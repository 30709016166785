import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Components/Nav";
import Footer from "../Components/Footer";
import Sidebar from "../Components/Sidebar";
import ModalSignup from "../Modals/SignupModal";
import ModalSignin from "../Modals/SigninModal";
import ModalCity from "../Modals/CityModal";
import ModalTermsCoditions from "../Modals/TermsConditionsModal";
import ModalPrivacyPolicy from "../Modals/PrivacyPolicyModal";
import ModalRefundPolicy from "../Modals/RefundPolicyModal";
import { DataContext } from "./Data-Context";



function PageLayout() {

    const [showProfile, setShowProfile] = useState(false);
    const [signinModal, setSigninModal] = useState(false);
    const [signupModal, setSignupModal] = useState(false);
    const [termsModal, setTermsModal] = useState(false);
    const [privacyModal, setPrivacyModal] = useState(false);
    const [refundModal, setRefundModal] = useState(false);
    const [profileModalShow, setProfileModalShow] = useState(false);
    const [userImage, setUserImage] = useState('');
    const [cityModalShow, setCityModalShow] = useState(true);
    const [selectedCity, setSelectedCity] = useState('Location');


    useEffect(() => {
        if(selectedCity == "Location") {
            setCityModalShow(true);
        } else {
            setCityModalShow(false);
        }
    }, [selectedCity]);


    function handleSigninModal() {
        setSigninModal(!signinModal);
    }

    function handleSignupModal() {
        setSignupModal(!signupModal);
    }

    const handleProfileSidebar = () => {
        setProfileModalShow(!profileModalShow);
    };

    return(
        <>
            <Navbar showProfile={showProfile} setShowProfile={setShowProfile} handleSigninModal={handleSigninModal} handleProfileSidebar={handleProfileSidebar} userImage={userImage} setUserImage={setUserImage} setProfileModalShow={setProfileModalShow} selectedCity={selectedCity} setSelectedCity={setSelectedCity} setCityModalShow={setCityModalShow} />
            <DataContext.Provider value={{selectedCity}}>
                <Outlet />
            </DataContext.Provider>
            <Footer termsModal={termsModal} setTermsModal={setTermsModal} privacyModal={privacyModal} setPrivacyModal={setPrivacyModal} refundModal={refundModal} setRefundModal={setRefundModal} />
            <Sidebar profileModalShow={profileModalShow} setProfileModalShow={setProfileModalShow} userImage={userImage} setUserImage={setUserImage} />
            <ModalCity selectedCity={selectedCity} setSelectedCity={setSelectedCity} cityModalShow={cityModalShow} setCityModalShow={setCityModalShow} />

            {/* register */}
            <ModalSignup showModal={signupModal} handleSigninModal={handleSigninModal} handleSignupModal={handleSignupModal} setTermsModal={setTermsModal} setPrivacyModal={setPrivacyModal} />

            {/* login */}
            <ModalSignin setShowProfile={setShowProfile} showModal={signinModal} handleSigninModal={handleSigninModal} handleSignupModal={handleSignupModal} />

            {/* Terms and Conditions */}
            <ModalTermsCoditions termsModal={termsModal} setTermsModal={setTermsModal} />

            {/* Privacy Policy */}
            <ModalPrivacyPolicy privacyModal={privacyModal} setPrivacyModal={setPrivacyModal} />

            {/* Refund Policy */}
            <ModalRefundPolicy refundModal={refundModal} setRefundModal={setRefundModal} />

        </>
    );
}


export default PageLayout;