import { useEffect, useState } from "react";
import { BannerWrapper } from "../Styles/Home-Style";
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from "axios";
import { Banners_URL, Poster_Image_URL } from "../API/Api";
import { useNavigate } from "react-router-dom";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectFade, Autoplay, Pagination, Navigation } from 'swiper/modules';



function Banner() {

    const [banners, setBanners] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        axios.get(Banners_URL)
        .then(res => {
            // console.log(res);
            setBanners(res.data.banners);
        })
        .catch(err => {
            // console.log(err);
            setBanners([]);
        })

    }, []);

    const getJoinedTypes = (types) => {
        return types.split(',').map(type => type.trim()).join(' / ');
    };

    const getFormattedLanguages = (languages) => {
        return languages.split(',').map(lang => lang.trim());
    };

    const handleRedirect = (movieName) => {
        localStorage.setItem("Movie_name", movieName);
        navigate(`/movie?movie=${encodeURIComponent(movieName)}`);
    };

    return (
        <>
            <BannerWrapper>
                <div className="video_backgroung_slider">
                    <Swiper
                        spaceBetween={30}
                        effect={'fade'}
                        loop={true}
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                        }}
                        modules={[EffectFade, Autoplay]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <video autoPlay muted src="images/Banner-1.mp4" loop ></video>
                        </SwiperSlide>
                        <SwiperSlide>
                            <video autoPlay muted src="images/Banner-2.mp4" loop ></video>
                        </SwiperSlide>
                        <SwiperSlide>
                            <video autoPlay muted src="images/Banner-3.mp4" loop ></video>
                        </SwiperSlide>
                        <SwiperSlide>
                            <video autoPlay muted src="images/Banner-4.mp4" loop ></video>
                        </SwiperSlide>
                        <SwiperSlide>
                            <video autoPlay muted src="images/Banner-5.mp4" loop ></video>
                        </SwiperSlide>
                        <SwiperSlide>
                            <video autoPlay muted src="images/Banner-6.mp4" loop ></video>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className="video_overlay"></div>
                <div className="banner_content">
                    <div className="banner_items">
                        <div className="banner_slider">
                            <Swiper
                                loop={true}
                                autoplay={{
                                    delay: 7000,
                                    disableOnInteraction: false,
                                }}
                                navigation={true}
                                modules={[Navigation, Autoplay]}
                                className="mySwiper"
                            >
                                {
                                    banners && banners.map((banner, index) => 
                                        <SwiperSlide key={index}>
                                            <div className="banner_item_box">
                                                <div className="box_left">
                                                    <div className="movie_box">
                                                        <img src={`${Poster_Image_URL}/${banner.poster_image}`} alt="poster" />
                                                    </div>
                                                </div>
                                                <div className="box_right">
                                                    <div className="box_inner">
                                                        <h2>{banner.name}</h2>
                                                        <li className="date_types">
                                                            <span>{banner.release_date}</span>
                                                            <i className="fa-solid fa-circle"></i>
                                                            <span>{getJoinedTypes(banner.types)}</span>
                                                        </li>
                                                        <li>
                                                            <p>
                                                                {
                                                                    getFormattedLanguages(banner.languages).map((language, index) => (
                                                                        <a key={index}>{language}</a>
                                                                    ))
                                                                }
                                                            </p>
                                                        </li>
                                                        <ul>
                                                            <a onClick={() => handleRedirect(banner.name)}><span><i className="fa-solid fa-ticket"></i> Book Ticket</span></a>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                }
                            </Swiper>
                        </div>
                    </div>
                    <div className="banner_shape">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                            <path fill="#FFF" fill-opacity="1" d="M0,64L40,101.3C80,139,160,213,240,224C320,235,400,181,480,160C560,139,640,149,720,170.7C800,192,880,224,960,229.3C1040,235,1120,213,1200,186.7C1280,160,1360,128,1400,112L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
                        </svg>
                    </div>
                </div>
            </BannerWrapper>
        </>
    );
}


export default Banner;