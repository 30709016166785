import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import PageLayout from "../Layouts/Page-Layout";
import Home from "../Pages/Home";
import Movies from "../Pages/Movies";
import Cinemas from "../Pages/Cinemas";
import MoviePage from "../Pages/Movie";
import PaymentSuccess from "../Pages/Success";
import BookingList from "../Pages/BookingList";
import UpcomingBookings from "../Components/UpcomigBooking";
import PreviousBookings from "../Components/PreviousBooking";
import CancelledBookings from "../Components/CancelledBooking";




function Router() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<PageLayout/>}>
                        <Route path="" element={<Navigate to="home" />} />
                        <Route exact path="home" element={<Home/>} />
                        <Route exact path="movies" element={<Movies/>} />
                        <Route exact path="cinema" element={<Cinemas/>} />
                        <Route exact path="movie" element={<MoviePage/>} />
                        <Route path="booking-list" element={<BookingList/>}>
                            <Route path="" element={<Navigate to="upcoming-bookings" />} />
                            <Route exact path="upcoming-bookings" element={<UpcomingBookings/>} />
                            <Route exact path="previous-bookings" element={<PreviousBookings/>} />
                            <Route exact path="cancelled-bookings" element={<CancelledBookings/>} />
                        </Route>
                    </Route>
                    <Route exact path="payment-success" element={<PaymentSuccess />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}


export default Router;