import { Link } from "react-router-dom";
import { FooterWrapper } from "../Styles/Nav-Footer-Style";




function Footer({termsModal, setTermsModal, privacyModal, setPrivacyModal, refundModal, setRefundModal}) {

    const handleTermsModal = () => {
        setTermsModal(!termsModal);
    };

    const handlePrivacyModal = () => {
        setPrivacyModal(!privacyModal);
    };

    const handleRefundModal = () => {
        setRefundModal(!refundModal);
    };
    
    return(
        <>
            <FooterWrapper>
                <div className="shape">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="rgb(49, 48, 53)" fill-opacity="1" d="M0,224L40,208C80,192,160,160,240,154.7C320,149,400,171,480,170.7C560,171,640,149,720,144C800,139,880,149,960,165.3C1040,181,1120,203,1200,192C1280,181,1360,139,1400,117.3L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path></svg>
                </div>
                <div className="footer">
                    <div className="footer_top">
                        <div className="newsettler_sec">
                            <div className="left_part">
                                <h5>Want to stay updated about our activity ? <span>Subscribe Us Now !!</span></h5>
                                <form>
                                    <input type="text" placeholder="Enter Your Email Here" />
                                    <button>Subscribe</button>
                                </form>
                            </div>
                            <div className="right_part">
                                <div className="link_box">
                                    <a href="#">
                                        <i class="fa-solid fa-headset"></i>
                                        <span>24/7 Customer <br /> Support</span>
                                    </a>
                                </div>
                                <div className="link_box">
                                    <Link to="/movies">
                                        <i class="fa-solid fa-ticket"></i>
                                        <span>Book Tickets</span>
                                    </Link>
                                </div>
                                <div className="link_box">
                                    <Link to="/cinema">
                                        <i class="fa-solid fa-video"></i>
                                        <span>Cinemas</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="footer_gap">
                            <div className="gap_image">
                                <div className="img_box">
                                    <img src="images/Logo.png" alt="Logo" />
                                </div>
                            </div>
                            <div className="gap_line">
                                <div className="line"></div>
                            </div>
                        </div>
                        <div className="sec_items">
                            <div className="items_inner">
                                <div className="item_box">
                                    <p>Ticket Counter is a Fast Growing Platform <br /> to make Booking Easy for Moviegoers</p>
                                </div>
                                <div className="item_box links_box">
                                    <h5>Browse All</h5>
                                    <ul>
                                        <li><a href="#"><i class="fa-solid fa-angle-right"></i>Now Showing</a></li>
                                        <li><a href="#"><i class="fa-solid fa-angle-right"></i>Upcoming Movies</a></li>
                                        <li><a href="#"><i class="fa-solid fa-angle-right"></i>This Week</a></li>
                                        <li><a href="#"><i class="fa-solid fa-angle-right"></i>Cinemas</a></li>
                                    </ul>
                                </div>
                                <div className="item_box links_box">
                                    <h5>Quick Links</h5>
                                    <ul>
                                        <li><a href="#"><i class="fa-solid fa-angle-right"></i>Login</a></li>
                                        <li><a href="#"><i class="fa-solid fa-angle-right"></i>Register</a></li>
                                        <li><a href="#"><i class="fa-solid fa-angle-right"></i>Contact Us</a></li>
                                        <li><a href="#"><i class="fa-solid fa-angle-right"></i>Movies</a></li>
                                        <li><a onClick={handleRefundModal}><i class="fa-solid fa-angle-right"></i>Refund Policy</a></li>
                                    </ul>
                                </div>
                                <div className="item_box">
                                    <h5>Follow Us on:</h5>
                                    <div className="social_media">
                                        <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-x-twitter"></i></a></li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer_bottom">
                        <p>Copyright © <span>Shetty Ticket Counter Private Limited</span> | All Rights Reserved</p>
                        <div className="dot"><i class="fa-solid fa-circle"></i></div>
                        <div className="links">
                            <a onClick={handleTermsModal}>Terms & Conditions</a>
                            <a onClick={handlePrivacyModal}>Privacy Policy</a>
                        </div>
                    </div>
                </div>
            </FooterWrapper>
        </>
    );
}


export default Footer;