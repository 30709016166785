import { styled } from "@mui/material";


export const BookingWrapper = styled('div')`
    position: relative;
    width: 100%;
    

    .banner_sec {
        position: relative;
        padding: 100px ;
        padding-top: 180px;
        height: 900px;
        background: linear-gradient(to bottom, #fff 30%, #EEE);
        padding-bottom: 250px;

        .sec_items {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;

            .item_left {
                position: relative;
                width: 270px;
                height: 400px;

                .movie_box {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;

                    img {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 8px;
                        box-shadow: 7px 10px 15px rgba(25,25,25,0.3),
                                    -4px -4px 10px rgba(50,50,50,0.3);
                    }
                }
            }

            .item_right {
                position: relative;
                width: calc(100% - 335px);
                padding-left: 65px;
                padding-right: 50px;

                .item_inner {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    h2 {
                        position: relative;
                        margin-bottom: 5px;
                        font-size: 40px;
                        color: #111;
                        font-family: "Russo One", sans-serif;
                        letter-spacing: 0.3px;
                    }

                    li {
                        position: relative;
                        margin-top: 20px;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        font-family: 'Poppins', sans-serif;

                        span {
                            font-size: 17px;
                            font-weight: 500;
                            color: #444;
                        }
                        i {
                            position: relative;
                            font-size: 6px;
                            margin: 0px 10px;
                            color: #444;
                        }

                        p {
                            position: relative;
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;

                            a {
                                position: relative;
                                margin-right: 20px;
                                margin-bottom: 20px;
                                padding: 10px 30px;
                                background: #222;
                                font-size: 16px;
                                line-height: 1;
                                color: #fff;
                                border-radius: 4px;
                            }

                            &.type a {
                                background: #E1E0EA;
                                color: #111;
                            }
                        }
                    }

                    ul {
                        position: relative;
                        display: flex;
                        align-items: center;

                        button {
                            position: relative;
                            width: 200px;
                            height: 55px;
                            font-family: 'Ubuntu', sans-serif;
                            font-size: 17px;
                            font-weight: 600;
                            cursor: pointer;
                            border-radius: 5px;
                            overflow: hidden;
                            background: none;
                            outline: none;


                            &::before {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                transform: scaleX(1);
                                transform-origin: left;
                                transition: transform 0.5s;
                            }

                            &:hover:before {
                                transform: scaleX(0);
                                transform-origin: right;
                                transition: transform 0.5s;
                            }


                            span {
                                position: relative;
                                z-index: 5;
                                color: #FFF;
                                transition: all 0.5s;

                                i {
                                    font-size: 18px;
                                    margin-right: 10px;
                                }
                            }

                            &.trailer_btn {
                                margin-right: 20px;
                                border: 2px solid #E5322D;
                                color: #FC6736;

                                &::before {
                                    background: #E5322D;
                                }

                                &:hover span {
                                    color: #E5322D;
                                    transition: all 0.5s;
                                }
                            }

                            &.booking_btn {
                                border: 2px solid #3F00FF;
                                color: #3F00FF;

                                i {
                                    transform: rotate(-45deg);
                                }

                                &::before {
                                    background: #3F00FF;
                                }

                                &:hover span {
                                    color: #3F00FF;
                                    transition: all 0.5s;
                                }
                            }
                        }
                    }
                    .rating_btn {
                        position: relative;
                        margin-top: 20px;
                        width: max-content;
                        height: 55px;
                        background: #FFF;
                        border: 2px solid #FFC76C;
                        border-radius: 5px;
                        overflow: hidden;
                        display: flex;

                        a {
                            position: relative;
                            width: 200px;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #FFC76C;
                            font-family: 'Ubuntu', sans-serif;
                            font-size: 17px;
                            font-weight: 600;
                            color: #111;
                            cursor: pointer;

                            i {
                                font-size: 18px;
                                margin-right: 13px;
                            }
                        }

                        .rating_form {
                            position: relative;
                            max-width: 0;
                            overflow: hidden;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            transition: all 0.5s;

                            &.active {
                                max-width: 300px;
                                transition: all 0.5s;
                            }

                            ul {
                                position: relative;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                margin: 0;
                                padding-left: 20px;
                                padding-right: 25px;

                                label {
                                    position: relative;
                                    margin: 0px 9px;
                                    cursor: pointer;

                                    i {
                                        position: relative;
                                        font-size: 24px;
                                        color: #DDD;

                                        &.star {
                                            color: #FFD700;
                                            transition: color 0.3s;
                                        }

                                        &.glowing-star {
                                            color: #FFD700;
                                            text-shadow: 0 0 7px rgba(255, 215, 0, 0.8), 0 0 10px rgba(255, 215, 0, 0.6), 0 0 15px rgba(255, 215, 0, 0.4);
                                            transition: color 0.3s, text-shadow 0.3s;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .rating_sec {
                        position: relative;
                        margin-top: 15px;
                        margin-bottom: 5px;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        font-family: 'Poppins', sans-serif;

                        h5 {
                            position: relative;
                            font-size: 17px;
                            font-weight: 600;
                            color: #111;
                        }

                        .stars {
                            position: relative;
                            margin-left: 10px;
                            display: flex;
                            align-items: center;
                            padding-bottom: 5px;

                            i {
                                position: relative;
                                font-size: 17px;
                                color: #FFE234;
                                margin-right: 2px;
                            }
                        }

                        span {
                            position: relative;
                            margin-left: 3px;
                            font-size: 16px;
                            color: #666;
                            font-weight: 500;

                            &.dot {
                                font-size: 5px;
                                margin: 0px 8px;
                            }
                        }

                        p {
                            position: relative;
                            font-size: 16px;
                            color: #666;
                        }
                    }
                }
            }

            .item_share {
                position: relative;
                width: 60px;
                height: 100%;
                padding: 35px 0;

                .item_inner {
                    position: relative;
                    width: 100%;
                    height: 100%;

                    .share_btn {
                        position: relative;
                        width: 100%;
                        cursor: pointer;
                        z-index: 10;
                        transition: all 0.9s;

                        &.active {
                            transform: rotate(360deg);
                            transition: all 0.9s;
                        }

                        img {
                            position: relative;
                            width: 100%;
                            border-radius: 50%;
                            display: flex;
                            box-shadow: 6px 6px 15px rgba(0,0,0,0.3),
                                        -4px -4px 10px rgba(25,25,25,0.2);
                        }
                    }

                    .share_options {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;

                        li {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            list-style: none;

                            a {
                                position: relative;
                                width: 100%;
                                text-decoration: none;
                                cursor: pointer;

                                img {
                                    position: relative;
                                    width: 100%;
                                    border-radius: 50%;
                                    display: flex;
                                    box-shadow: 5px 5px 12px rgba(0,0,0,0.25),
                                                -3px -3px 9px rgba(25,25,25,0.2);
                                }
                            }

                            span {
                                position: absolute;
                                top: 50%;
                                left: 0;
                                white-space: nowrap;
                                transform: translate(-130%,-50%);
                                padding: 8px 15px;
                                background: #FFF;
                                line-height: 1;
                                font-family: 'Poppins', sans-serif;
                                box-shadow: 0 4px 8px rgba(252, 103, 54, 0.5),
                                            -3px -3px 5px rgba(252, 103, 54, 0.3);
                                border: 1px solid #FC6736;
                                border-right: none;
                                border-radius: 5px;
                                font-size: 14px;
                                color: #222;
                                font-weight: 500;
                                opacity: 0;
                                transition: all 0.5s;

                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: 5px;
                                    right: -10px;
                                    width: 23px;
                                    height: 20px;
                                    border-top: 1px solid #FC6736;
                                    border-left: 1px solid #FC6736;
                                    background: #FFF;
                                    z-index: -1;
                                    transform: rotate(135deg);
                                    box-shadow: -4px -3px 5px rgba(252, 103, 54, 0.5);
                                }
                            }

                            &:hover span {
                                opacity: 1;
                                transition: all 0.5s;
                            }

                            &:nth-child(1) {
                                opacity: 0;
                                transform: translateY(0);
                                transition: all 0.3s;
                                z-index: 7;

                                &.active {
                                    opacity: 1;
                                    transform: translateY(90px);
                                    transition: all 0.9s;
                                }
                            }

                            &:nth-child(2) {
                                opacity: 0;
                                transform: translateY(0);
                                transition: all 0.3s;
                                z-index: 4;
                                transition-delay: 0.1s;

                                &.active {
                                    opacity: 1;
                                    transform: translateY(175px);
                                    transition: all 0.9s;
                                }
                            }

                            &:nth-child(3) {
                                opacity: 0;
                                transform: translateY(0);
                                transition: all 0.3s;
                                z-index: 1;
                                transition-delay: 0.2s;

                                &.active {
                                    opacity: 1;
                                    transform: translateY(260px);
                                    transition: all 0.9s;
                                }
                            }
                        }
                    }
                }
            }

            @media (max-width: 1440px) {
                .item_left {
                    width: 250px;
                    height: 350px;
                }
                .item_right {
                    width: calc(100% - 315px);
                }
                .item_right .item_inner h2 {
                    font-size: 30px;
                }
            }

            @media (max-width: 1250px) {
                .item_right .item_inner li i.type_dot {
                    display: none;
                }
                .item_right .item_inner li span.types {
                    width: 100%;
                    margin-top: 10px;
                }
                .item_right .item_inner li p a {
                    font-size: 14px;
                }
                .item_right .item_inner ul button {
                    width: 180px;
                    height: 50px;
                    font-size: 15px;
                }
            }

            @media (max-width: 991px) {
                .item_left {
                    width: 210px;
                    height: 300px;
                }

                .item_right {
                    width: calc(100% - 210px);
                    padding-left: 50px;
                    padding-right: 0;

                    .item_inner h2 {
                        font-size: 25px;
                    }
                }

                .item_share {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    width: 50px;

                    .item_inner {

                        height: max-content;

                        .share_options {
                            li {
                                &:nth-child(1) {
                                    &.active {
                                        transform: translateY(75px);
                                    }
                                }

                                &:nth-child(2) {
                                    &.active {
                                        transform: translateY(150px);
                                    }
                                }

                                &:nth-child(3) {
                                    &.active {
                                        transform: translateY(225px);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media (max-width: 768px) {
                flex-direction: column;

                .item_right {
                    width: 100%;
                    padding: 0;
                    margin-top: 50px;

                    .item_inner {
                        align-items: center;
                        text-align: center;

                        h2 {
                            font-size: 22px;
                        }

                        li {
                            justify-content: center;
                            text-align: center;

                            p {
                                justify-content: center;
                            }

                            span {
                                font-size: 15px;
                            }
                        }
                    }
                }

                .item_share {
                    padding: 0;
                }
            }

            @media (max-width: 575px) {

                .item_right {

                    .item_inner {

                        li {

                            p {
                                a {
                                    margin-right: 15px;
                                    margin-bottom: 15px;
                                }
                            }
                        }

                        ul {
                            margin-top: 15px;
                        }

                        .rating_btn {
                            display: none;
                        }
                    }
                }

                .item_share {
                    width: 40px;

                    .item_inner {

                        .share_options {

                            li {
                                &:nth-child(1) {
                                    &.active {
                                        transform: translateY(55px);
                                    }
                                }

                                &:nth-child(2) {
                                    &.active {
                                        transform: translateY(110px);
                                    }
                                }

                                &:nth-child(3) {
                                    &.active {
                                        transform: translateY(165px);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media (max-width: 450px) {
                .item_right .item_inner {

                    ul {
                        width: 100%;
                        flex-direction: column;
    
                        button {
                            width: 100%;
                            height: 52px;

                            &.trailer_btn {
                                margin-right: 0;
                                margin-bottom: 15px;
                            }
                        }
                    }

                    .rating_sec {
                        justify-content: center;

                        span {
                            &.dot {
                                display: none;
                            }
                        }
                        p {
                            margin-top: 5px;
                            width: 100%;
                        }
                    }
                }

                .item_share {
                    right: -10px;
                }
            }
        }

        .shape {
            position: absolute;
            bottom: -150px;
            left: 0;
            width: 100%;
            display: block;

            svg {
                position: relative;
                width: 100%;
                height: auto;
                display: block;
            }

            @media (max-width: 1570px) {
                bottom: -65px;
            }

            @media (max-width: 991px) {
                bottom: -20px;
            }
        }

        @media (max-width: 1250px) {
            padding: 70px ;
            padding-top: 150px;

            .sec_items {
                padding-bottom: 80px;
            }
        }

        @media (max-width: 991px) {
            padding: 50px ;
            padding-top: 100px;
            height: 750px;
        }

        @media (max-width: 768px) {
            padding-top: 150px;
            height: max-content;

            .sec_items {
                padding-bottom: 125px;
            }
        }

        @media (max-width: 575px) {
            padding: 50px 35px;
            padding-top: 150px;

            .sec_items {
                padding-bottom: 100px;
            }
        }

        @media (max-width: 450px) {
            padding: 50px 25px;
            padding-top: 130px;

            .sec_items {
                padding-bottom: 90px;
            }
        }
    }

    .movie_info_sec {
        position: relative;
        width: 100%;
        padding: 80px 100px;
        padding-bottom: 0;

        .sec_content {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;

            .movie_about {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
                padding-bottom: 60px;
                
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                    background: linear-gradient(to right, rgba(0,0,0,0.1) 20%, #fff 100%);
                }

                h2 {
                    position: relative;
                    width: max-content;
                    font-family: arista-pro-trial-semibold;
                    font-size: 38px;
                    padding-bottom: 4px;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 65%;
                        height: 4px;
                        background: linear-gradient(to right,  #FC6736 0%, #E1E0EA 100%);
                        border-radius: 20px;
                    }

                    span {
                        font-family: 'Oleo Script', cursive;
                        font-size: 40px;
                        color: #FC6736;
                    }
                }

                p {
                    position: relative;
                    margin-top: 40px;
                    font-family: 'Poppins', sans-serif;
                    font-size: 17px;
                    line-height: 1.5;
                    color: #444;
                }
            }

            .movie_cast_sec {
                position: relative;
                width: 100%;
                padding-top: 50px;
                padding-bottom: 60px;
                display: flex;
                flex-direction: column;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                    background: linear-gradient(to right, rgba(0,0,0,0.1) 20%, #fff 100%);
                }

                h2 {
                    position: relative;
                    width: max-content;
                    font-family: arista-pro-trial-semibold;
                    font-size: 38px;
                    padding-bottom: 4px;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 65%;
                        height: 4px;
                        background: linear-gradient(to right,  #FC6736 0%, #E1E0EA 100%);
                        border-radius: 20px;
                    }

                    span {
                        font-family: 'Oleo Script', cursive;
                        font-size: 40px;
                        color: #FC6736;
                    }
                }

                .cast_slider_sec {
                    position: relative;
                    margin-top: 40px;
                    width: 100%;

                    .swiper {
                        position: relative;
                        width: 100%;
                        padding-top: 15px;
                        padding-bottom: 100px;

                        .swiper-slide {
                            position: relative;
                            width: 200px;
                            margin-right: 30px;

                            .cast_box {
                                position: relative;
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                text-align: center;

                                .img_box {
                                    position: relative;
                                    width: 100%;
                                    height: 200px;
                                    border: 1px solid #E1E0EA;
                                    border-radius: 50%;
                                    padding: 8px;
                                    box-shadow: 6px 7px 15px rgba(0,0,0,0.25),
                                                -3px -3px 7px rgba(0,0,0,0.1);

                                    img {
                                        position: relative;
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        border-radius: 50%;
                                    }
                                }

                                p {
                                    position: relative;
                                    margin-top: 20px;
                                    font-family: 'Lemonada', cursive;
                                    font-size: 16px;
                                    font-weight: 500;
                                    color: #333;
                                }
                            }
                        }

                        .swiper-button-prev {
                            top: inherit;
                            bottom: 0;
                            left: calc(50% - 80px);
                            width: 80px;
                            height: 50px;
                            border: 2px solid #FC6736;
                            background: #FFF;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 30px;
                            transform: translateX(-10%);

                            &::after {
                                font-size: 20px;
                                color: #FC6736;
                                font-weight: 700;
                            }
                        }

                        .swiper-button-next {
                            top: inherit;
                            bottom: 0;
                            left: inherit;
                            right: calc(50% - 80px);
                            width: 80px;
                            height: 50px;
                            border: 2px solid #FC6736;
                            background: #FC6736;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 30px;
                            transform: translateX(10%);

                            &::after {
                                font-size: 20px;
                                color: #FFF;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }

            .movie_crew_sec {
                position: relative;
                width: 100%;
                padding-top: 50px;
                padding-bottom: 60px;
                display: flex;
                flex-direction: column;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                    background: linear-gradient(to right, rgba(0,0,0,0.1) 20%, #fff 100%);
                }

                h2 {
                    position: relative;
                    width: max-content;
                    font-family: arista-pro-trial-semibold;
                    font-size: 38px;
                    padding-bottom: 4px;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 65%;
                        height: 4px;
                        background: linear-gradient(to right,  #FC6736 0%, #E1E0EA 100%);
                        border-radius: 20px;
                    }

                    span {
                        font-family: 'Oleo Script', cursive;
                        font-size: 40px;
                        color: #FC6736;
                    }
                }

                .crew_slider_sec {
                    position: relative;
                    margin-top: 40px;
                    width: 100%;

                    .swiper {
                        position: relative;
                        width: 100%;
                        padding-top: 15px;
                        padding-bottom: 100px;

                        .swiper-slide {
                            position: relative;
                            width: 200px;
                            margin-right: 30px;

                            .crew_box {
                                position: relative;
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                text-align: center;

                                .img_box {
                                    position: relative;
                                    width: 100%;
                                    height: 200px;
                                    border: 1px solid #E1E0EA;
                                    border-radius: 50%;
                                    padding: 8px;
                                    box-shadow: 6px 7px 15px rgba(0,0,0,0.25),
                                                -3px -3px 7px rgba(0,0,0,0.1);

                                    img {
                                        position: relative;
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        border-radius: 50%;
                                    }
                                }

                                p {
                                    position: relative;
                                    margin-top: 20px;
                                    font-family: 'Lemonada', cursive;
                                    font-size: 16px;
                                    font-weight: 500;
                                    color: #333;
                                }

                                span {
                                    position: relative;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 16px;
                                    color: #888;
                                }
                            }
                        }

                        .swiper-button-prev {
                            top: inherit;
                            bottom: 0;
                            left: calc(50% - 80px);
                            width: 80px;
                            height: 50px;
                            border: 2px solid #FC6736;
                            background: #FFF;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 30px;
                            transform: translateX(-10%);

                            &::after {
                                font-size: 20px;
                                color: #FC6736;
                                font-weight: 700;
                            }
                        }

                        .swiper-button-next {
                            top: inherit;
                            bottom: 0;
                            left: inherit;
                            right: calc(50% - 80px);
                            width: 80px;
                            height: 50px;
                            border: 2px solid #FC6736;
                            background: #FC6736;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 30px;
                            transform: translateX(10%);

                            &::after {
                                font-size: 20px;
                                color: #FFF;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 1250px) {
            padding: 80px 70px;
            padding-bottom: 0;

            .sec_content .movie_about h2,
            .sec_content .movie_cast_sec h2,
            .sec_content .movie_crew_sec h2 {
                font-size: 35px;

                span {
                    font-size: 37px;
                }
            }
        }

        @media (max-width: 991px) {
            padding: 70px 50px;
            padding-bottom: 0;

            .sec_content .movie_about h2,
            .sec_content .movie_cast_sec h2,
            .sec_content .movie_crew_sec h2 {
                font-size: 32px;

                span {
                    font-size: 35px;
                }
            }

            .sec_content .movie_cast_sec .cast_slider_sec .swiper .swiper-slide,
            .sec_content .movie_crew_sec .crew_slider_sec .swiper .swiper-slide {
                width: 175px;
                margin-right: 20px;
            }

            .sec_content .movie_cast_sec .cast_slider_sec .swiper .swiper-slide .cast_box .img_box,
            .sec_content .movie_crew_sec .crew_slider_sec .swiper .swiper-slide .crew_box .img_box {
                height: 175px;
            }
        }

        @media (max-width: 768px) {
            .sec_content .movie_about p {
                font-size: 15px;
            }

            .sec_content .movie_cast_sec .cast_slider_sec .swiper .swiper-button-prev, 
            .sec_content .movie_crew_sec .crew_slider_sec .swiper .swiper-button-prev {
                left: calc(50% - 60px);
                width: 60px;
                height: 40px;

                &::after {
                    font-size: 16px;
                }
            } 

            .sec_content .movie_cast_sec .cast_slider_sec .swiper .swiper-button-next, 
            .sec_content .movie_crew_sec .crew_slider_sec .swiper .swiper-button-next {
                right: calc(50% - 60px);
                width: 60px;
                height: 40px;

                &::after {
                    font-size: 16px;
                }
            } 
        }

        @media (max-width: 575px) {
            padding: 70px 35px;
            padding-bottom: 0;

            .sec_content .movie_about h2,
            .sec_content .movie_cast_sec h2,
            .sec_content .movie_crew_sec h2 {
                font-size: 27px;

                span {
                    font-size: 30px;
                }
            }

            .sec_content .movie_cast_sec,
            .sec_content .movie_crew_sec {
                padding-top: 30px;
                padding-bottom: 35px;
            }

            .sec_content .movie_cast_sec .cast_slider_sec .swiper,
            .sec_content .movie_crew_sec .crew_slider_sec .swiper {
                padding-bottom: 80px;
            }

            .sec_content .movie_cast_sec .cast_slider_sec .swiper .swiper-slide,
            .sec_content .movie_crew_sec .crew_slider_sec .swiper .swiper-slide {
                width: 150px;
                margin-right: 20px;
            }

            .sec_content .movie_cast_sec .cast_slider_sec .swiper .swiper-slide .cast_box .img_box,
            .sec_content .movie_crew_sec .crew_slider_sec .swiper .swiper-slide .crew_box .img_box {
                height: 150px;
                padding: 5px;
            }

            .sec_content .movie_cast_sec .cast_slider_sec .swiper .swiper-slide .cast_box p,
            .sec_content .movie_crew_sec .crew_slider_sec .swiper .swiper-slide .crew_box p {
                font-size: 14px;
            }
        }
        
        @media (max-width: 450px) {
            padding: 50px 25px;
            padding-bottom: 0;

            .sec_content .movie_cast_sec .cast_slider_sec .swiper .swiper-slide,
            .sec_content .movie_crew_sec .crew_slider_sec .swiper .swiper-slide {
                width: 110px;
                margin-right: 15px;
            }

            .sec_content .movie_cast_sec .cast_slider_sec .swiper .swiper-slide .cast_box .img_box,
            .sec_content .movie_crew_sec .crew_slider_sec .swiper .swiper-slide .crew_box .img_box {
                height: 110px;
            }
            .sec_content .movie_cast_sec .cast_slider_sec .swiper .swiper-slide .cast_box p,
            .sec_content .movie_crew_sec .crew_slider_sec .swiper .swiper-slide .crew_box p {
                font-size: 13px;
            }
        }
    }

    .user_review_sec {
        position: relative;
        width: 100%;
        padding: 50px 100px;

        .sec_inner {
            position: relative;
            width: 100%;
            padding-bottom: 60px;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background: linear-gradient(to right, rgba(0,0,0,0.1) 20%, #fff 100%);
            }

            .sec_head {
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                h2 {
                    position: relative;
                    width: max-content;
                    font-family: arista-pro-trial-semibold;
                    font-size: 38px;
                    padding-bottom: 4px;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 65%;
                        height: 4px;
                        background: linear-gradient(to right,  #FC6736 0%, #E1E0EA 100%);
                        border-radius: 20px;
                    }

                    span {
                        font-family: 'Oleo Script', cursive;
                        font-size: 40px;
                        color: #FC6736;
                    }
                }

                a {
                    position: relative;
                    width: 180px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 2px solid #E5322D;
                    border-radius: 25px;
                    font-family: 'Poppins', sans-serif;
                    text-decoration: none;
                    font-size: 17px;
                    font-weight: 500;
                    overflow: hidden;

                    span {
                        position: relative;
                        z-index: 5;
                        color: #FFF;

                        i {
                            position: relative;
                            margin-left: 8px;
                        }
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: #E5322D;
                        transform: scaleX(1);
                        transform-origin: left;
                        transition: transform 0.5s;
                    }

                    &:hover span {
                        color: #E5322D;
                        transition: all 0.5s;
                    }

                    &:hover::before {
                        transform: scaleX(0);
                        transform-origin: right;
                        transition: transform 0.5s;
                    }
                }
            }

            .review_slider_sec {
                position: relative;
                margin-top: 40px;
                width: 100%;

                .swiper {
                    position: relative;
                    width: 100%;
                    padding-top: 15px;
                    padding-bottom: 100px;

                    .swiper-slide {
                        position: relative;
                        width: 500px;
                        height: 290px;
                        padding: 35px;
                        border: 2px solid rgba(225, 224, 234, 0.7);
                        border-radius: 15px;

                        .quote_icon {
                            position: absolute;
                            top: 5px;
                            right: 25px;

                            i {
                                position: relative;
                                font-size: 80px;
                                color: rgba(225, 224, 234, 0.4);
                            }
                        }

                        .review_box {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            flex-direction: column;

                            .review_top {
                                position: relative;
                                width: 100%;
                                display: flex;
                                align-items: center;

                                .user_img {
                                    position: relative;
                                    width: 50px;
                                    height: 50px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background: #E1E0EA;
                                    border-radius: 50%;

                                    i {
                                        position: relative;
                                        font-size: 22px;
                                        color: #FFF;
                                    }
                                }

                                h3 {
                                    position: relative;
                                    width: calc(100% - 50px);
                                    padding-left: 20px;
                                    font-family: 'Lemonada', cursive;
                                    font-size: 20px;
                                    color: #111;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }

                            .review_message {
                                position: relative;
                                margin-top: 30px;
                                width: 100%;

                                p {
                                    position: relative;
                                    width: 100%;
                                    height: 75px;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 16px;
                                    line-height: 1.5;
                                    color: #666;
                                    display: -webkit-box;
                                    overflow: hidden;
                                    -webkit-line-clamp: 3;
                                    -webkit-box-orient: vertical;
                                    text-overflow: ellipsis;
                                }
                            }

                            .review_bottom {
                                position: relative;
                                margin-top: auto;
                                width: 100%;
                                display: flex;
                                align-items: flex-end;
                                justify-content: space-between;

                                .user_review {
                                    position: relative;
                                    display: flex;
                                    align-items: center;
                                    font-family: 'Poppins', sans-serif;

                                    h4 {
                                        position: relative;
                                        font-size: 17px;
                                        font-weight: 600;
                                        color: #333;
                                        margin-right: 10px;
                                    }

                                    span {
                                        position: relative;
                                        font-size: 17px;
                                        font-weight: 500;
                                        color: #888;
                                    }

                                    i {
                                        position: relative;
                                        margin-left: 5px;
                                        padding-bottom: 3px;
                                        font-size: 18px;
                                        color: #FFE234;
                                    }
                                }

                                .review_date {
                                    position: relative;

                                    p {
                                        position: relative;
                                        font-family: 'Ubuntu', sans-serif;
                                        font-size: 16px;
                                        font-weight: 500;
                                        color: #666;
                                    }
                                }
                            }
                        }
                    }

                    .swiper-button-prev {
                        top: inherit;
                        bottom: 0;
                        left: calc(50% - 80px);
                        width: 80px;
                        height: 50px;
                        border: 2px solid #FC6736;
                        background: #FFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 30px;
                        transform: translateX(-10%);

                        &::after {
                            font-size: 20px;
                            color: #FC6736;
                            font-weight: 700;
                        }
                    }

                    .swiper-button-next {
                        top: inherit;
                        bottom: 0;
                        left: inherit;
                        right: calc(50% - 80px);
                        width: 80px;
                        height: 50px;
                        border: 2px solid #FC6736;
                        background: #FC6736;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 30px;
                        transform: translateX(10%);

                        &::after {
                            font-size: 20px;
                            color: #FFF;
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        @media (max-width: 1250px) {
            padding: 50px 70px;

            .sec_inner .sec_head h2 {
                font-size: 35px;

                span {
                    font-size: 37px;
                }
            }
        }

        @media (max-width: 1150px) {
            .sec_inner .review_slider_sec .swiper .swiper-slide {
                width: calc(50% - 15px);
            }
        }

        @media (max-width: 991px) {
            padding: 50px;

            .sec_inner .review_slider_sec .swiper .swiper-slide .review_box .review_top .user_img {
                width: 40px;
                height: 40px;

                i {
                    font-size: 17px;
                }
            }

            .sec_inner .review_slider_sec .swiper .swiper-slide .review_box .review_top h3 {
                font-size: 18px;
            }
        }

        @media (max-width: 768px) {

            .sec_inner .sec_head h2 {
                font-size: 32px;

                span {
                    font-size: 35px;
                }
            }

            .sec_inner .sec_head a {
                width: 150px;
                font-size: 15px;
            }

            .sec_inner .review_slider_sec .swiper .swiper-slide {
                width: 100%;
            }

            .sec_inner .review_slider_sec .swiper .swiper-button-prev {
                left: calc(50% - 60px);
                width: 60px;
                height: 40px;

                &::after {
                    font-size: 16px;
                }
            } 

            .sec_inner .review_slider_sec .swiper .swiper-button-next {
                right: calc(50% - 60px);
                width: 60px;
                height: 40px;

                &::after {
                    font-size: 16px;
                }
            }
        }

        @media (max-width: 575px) {
            padding: 50px 35px;

            .sec_inner .sec_head h2 {
                font-size: 27px;

                span {
                    font-size: 30px;
                }
            }

            .sec_inner .review_slider_sec .swiper .swiper-slide {
                margin-top: 20px;
                height: 250px;
                padding: 25px;

                .quote_icon {
                    position: absolute;
                    top: 5px;
                    right: 25px;

                    i {
                        position: relative;
                        font-size: 55px;
                        color: rgba(225, 224, 234, 0.4);
                    }
                }
            }

            .sec_inner .review_slider_sec .swiper .swiper-slide .review_box .review_top h3 {
                font-size: 16px;
            }

            .sec_inner .review_slider_sec .swiper .swiper-slide .review_box .review_message {
                margin-top: 20px;

                p {
                    font-size: 15px;
                    height: 70px;
                }
            }

            .sec_inner .review_slider_sec .swiper .swiper-slide .review_box .review_bottom {
                .user_review {
                    h4 {
                        font-size: 15px;
                    }

                    span {
                        font-size: 15px;
                    }

                    i {
                        font-size: 15px;
                    }
                }

                .review_date {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
        
        @media (max-width: 475px) {
            padding: 50px 25px;

            .sec_inner .sec_head {
                flex-direction: column;
                align-items: flex-start;
            }

            .sec_inner .sec_head a {
                width: auto;
                margin-left: auto;
                font-size: 16px;
                border: none;

                &::before {
                    display: none;
                }

                span {
                    color: #FC6736;
                }
            }

            .sec_inner .review_slider_sec {
                margin-top: 0px;
            }

            .sec_inner .review_slider_sec .swiper .swiper-slide {
                padding: 20px;
                height: 225px;
            }
        }
    }

    .related_movie_sec {
        position: relative;
        width: 100%;
        padding: 50px 100px;
        padding-bottom: 60px;
        margin-bottom: 150px;

        .sec_head {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h2 {
                position: relative;
                width: max-content;
                font-family: arista-pro-trial-semibold;
                font-size: 38px;
                padding-bottom: 4px;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 65%;
                    height: 4px;
                    background: linear-gradient(to right,  #FC6736 0%, #E1E0EA 100%);
                    border-radius: 20px;
                }

                span {
                    font-family: 'Oleo Script', cursive;
                    font-size: 40px;
                    color: #FC6736;
                }
            }

            a {
                position: relative;
                width: 180px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #E5322D;
                border-radius: 25px;
                font-family: 'Poppins', sans-serif;
                text-decoration: none;
                font-size: 17px;
                font-weight: 500;
                overflow: hidden;

                span {
                    position: relative;
                    z-index: 5;
                    color: #FFF;

                    i {
                        position: relative;
                        margin-left: 8px;
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #E5322D;
                    transform: scaleX(1);
                    transform-origin: left;
                    transition: transform 0.5s;
                }

                &:hover span {
                    color: #E5322D;
                    transition: all 0.5s;
                }

                &:hover::before {
                    transform: scaleX(0);
                    transform-origin: right;
                    transition: transform 0.5s;
                }
            }
        }

        .related_slider_sec {
            position: relative;
            width: 100%;
            margin-top: 50px;

            .swiper {
                position: relative;
                width: 100%;
                padding-bottom: 120px;

                .swiper-slide {
                    position: relative;
                    width: 300px;
                    padding-right: 30px;
                    margin-right: 30px;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 1px;
                        height: 100%;
                        background: linear-gradient(to bottom, #fff 2%, rgba(0,0,0,0.2) 40%, #fff 100%);
                    }

                    &:last-of-type::before {
                        display: none;
                    }

                    .movie_box {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        .box_img {
                            position: relative;
                            width: 100%;
                            height: 300px;
                            border-radius: 8px;
                            overflow: hidden;

                            img {
                                position: relative;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .box_info {
                            position: relative;
                            margin-top: 20px;
                            display: flex;
                            flex-direction: column;

                            h5 {
                                position: relative;
                                width: 100%;
                                font-family: "Russo One", sans-serif;
                                font-size: 18px;
                                font-weight: 500;
                                letter-spacing: 0.55px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            p {
                                position: relative;
                                margin-top: 4px;
                                font-family: 'Poppins', sans-serif;
                                font-size: 15px;
                                color: #666;
                            }

                            a {
                                position: relative;
                                margin-top: 20px;
                                width: 100%;
                                height: 55px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-decoration: none;
                                font-family: 'Ubuntu', sans-serif;
                                font-size: 17px;
                                font-weight: 600;
                                cursor: pointer;
                                border-radius: 8px;
                                border: 2px solid #3F00FF;
                                color: #FFF;
                                overflow: hidden;
                                transition: all 0.5s;

                                span {
                                    position: relative;
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    z-index: 5;

                                    i {
                                        position: relative;
                                        font-size: 18px;
                                        margin-right: 10px;
                                        transform: rotate(-45deg);
                                    }
                                }

                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    background: #3F00FF;
                                    transform: scaleX(1);
                                    transform-origin: left;
                                    transition: transform 0.5s;
                                }

                                &:hover {
                                    color: #3F00FF;
                                    transition: all 0.5s;
                                }

                                &:hover::before {
                                    transform: scaleX(0);
                                    transform-origin: right;
                                    transition: transform 0.5s;
                                }
                            }
                        }
                    }
                }

                .swiper-button-prev {
                    top: inherit;
                    bottom: 0;
                    left: calc(50% - 80px);
                    width: 80px;
                    height: 50px;
                    border: 2px solid #FC6736;
                    background: #FFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 30px;
                    transform: translateX(-10%);

                    &::after {
                        font-size: 20px;
                        color: #FC6736;
                        font-weight: 700;
                    }
                }

                .swiper-button-next {
                    top: inherit;
                    bottom: 0;
                    left: inherit;
                    right: calc(50% - 80px);
                    width: 80px;
                    height: 50px;
                    border: 2px solid #FC6736;
                    background: #FC6736;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 30px;
                    transform: translateX(10%);

                    &::after {
                        font-size: 20px;
                        color: #FFF;
                        font-weight: 700;
                    }
                }
            }
        }
    }
`;

export const BookingListWrapper = styled('div')`
    position: relative;
    width: 100%;
    padding: 150px 100px;

    .page_inner {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;

        .booking_list_page_sec {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 80px 0;

            .page_heading {
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                h2 {
                    position: relative;
                    display: flex;
                    align-items: center;

                    span:nth-of-type(1) {
                        position: relative;
                        font-size: 55px;
                        font-weight: 700;
                        font-family: arista-pro-trial-bold;
                        color: #000;
                        z-index: 1;
                    }

                    span:nth-of-type(2) {
                        position: relative;
                        margin-left: 20px;
                        font-size: 55px;
                        font-family: arista-pro-alt-bold;
                        color: #FC6736;
                    }

                    svg {
                        position: absolute;
                        left: -50px;
                        width: 320px;
                        overflow: inherit;
                    }

                    @media (max-width: 1399px) {
                        span:nth-of-type(1) {
                            font-size: 45px;
                        }

                        span:nth-of-type(2) {
                            font-size: 45px;
                        }

                        svg {
                            left: -50px;
                            width: 250px;
                        }
                    }

                    @media (max-width: 575px) {
                        span:nth-of-type(1) {
                            font-size: 35px;
                        }

                        span:nth-of-type(2) {
                            font-size: 35px;
                        }

                        svg {
                            left: -50px;
                            width: 200px;
                        }
                    }

                    @media (max-width: 450px) {
                        span:nth-of-type(1) {
                            font-size: 30px;
                        }

                        span:nth-of-type(2) {
                            font-size: 30px;
                        }

                        svg {
                            left: -20px;
                            width: 180px;
                        }
                    }
                }
            }

            .list_sec {
                position: relative;
                margin-top: 85px;
                width: 100%;
                display: flex;

                .sec_left {
                    position: sticky;
                    top: 150px;
                    width: 350px;
                    max-height: 100vh;
                    padding-right: 50px;
                    border-right: 1px solid rgb(177, 177, 177);

                    .left_inner {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        a {
                            position: relative;
                            width: 100%;
                            height: 80px;
                            margin-bottom: 20px;
                            display: flex;
                            align-items: center;
                            padding: 10px 30px;
                            font-size: 20px;
                            color: #555;
                            border-radius: 10px 0 0 10px;
                            text-decoration: none;
                            background: rgb(231, 231, 231);
                            transition: all 0.5s;

                            &:last-child {
                                margin-bottom: 0%;
                            }

                            &::before {
                                content: '';
                                position: absolute;
                                top: 12px;
                                right: -28.5px;
                                width: 56.5px;
                                height: 56.5px;
                                background: rgb(231, 231, 231);
                                transform: rotate(45deg);
                                transition: all 0.5s;
                            }

                            &:hover {
                                color: #000;
                                background: #FFC76C;
                                transition: all 0.5s;
                            }

                            &:hover::before {
                                background: #FFC76C;
                                transition: all 0.5s;
                            }

                            &.active {
                                background: #FC6736;
                                color: #FFF;

                                &::before {
                                    background: #FC6736;
                                }
                            }
                        }
                    }
                }

                .sec_right {
                    position: relative;
                    width: calc(100% - 350px);
                    padding-left: 50px;
                }
            }
        }
    }
`;

export const TicketListWrapper = styled('div')`
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .ticket_box {
        position: relative;
        /* border: 1px solid #000; */
        padding: 10px;

        .box_inner {
            position: relative;
            padding: 25px;
            border-radius: 8px;
            box-shadow: 5px 7px 15px rgba(99, 99, 99, 0.219),
                        -3px -3px 6px rgba(207, 207, 207, 0.219);

            .ticket {
                position: relative;
                width: 350px;
                height: 125px;
                display: flex;
                cursor: pointer;

                .left_part {
                    position: relative;
                    width: calc(100% - 85px);
                    height: 100%;
                    padding: 10px 15px;
                    padding-right: 25px;

                    .cuts {
                        position: absolute;
                        top: 0;
                        left: -6px;
                        width: fit-content;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-direction: column;
                        padding: 3px 0;

                        .cut {
                            position: relative;
                            width: 10px;
                            height: 10px;
                            background: #FFF;
                            border-radius: 50%;
                        }
                    }

                    .content {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        border: 1px solid #FFF;
                        border-radius: 3px;
                        padding: 6px 10px;

                        h4 {
                            position: relative;
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            font-size: 16px;
                            line-height: 1.3;
                            text-overflow: ellipsis;
                            font-weight: 600;
                        }

                        .date_time {
                            position: relative;
                            margin-top: 4px;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 15px;
                            line-height: 1;
                            opacity: 0.9;
                            font-weight: 500;

                            span {
                                font-size: 7px;
                            }
                        }

                        .section {
                            position: relative;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            display: 15px;
                            line-height: 1;
                            margin-top: 2px;
                            font-weight: 500;
                            opacity: 0.9;

                            span {
                                position: relative;
                                width: 70px;
                            }

                            p {
                                position: relative;
                                width: calc(100% - 70px);
                                padding-left: 4px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }

                        .seats {
                            position: relative;
                            width: 100%;
                            margin-top: 2px;
                            display: flex;
                            align-items: center;
                            display: 15px;
                            line-height: 1;
                            margin-top: 2px;
                            font-weight: 500;
                            opacity: 0.9;

                            span {
                                position: relative;
                                width: 53px;
                            }

                            p {
                                position: relative;
                                width: calc(100% - 53px);
                                padding-left: 5px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }

                .right_part {
                    position: relative;
                    width: 85px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-image: linear-gradient(rgba(255, 255, 255) 45%, rgba(255, 255, 255, 0) 0%);
                    background-position: left;
                    background-size: 2px 21px;
                    background-repeat: repeat-y;
                    padding: 10px 15px;
                    padding-left: 25px;

                    .cuts {
                        position: absolute;
                        right: -6px;
                        width: fit-content;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-direction: column;
                        padding: 3px 0;

                        .cut {
                            position: relative;
                            width: 10px;
                            height: 10px;
                            background: #FFF;
                            border-radius: 50%;
                        }
                    }

                    .number {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        border: 1px solid #FFF;
                        border-radius: 3px;
                        font-family: 'Oswald', sans-serif;
                        text-align: center;
                        font-size: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        letter-spacing: 0.33px;

                        p {
                            position: relative;
                            font-family: 'Oswald', sans-serif; 
                            transform: rotate(-90deg);
                        }
                    }
                }

                .circles {
                    position: absolute;
                    width: fit-content;
                    height: calc(100% + 30px);
                    top: 50%;
                    transform: translate(0, -50%);
                    right: 70px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;

                    .circle {
                        position: relative;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background: #FFF;
                    }
                }
            }

            .cancel_tag {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                pointer-events: none;

                img {
                    position: relative;
                    width: 210px;
                    height: auto;
                }
            }
        }

        &:nth-child(4n + 1) .ticket.upcoming {
            background-color: #02C0FF;
            color: #FFF;
        }

        &:nth-child(4n + 2) .ticket.upcoming {
            background-color: #FF33A1;
            color: #FFF;
        }

        &:nth-child(4n + 3) .ticket.upcoming {
            background-color: rgba(28, 66, 255, 0.801) ;
            color: #FFF;
        }

        &:nth-child(4n) .ticket.upcoming {
            background-color: #FC6736;
            color: #FFF;
        }

        &:nth-child(5n) .ticket.upcoming {
            background-color: #3FE44F ;
            color: #FFF;
        }

        .ticket.previous {
            background-color: rgba(77, 77, 77, 0.425) ;
            color: #FFF;
        }

        .ticket.cancelled {
            background-color: rgba(128, 128, 128, 0.397) ;
            color: #FFF;
        }
    }

    .empty_message {
        position: relative;
        width: 100%;
        min-height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        p {
            position: relative;
            font-size: 22px;
            font-family: 'Lemonada', cursive;
            font-weight: 500;
            color: rgb(202, 202, 202);
        }
    }
`;

export const TicketDetailsWrapper = styled('div')`
    position: fixed;
    top: 120px;
    right: -120%;
    width: 400px;
    z-index: 1200;
    height: calc(100vh - 120px);
    background: #FFF;
    border-radius: 10px 0 0 0;
    box-shadow: -5px 2px 10px 0px rgba(0,0,0,0.1);
    border-top: 1px solid #E1E0EA;
    transition: all 0.8s ease-in-out;

    &.active {
        right: 0;
        transition: all 0.6s ease-in-out;
    }

    .sidebar_inner {
        position: relative;
        width: 100%;
        height: 100%;

        .ticket_outer {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 40px 35px;
            overflow-y: auto;
            scrollbar-width: none;

            &.upcoming {
                height: calc(100% - 125px);
            }

            .ticket_box {
                position: relative;
                width: 100%;
                background: #FFC76C;
                min-height: 250px;
                display: flex;
                flex-direction: column;

                .top_part {
                    position: relative;
                    width: 100%;
                    border-bottom: 2px dashed #000;

                    .dots {
                        position: absolute;
                        left: 0;
                        top: -7px;
                        width: 100%;
                        height: fit-content;
                        padding: 0px 5px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .dot {
                            position: relative;
                            width: 12px;
                            height: 12px;
                            background: #FFF;
                            border-radius: 50%;
                        }
                    }

                    .top_items {
                        position: relative;
                        padding: 30px 25px;
                        padding-top: 40px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .poster_img {
                            position: relative;
                            width: 80px;
                            height: 100px;
                            border-radius: 10px;
                            overflow: hidden;

                            img {
                                position: relative;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        h4 {
                            position: relative;
                            margin-top: 15px;
                            width: 100%;
                            text-align: center;
                            font-size: 17px;
                            font-weight: 600;
                        }

                        .date_time {
                            position: relative;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 5px;
                            line-height: 1;
                            font-size: 15px;
                            font-weight: 500;

                            span {
                                margin: 0px 12px;
                            }
                        }

                        h5 {
                            position: relative;
                            margin-top: 5px;
                            font-size: 17px;
                            font-weight: 600;
                        }
                    }

                    .circles {
                        position: absolute;
                        bottom: -20px;
                        width: calc(100% + 40px);
                        transform: translateX(-20px);
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .circle {
                            position: relative;
                            width: 40px;
                            height: 40px;
                            background: #FFF;
                            border-radius: 50%;
                        }
                    }
                }

                .bottom_part {
                    position: relative;
                    width: 100%;
                    padding: 30px 25px;

                    .bottom_items {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .id_sec {
                            position: relative;
                            display: flex;
                            align-items: center;
                            font-size: 16px;
                            line-height: 1;
                            font-weight: 500;
                            opacity: 0.9;

                            span {
                                position: relative;
                                margin-right: 6px;
                            }
                        }

                        .seats_sec {
                            position: relative;
                            margin-top: 10px;
                            display: flex;

                            span {
                                position: relative;
                                font-weight: 500;
                                font-size: 16px;
                                white-space: nowrap;
                            }

                            i {
                                position: relative;
                                font-size: 6px;
                                margin: 0px 10px;
                                padding: 8px 0;
                            }

                            p {
                                position: relative;
                                font-size: 16px;
                            }
                        }

                        .qrcode_sec {
                            position: relative;
                            width: 100px;
                            margin-top: 20px;
                            padding: 6px;
                            border: 1px solid #000;
                            border-radius: 6px;
                            display: flex;

                            canvas {
                                position: relative;
                                width: 100% !important;
                                height: auto !important;
                            }
                        }
                    }
                }

                .price_part {
                    position: relative;
                    border-top: 1px solid #000;
                    width: 100%;
                    padding: 15px 25px;
                    padding-bottom: 25px;

                    .dots {
                        position: absolute;
                        left: 0;
                        bottom: -7px;
                        width: 100%;
                        height: fit-content;
                        padding: 0px 5px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .dot {
                            position: relative;
                            width: 12px;
                            height: 12px;
                            background: #FFF;
                            border-radius: 50%;
                        }
                    }

                    .price_items {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        .item_inner {
                            position: relative;
                            width: 100%;
                            display: flex;
                            align-items: center;

                            span {
                                position: relative;
                                font-size: 16px;
                                font-weight: 500;
                            }

                            p {
                                position: relative;
                                margin-left: auto;
                                font-size: 16px;
                                font-weight: 500;
                            }

                            a {
                                position: relative;
                                margin-left: 10px;
                                font-size: 15px;
                                cursor: pointer;

                                i {
                                    transition: all 0.6s;
                                }

                                &.active i {
                                    transform: rotate(-180deg);
                                    transition: all 0.6s;
                                }
                            }
                        }

                        .inner_details {
                            position: relative;
                            width: 100%;
                            display: flex;
                            justify-content: flex-end;
                            max-height: 0;
                            overflow: hidden;
                            transition: all 0.6s;

                            &.active {
                                max-height: 250px;
                                transition: all 0.6s;
                            }

                            .details_box {
                                position: relative;
                                padding-top: 10px;

                                .details {
                                    position: relative;
                                    width: max-content;
                                    padding-top: 10px;
                                    border-top: 1px solid #000;
                                    display: flex;
                                    flex-direction: column;

                                    .details_line {
                                        position: relative;
                                        width: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        font-size: 15px;
                                        line-height: 1;
                                        margin-bottom: 4px;

                                        span {
                                            position: relative;
                                            font-weight: 500;
                                            color: #000;
                                        }

                                        p {
                                            position: relative;
                                            margin-left: 15px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .cancel_btn {
            position: relative;
            width: 100%;
            height: 125px;
            padding: 35px;
            border-top: 1px solid rgb(177, 177, 177);

            button {
                position: relative;
                width: 100%;
                height: 55px;
                border: none;
                font-size: 17px;
                font-weight: 500;
                background: #000;
                color: #FFF;
                border-radius: 6px;
                cursor: pointer;
            }
        }
    }
`;