import { styled } from "@mui/material";


export const CinemaWrapper = styled('div')`
    position: relative;
    width: 100%;
    padding: 150px 100px;

    .page_inner {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;

        .cinema_box_sec {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 80px 0;

            .box_head {
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                h2 {
                    position: relative;
                    display: flex;
                    align-items: center;

                    span:nth-of-type(1) {
                        position: relative;
                        font-size: 55px;
                        font-weight: 700;
                        font-family: arista-pro-trial-bold;
                        color: #000;
                        z-index: 1;
                    }

                    span:nth-of-type(2) {
                        position: relative;
                        margin-left: 20px;
                        font-size: 55px;
                        font-family: arista-pro-alt-bold;
                        color: #FC6736;
                    }

                    svg {
                        position: absolute;
                        left: -50px;
                        width: 320px;
                        overflow: inherit;
                    }

                    @media (max-width: 1399px) {
                        span:nth-of-type(1) {
                            font-size: 45px;
                        }

                        span:nth-of-type(2) {
                            font-size: 45px;
                        }

                        svg {
                            left: -50px;
                            width: 250px;
                        }
                    }

                    @media (max-width: 575px) {
                        span:nth-of-type(1) {
                            font-size: 35px;
                        }

                        span:nth-of-type(2) {
                            font-size: 35px;
                        }

                        svg {
                            left: -50px;
                            width: 200px;
                        }
                    }

                    @media (max-width: 450px) {
                        span:nth-of-type(1) {
                            font-size: 30px;
                        }

                        span:nth-of-type(2) {
                            font-size: 30px;
                        }

                        svg {
                            left: -20px;
                            width: 180px;
                        }
                    }
                }
            }

            .no_data_message {
                position: relative;
                width: 100%;
                margin-top: 85px;
                display: flex;
                justify-content: center;
                text-align: center;

                h3 {
                    position: relative;
                    font-family: 'Lemonada', cursive;
                    font-size: 25px;
                    color: #AAA;
                }
            }

            .box_sec {
                position: relative;
                width: 1500px;
                margin-top: 80px;
                display: flex;
                flex-wrap: wrap;

                .cinema_box {
                    position: relative;
                    width: 20%;
                    padding: 30px 25px;
                    cursor: pointer;
                    border-radius: 8px;
                    transition: all 0.5s;

                    &:hover {
                        box-shadow: 10px 15px 27px rgba(255, 199, 108, 0.8),
                                    -5px -5px 10px rgba(255, 255, 108, 0.8);
                    }

                    .box_inner {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .icon {
                            position: relative;
                            display: flex;
                            justify-content: center;

                            img {
                                position: relative;
                                width: 100px;
                            }
                        }

                        .cinema_info {
                            position: relative;
                            margin-top: 25px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;

                            h5 {
                                position: relative;
                                font-family: 'Lemonada', cursive;
                                font-size: 18px;
                                color: #000;
                            }

                            p {
                                position: relative;
                                font-family: 'Poppins', sans-serif;
                                font-size: 15px;
                                color: #555;
                            }
                        }
                    }

                    @media (max-width: 1599px) {
                        width: 25%;
                    }

                    @media (max-width: 991px) {
                        width: 33.33%;
                    }

                    @media (max-width: 768px) {
                        width: 50%;
                    }

                    @media (max-width: 475px) {
                        width: 100%;
                    }
                }

                @media (max-width: 1599px) {
                    width: 1050px;
                }
            
                @media (max-width: 1150px) {
                    width: 100%;

                    .cinema_box .box_inner .icon img {
                        width: 80px;
                    }
                    .cinema_box .box_inner .cinema_info h5 {
                        font-size: 16px;
                    }
                    .cinema_box .box_inner .cinema_info p {
                        margin-top: 4px;
                        font-size: 14px;
                    }
                }

                @media (max-width: 768px) {
                    .cinema_box .box_inner .icon img {
                        width: 65px;
                    }
                } 

                @media (max-width: 575px) {
                    .cinema_box .box_inner .cinema_info h5 {
                        font-size: 15px;
                    }
                } 
            }
        }
    }

    @media (max-width: 1250px) {
        padding: 130px 70px;
    }

    @media (max-width: 991px) {
        padding: 130px 50px;
    }

    @media (max-width: 575px) {
        padding: 130px 35px;
    }

    @media (max-width: 475px) {
        padding: 130px 25px;
    }
`;