import { useEffect, useState } from "react";
import { TicketDetailsWrapper } from "../Styles/Booking-Style";
import QRCode from 'qrcode.react';
import axios from "axios";
import { Fetch_Tickets_Details_URL, Poster_Image_URL } from "../API/Api";



function TicketDetails({bookingId, currTicket, showDetails}) {

    const [priceDetailsShow, setPriceDetailsShow] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState([]);
    const [qrData, setqrData] = useState({ booking_id: '', user: '' });


    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("UserData"));
        axios.get(`${Fetch_Tickets_Details_URL}?name=${user.user}&&bookingId=${bookingId}`)
        .then(res => {
            console.log(res.data.ticket);
            setqrData({
                booking_id: res.data.ticket.booking_id,
                user: res.data.ticket.username
            });
            setSelectedTicket(res.data.ticket)
        })
        .catch(err => {
            // console.log(err);
            setSelectedTicket([]);
        })
    }, [bookingId]);

    const togglePriceDetails = () => {
        setPriceDetailsShow(!priceDetailsShow);
    };

    return(
        <>
            <TicketDetailsWrapper className={showDetails ? 'active' : ''}>
                <div className="sidebar_inner">
                    <div className={`ticket_outer ${currTicket ? 'upcoming' : ''}`}>
                        <div className="ticket_box">
                            <div className="top_part">
                                <div className="dots">
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                </div>
                                <div className="top_items">
                                    <div className="poster_img">
                                        <img src={`${Poster_Image_URL}/${selectedTicket.poster_image}`} alt="poster" />
                                    </div>
                                    <h4>{selectedTicket.movie}</h4>
                                    <div className="date_time">
                                        <p>{selectedTicket.day}, {selectedTicket.date}</p>
                                        <span>|</span>
                                        <p>{selectedTicket.time}</p>
                                    </div>
                                    <h5>{selectedTicket.theater}</h5>
                                </div>
                                <div className="circles">
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                </div>
                            </div>
                            <div className="bottom_part">
                                <div className="bottom_items">
                                    <div className="id_sec">
                                        <span>Booking ID :</span>
                                        <p>{selectedTicket.booking_id}</p>
                                    </div>
                                    <div className="seats_sec">
                                        <span>{selectedTicket.class}</span>
                                        <i className="fa-solid fa-circle"></i>
                                        <p>{selectedTicket.seats}</p>
                                    </div>
                                    <div className="qrcode_sec">
                                        <QRCode value={JSON.stringify(qrData)} bgColor="transparent" />
                                    </div>
                                </div>
                            </div>
                            <div className="price_part">
                                <div className="dots">
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                </div>
                                <div className="price_items">
                                    <div className="item_inner">
                                        <span>Total Amount</span>
                                        <p>{(Number(selectedTicket.ticket_price) + Number(selectedTicket.base_convenience) + Number(selectedTicket.gst)).toFixed(2)}</p>
                                        <a className={priceDetailsShow ? 'active' : ''} onClick={togglePriceDetails}><i className="fa-solid fa-angle-down"></i></a>
                                    </div>
                                    <div className={`inner_details ${priceDetailsShow ? 'active' : ''}`}>
                                        <div className="details_box">
                                            <div className="details">
                                                <div className="details_line">
                                                    <span>Ticket Price :</span>
                                                    <p>{selectedTicket.ticket_price}</p>
                                                </div>
                                                <div className="details_line">
                                                    <span>Convenience Fee :</span>
                                                    <p>{(Number(selectedTicket.base_convenience) + Number(selectedTicket.gst)).toFixed(2)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        currTicket && <div className="cancel_btn">
                            <button>Cancel Ticket</button>
                        </div> 
                    }
                </div>
            </TicketDetailsWrapper>
        </>
    );
}


export default TicketDetails;