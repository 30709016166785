import { useEffect, useState } from "react";
import { ProfileImagesBox } from "../Styles/Modal-Style";
import axios from "axios";
import { Profile_Image_URL, Profile_Image_Update_URL, Profile_Images_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';



function ProfileImages({profileImgModalShow, setProfileImgModalShow, userImage, setUserImage}) {

    const [userName, setUserName] = useState('');
    const [profileImages, setProfileImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageName, setSelectedImageName] = useState('');


    useEffect(() => {

        let loginStatus = localStorage.getItem("Login");

        if(loginStatus == "true"){
            let user = JSON.parse(localStorage.getItem("UserData"));
            setUserName(user.user);
        }

    }, []);

    useEffect(() => {

        axios.get(Profile_Images_URL)
        .then(res => {
            // console.log(res);
            setProfileImages(res.data.images);
            const defaultIndex = res.data.images.findIndex(image => image.profile_image === userImage);
            if (defaultIndex !== -1) {
                setSelectedImage(defaultIndex);
                setSelectedImageName(userImage);
            }
        })
        .catch(err => {
            // console.log(err);
            setProfileImages([]);
        })

    }, [userImage]);

    const handleImageClick = (index, imageName) => {
        setSelectedImage(index);
        setSelectedImageName(imageName);
        setUserImage(imageName);
    };

    const handleImageUpdate = () => {

        const inputs = {
            user: userName,
            image_name: selectedImageName
        }

        axios.post(Profile_Image_Update_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
            toast.success('Profile Image Updated !!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            setProfileImgModalShow(false);
        })
        .catch(err => {
            console.log(err);
        })

    };

    return(
        <>
            <ProfileImagesBox className={profileImgModalShow ? 'active' : ''}>
                <div className="close_btn" onClick={() => setProfileImgModalShow(false)}>
                    <a><i className="fa-solid fa-arrow-right-long"></i></a>
                </div>
                <div className="wrapper_inner">
                    <div className="img_box_sec">
                        {
                            profileImages.map((profileImage, index) => 
                                <div className={`img_box ${selectedImage === index ? 'selected' : ''}`} key={index} onClick={() => handleImageClick(index, profileImage.profile_image)}>
                                    <div className="box_inner">
                                        <div className="img">
                                            <img src={`${Profile_Image_URL}/${profileImage.profile_image}`} alt="profile" />
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className="btn_sec">
                        <a onClick={handleImageUpdate}><i className="fa-solid fa-pen-to-square"></i>Update</a>
                    </div>
                </div>
            </ProfileImagesBox>
        </>
    );
}


export default ProfileImages;