import { useContext, useEffect, useState } from "react";
import { CinemaWrapper } from "../Styles/Cinema-Style";
import Cinemainfo from "../Modals/CinemainfoModal";
import ModalBooking from "../Modals/BookingModal";
import axios from "axios";
import { Fetch_Theaters_URL } from "../API/Api";
import { DataContext } from "../Layouts/Data-Context";



function Cinemas() {

    const {selectedCity} = useContext(DataContext);

    const [count, setCount] = useState(false);
    const [theaters, setTheaters] = useState([]);
    const [theaterInfo, setTheaterInfo] = useState({name: '', commission: ''});
    const [showCinemaInfo, setShowCinemaInfo] = useState(false);
    const [showDetails, setShowDetails] = useState([]);
    const [bookedSeats, setBookedSeats] = useState('');
    const [bookingModalShow, setBookingModalShow] = useState(false);
    

    useEffect(() => {

        if(selectedCity) {
            axios.get(`${Fetch_Theaters_URL}?location=${selectedCity}`)
            .then(res => {
                console.log(res);
                if(res.data.count > 0){
                    setCount(true);
                    setTheaters(res.data.theaters);
                } else {
                    setCount(false);
                    setTheaters([]);
                }
            })
            .catch(err => {
                setCount(false);
                setTheaters([]);
            })
        } else {
            axios.get(Fetch_Theaters_URL)
            .then(res => {
                console.log(res);
                if(res.data.count > 0){
                    setCount(true);
                    setTheaters(res.data.theaters);
                } else {
                    setCount(false);
                    setTheaters([]);
                }
            })
            .catch(err => {
                setCount(false);
                setTheaters([]);
            })
        }

    }, [selectedCity]);

    function handleCinemaInfo(name, commission) {
        setShowCinemaInfo(!showCinemaInfo);
        setTheaterInfo({name: name, commission: commission});
    }

    return(
        <>
            <CinemaWrapper>
                <div className="page_inner">
                    <div className="cinema_box_sec">
                        <div className="box_head">
                            <h2>
                                <span>All</span>
                                <span>Cinemas</span>
                                <svg id="sw-js-blob-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
                                            <stop id="stop1" stopColor="#FC6736" offset="0%"></stop>
                                            <stop id="stop2" stopColor="rgba(251, 168, 31, 1)" offset="100%"></stop>
                                        </linearGradient>
                                    </defs>
                                    <path fill="url(#sw-gradient)" d="M9.9,-15C16.8,-13.2,28.9,-18.5,30.4,-17.1C31.8,-15.8,22.6,-7.9,17.2,-3.1C11.8,1.6,10.2,3.3,9.6,6.1C8.9,8.9,9.3,12.8,7.9,15.5C6.5,18.2,3.2,19.5,-0.2,20C-3.7,20.4,-7.4,19.8,-9.3,17.4C-11.2,15,-11.3,10.8,-13.9,7.6C-16.5,4.4,-21.6,2.2,-22,-0.2C-22.4,-2.6,-18.1,-5.3,-15.1,-7.8C-12.1,-10.4,-10.5,-12.8,-8.2,-17.2C-5.9,-21.5,-2.9,-27.8,-0.7,-26.6C1.5,-25.4,3.1,-16.7,9.9,-15Z" width="100%" height="100%" transform="translate(15 50)" strokeWidth="0"></path>
                                </svg>
                            </h2>
                        </div>
                        {
                            count ?
                            <div className="box_sec">
                                {
                                    theaters && theaters.map((theater, index) => 
                                        <div className="cinema_box" key={index} onClick={() => handleCinemaInfo(theater.name, theater.commission)}>
                                            <div className="box_inner">
                                                <div className="icon">
                                                    <img src="images/cinema.png" alt="Cinema Hall" />
                                                </div>
                                                <div className="cinema_info">
                                                    <h5>{theater.name}</h5>
                                                    <p>{`${theater.city} / ${theater.state}`}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            :
                            <div className="no_data_message">
                                <h3>No Theaters Found</h3>
                            </div>
                        }
                    </div>
                </div>
            </CinemaWrapper>

            <Cinemainfo showCinemaInfo={showCinemaInfo} setShowCinemaInfo={setShowCinemaInfo} handleCinemaInfo={handleCinemaInfo} theaterInfo={theaterInfo} setShowDetails={setShowDetails} setBookedSeats={setBookedSeats} setBookingModalShow={setBookingModalShow} />
            <ModalBooking bookingModalShow={bookingModalShow} setBookingModalShow={setBookingModalShow} selectedShowDetails={showDetails} bookedSeats={bookedSeats} />
        </>
    );
}



export default Cinemas;