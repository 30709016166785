import { useEffect, useRef, useState } from "react";
import { Nav, NavSideWrapper } from "../Styles/Nav-Footer-Style";
import { NavLink, useLocation } from "react-router-dom";
import axios from "axios";
import { Profile_Image_URL, User_Details_URL } from "../API/Api";
import states from '../Data/State-City.json';



function Navbar({showProfile, setShowProfile, handleSigninModal, handleProfileSidebar, userImage, setUserImage, setProfileModalShow, profileImgModalShow, selectedCity, setSelectedCity, setCityModalShow}) {

    const location = useLocation();
    const [username, setUsername] = useState(false);
    const [navBack, setNavBack] = useState(location.pathname === "/home");
    const [isScrolled, setIsScrolled] = useState(false);
    const [sideNavShow, setSideNavShow] = useState(false);

    useEffect(() => {
        let loginStatus = localStorage.getItem("Login");

        if(loginStatus == "true") {
            let user = JSON.parse(localStorage.getItem("UserData"));
            axios.get(`${User_Details_URL}?user=${user.user}`)
            .then(res => {
                console.log(res);
                setUserImage(res.data.user.image);
                setShowProfile(true);
                setUsername(user.user);
                setSelectedCity(res.data.user.city);
                localStorage.setItem("location", res.data.user.city);
            })
            .catch(err => {
                // console.log(err);
                setShowProfile(false);
            })
        } else {
            setShowProfile(false);
        }
        
    },[showProfile]);

    useEffect(() => {
        function handleScroll() {
            if(window.scrollY) {
                setIsScrolled(true);
            } else{
                setIsScrolled(false);
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    useEffect(() => {
        if(location.pathname !== "/home"){
            setNavBack(true);
        } else {
            setNavBack(false);
        }
    }, [location.pathname]);


    function signinModalOpen() {
        handleSigninModal();
        
        if(profileImgModalShow){
            console.log("Images are On");
        } else {
            console.log("Images are Off");
        }
    }

    const toggleSideNavbar = () => {
        setSideNavShow(!sideNavShow);
        setProfileModalShow(false);
    };

    const handleLogout = () => {
        localStorage.setItem("Login", "false");
        localStorage.removeItem("UserData");
        setProfileModalShow(false);
        setUserImage(null);
        window.location.reload();
    };

    return(
        <>
            <Nav className={`${navBack ? 'NavBg' : ''} ${isScrolled ? 'white' : ''}`}>
                <div className="nav_inner">
                    <div className="logo">
                        <img src="/images/Logo.png" alt="Logo" />
                    </div>
                    <div className="filter_box">
                        <div className="input_btn">
                            <a onClick={() => setCityModalShow(true)}><i className="fa-solid fa-location-dot"></i>{selectedCity}</a>
                        </div>
                    </div>
                    <div className="nav_items">
                        <ul>
                            <li><NavLink to="/home">Home</NavLink></li>
                            <li><NavLink to="/movies">Movies</NavLink></li>
                            <li><NavLink to="/cinema">Cinemas</NavLink></li>
                        </ul>
                        {
                            showProfile ? 
                            <div className="nav_profile">
                                <div className="profile_btn" onClick={handleProfileSidebar}>
                                    <img src={`${Profile_Image_URL}/${userImage}`} alt="user" />
                                    <p>{username}</p>
                                </div>
                            </div> 
                            :
                            <div className="nav_btn">
                                <a onClick={signinModalOpen}><span></span>Login / Register</a>
                            </div>
                        }
                    </div>
                    <div className="res_nav_btn">
                        <a onClick={toggleSideNavbar} className={sideNavShow ? 'active' : ''}>
                            <i className={`fa-solid fa-${sideNavShow ? 'xmark' : 'bars'}`}></i>
                        </a>
                    </div>
                </div>
            </Nav>
            <NavSideWrapper className={sideNavShow ? 'active' : ''}>
                <div className="sidebar_inner">
                    {
                        showProfile &&
                        <div className="profile_sec">
                            <div className="profile_btn" onClick={handleProfileSidebar}>
                                <img src={`${Profile_Image_URL}/${userImage}`} alt="user" />
                                <p>{username}</p>
                            </div>
                        </div>
                    }
                    <div className="sidebar_items">
                        <ul>
                            <li><NavLink to="/home">Home</NavLink></li>
                            <li><NavLink to="/movies">Movies</NavLink></li>
                            <li><NavLink to="/cinema">Cinemas</NavLink></li>
                        </ul>
                    </div>
                    <div className="auth_btn">
                        {
                            showProfile ? 
                            <a className="login_btn" onClick={handleLogout}><i className="fa-solid fa-right-from-bracket"></i>Logout</a>
                            :
                            <a className="login_btn" onClick={() => {signinModalOpen(); setSideNavShow(false)}}><i className="fa-solid fa-right-to-bracket"></i>Login / Register</a>
                        }
                    </div>
                </div>
            </NavSideWrapper>
        </>
    );
}


export default Navbar;