import { useEffect, useState } from "react";
import { CheckoutModalBox } from "../Styles/Modal-Style";
import axios from "axios";
import { Payment_URL, User_Details_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';


function ModalCheckout({checkoutModalShow, setcheckoutModalShow, selectedShowDetails, seatsInfo, setSelectedSeats}) {

    const [userName, setUserName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [selectedSeatsInfo, setSelectedSeatsInfo] = useState([]);
    const [secName, setSecName] = useState('');
    const [dayName, setDayName] = useState('');
    const [bookingSeats, setBookingSeats] = useState('');
    const [totalTicketPrice, setTotalTicketPrice] = useState('');
    const [baseCommission, setBaseCommission] = useState('');
    const [gstAmount, setGstAmount] = useState('');
    const [totalCommission, setTotalCommission] = useState('');
    const [showCommisionDetails, setShowCommisionDetails] = useState(false);
    const [totalAmount, setTotalAmount] = useState('');
    const [ticketCount, setTicketCount] = useState('');


    useEffect(() => {
        if(localStorage.getItem("UserData")){
            const userData = JSON.parse(localStorage.getItem("UserData"));
            const user = userData.user;

            axios.get(`${User_Details_URL}?user=${user}`)
            .then(res => {
                console.log(res);
                setUserName(res.data.user.name);
                setPhoneNumber(res.data.user.phone);
                setUserEmail(res.data.user.email);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [checkoutModalShow]);

    useEffect(() => {

        if(checkoutModalShow) {
            console.log(selectedShowDetails);
            setSelectedSeatsInfo(seatsInfo);

            const totalTicketPrice = seatsInfo.reduce((total, seatInfo) => {
                return total + parseFloat(seatInfo.sectionTotalPrice);
            }, 0);

            const baseCommission = seatsInfo.reduce((total, seatInfo) => {
                return total + parseFloat(seatInfo.commission);
            }, 0);

            const gst = baseCommission * 0.18;
            const totalCommission = baseCommission + gst;
            const totalAmount = totalTicketPrice + totalCommission;

            // Extracting all seat numbers and counting total seats
            const allSeatNumbers = seatsInfo.flatMap(seatInfo =>
                seatInfo.selectedSeats.map(seat => seat.seatNumber)
            );
            const seatNumbersJoined = allSeatNumbers.join(', ');
            const totalSeatsCount = allSeatNumbers.length;

            // Extracting the short day name from the date
            const showDate = new Date(selectedShowDetails.date);
            const dayNameShort = showDate.toLocaleDateString('en-US', { weekday: 'short' });

            setTotalTicketPrice(totalTicketPrice.toFixed(2));
            setBaseCommission(baseCommission.toFixed(2));
            setGstAmount(gst.toFixed(2));
            setTotalCommission(totalCommission.toFixed(2));
            setTotalAmount(totalAmount.toFixed(2));
            setSecName(seatsInfo[0].sectionName);
            setBookingSeats(seatNumbersJoined);
            setDayName(dayNameShort);
            setTicketCount(totalSeatsCount);
        }
    }, [checkoutModalShow]);

    const closeModal = () => {
        setcheckoutModalShow(false);
        setSelectedSeats([]);
    };

    function handleCommissionDetails() {
        setShowCommisionDetails(!showCommisionDetails);
    };

    const handlePayment = () => {

        const inputs = { 
            amount: totalAmount, 
            name: userName,
            movie: selectedShowDetails.movieName,
            language: selectedShowDetails.language,
            class: secName,
            seats: bookingSeats,
            time: selectedShowDetails.time,
            day: dayName,
            date: selectedShowDetails.date,
            theater: selectedShowDetails.theaterName,
            screen: selectedShowDetails.screen,
            screenId: selectedShowDetails.screenId,
            ticketPrice: totalTicketPrice,
            baseConvenience: baseCommission,
            gst: gstAmount,
            ticketCount: ticketCount
        };

        console.log(inputs);
        const loginStatus = localStorage.getItem("Login");

        if(loginStatus) {
            if(loginStatus === "true") {
                axios.post(Payment_URL, inputs, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                .then(res => {
                    if (res.data.success) {
                        window.location.href = res.data.paymentUrl;
                    } else {
                        console.log('Payment failed: ' + res.data.message);
                    }
                })
                .catch(err => {
                    console.log("Error:", err);
                });
            } else {
                toast.warn('You are not logged in !!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        } else {
            toast.warn('You are not logged in !!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    };

    return(
        <>
            <CheckoutModalBox className={checkoutModalShow ? 'active' : ''}>
                <div className={`modal_box ${checkoutModalShow ? 'active' : ''}`}>
                    <div className="close_btn">
                        <a onClick={closeModal}><i className="fa-solid fa-xmark"></i></a>
                    </div>
                    <div className="box_inner">
                        <div className="top_sec">
                            <h3>{selectedShowDetails.theaterName}</h3>
                            <h6>{selectedShowDetails.movieName}</h6>
                            <div className="top_sec_items">
                                <li><p><span>Screen :</span>{selectedShowDetails.screen}</p></li>
                                <li><p><span>Lanuage :</span>{selectedShowDetails.language}</p></li>
                                <li><p><span>Date :</span>{selectedShowDetails.date}</p></li>
                                <li><p><span>Time :</span>{selectedShowDetails.time}</p></li>
                            </div>
                        </div>
                        <div className="bottom_sec">
                            <div className="ticket_sec">
                                <div className="ticket_heading">
                                    <h5>Tickets</h5>
                                </div>
                                <table>
                                    <thead>
                                        <th>Class</th>
                                        <th>Row</th>
                                        <th>Seats</th>
                                    </thead>
                                    <tbody>
                                        {             
                                            selectedSeatsInfo && selectedSeatsInfo.map((info) => (
                                                info.rows.map((row, i) => 
                                                <tr key={i}>
                                                    <td>{info.sectionName}</td>
                                                    <td>{row.rowIndex}</td>
                                                    <td>{`${row.seats.replace(/["[\]]/g, '').split(',').join(', ')}`}</td>
                                                </tr>)
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="amount_sec">
                                <div className="amount_inner">
                                    <div className="amount_top">
                                        <li>
                                            <p>Ticket Amount :</p>
                                            <span>{totalTicketPrice}/-</span>
                                        </li>
                                        <li>
                                            <p>Convenience fees :</p>
                                            <span>{totalCommission}/-</span>
                                            <a onClick={handleCommissionDetails}><i className={`fa-solid fa-angle-down ${showCommisionDetails ? 'active' : ''}`}></i></a>
                                        </li>
                                    </div>
                                    <div className={`convenience_details ${showCommisionDetails ? 'active' : ''}`}>
                                        <div className="details_inner">
                                            <li>
                                                <p>Base Amount :</p>
                                                <span>{baseCommission}/-</span>
                                            </li>
                                            <li>
                                                <p> GST @ 18% :</p>
                                                <span>{gstAmount}/-</span>
                                            </li>
                                        </div>
                                    </div>
                                    <div className="amount_bottom">
                                        <p>Total Amount :</p>
                                        <span>{totalAmount}/-</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="button_sec">
                            <div className="user_details_sec">
                                <h5>Your Details:</h5>
                                <ul>
                                    <li>
                                        <span>Email:</span>
                                        <p>{userEmail}</p>
                                    </li>
                                    <li>
                                        <span>Phone:</span>
                                        <p>{phoneNumber}</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="btn_sec">
                                <a onClick={handlePayment}><span>Pay Now<i className="fa-solid fa-arrow-right"></i></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </CheckoutModalBox>
        </>
    );
}


export default ModalCheckout;