import { styled } from "@mui/material";


export const MovieWrapper = styled('div')`
    position: relative;
    width: 100%;
    padding: 100px ;
    padding-top: 150px;

    .page_inner {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;

        .movie_box_sec {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 80px 0;
        }

        .box_head {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            h2 {
                position: relative;
                display: flex;
                align-items: center;

                span:nth-of-type(1) {
                    position: relative;
                    font-size: 55px;
                    font-weight: 700;
                    font-family: arista-pro-trial-bold;
                    color: #000;
                    z-index: 1;
                }

                span:nth-of-type(2) {
                    position: relative;
                    margin-left: 20px;
                    font-size: 55px;
                    font-family: arista-pro-alt-bold;
                    color: #FC6736;
                }

                svg {
                    position: absolute;
                    left: -50px;
                    width: 320px;
                    overflow: inherit;
                }

                @media (max-width: 1399px) {
                    span:nth-of-type(1) {
                        font-size: 45px;
                    }

                    span:nth-of-type(2) {
                        font-size: 45px;
                    }

                    svg {
                        left: -50px;
                        width: 250px;
                    }
                }

                @media (max-width: 575px) {
                    span:nth-of-type(1) {
                        font-size: 35px;
                    }

                    span:nth-of-type(2) {
                        font-size: 35px;
                    }

                    svg {
                        left: -50px;
                        width: 200px;
                    }
                }

                @media (max-width: 450px) {
                    span:nth-of-type(1) {
                        font-size: 30px;
                    }

                    span:nth-of-type(2) {
                        font-size: 30px;
                    }

                    svg {
                        left: -20px;
                        width: 180px;
                    }
                }
            }
        }

        .no_data_message {
            position: relative;
            width: 100%;
            margin-top: 85px;
            display: flex;
            justify-content: center;
            text-align: center;

            h3 {
                position: relative;
                font-family: 'Lemonada', cursive;
                font-size: 25px;
                color: #AAA;
            }
        }

        .box_sec {
            position: relative;
            width: 1500px;
            margin-top: 80px;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            .movie_box {
                position: relative;
                width: 350px;
                padding: 25px;
                border-radius: 10px;
                transition: all 0.5s;
                margin-bottom: 15px;

                .box_inner {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .movie_poster {
                        position: relative;
                        width: 100%;
                        height: 400px;
                        border-radius: 6px;
                        overflow: hidden;
                        display: flex;

                        img {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        span {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            background: rgba(0,0,0,0.8);
                            width: 100%;
                            padding: 25px;
                            color: #FFF;
                            font-family: "Russo One", sans-serif;
                            font-size: 16px;
                            letter-spacing: 0.66px;
                            transform: translateY(100%);
                            transition: all 0.5s;
                        }
                    }

                    .movie_info {
                        position: relative;
                        margin-top: 15px;

                        h3 {
                            position: relative;
                            width: 100%;
                            font-size: 20px;
                            line-height: 1.5;
                            font-weight: 400;
                            font-family: "Russo One", sans-serif;
                            letter-spacing: 0.5px;
                            color: #000;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        p {
                            position: relative;
                            width: 100%;
                            display: block;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            margin-top: 6px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            line-height: 1;
                            color: #555;
                        }

                        a {
                            position: relative;
                            margin-top: 20px;
                            width: 100%;
                            height: 55px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-decoration: none;
                            font-family: 'Poppins', sans-serif;
                            font-size: 18px;
                            font-weight: 600;
                            cursor: pointer;
                            border-radius: 5px;
                            border: 2px solid #3F00FF;
                            color: #FFF;
                            overflow: hidden;
                            transition: all 0.5s;

                            span {
                                position: relative;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                z-index: 5;
                            }

                            i {
                                position: relative;
                                font-size: 18px;
                                margin-right: 10px;
                                transform: rotate(-45deg);

                                &.details {
                                    transform: rotate(0);
                                }
                            }

                            &::before {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background: #3F00FF;
                                transform: scaleX(1);
                                transform-origin: left;
                                transition: transform 0.5s;
                            }

                            &:hover {
                                color: #3F00FF;
                                transition: all 0.5s;
                            }

                            &:hover::before {
                                transform: scaleX(0);
                                transform-origin: right;
                                transition: transform 0.5s;
                            }
                        }
                    }
                }

                &:hover {
                    background: #FFF;
                    box-shadow: 10px 15px 27px rgba(255, 199, 108, 0.9),
                                    -6px -7px 12px rgba(255, 255, 108, 0.8);
                    transition: all 0.5s;
                }

                &:hover .box_inner .movie_poster span {
                    transform: translateY(0);
                    transition: all 0.5s;
                }

                @media (max-width: 1700px) {
                    width: 25%;
                }

                @media (max-width: 1599px) {
                    width: 33.33%;
                }

                @media (max-width: 768px) {
                    width: 50%;

                    .box_inner .movie_poster span {
                        display: none;
                    }
                }

                @media (max-width: 475px) {
                    margin-bottom: 20px;
                }
            }

            
            @media (max-width: 1599px) {
                width: 1050px;
            }

            
            @media (max-width: 1150px) {
                width: 100%;

                .movie_box .box_inner .movie_poster {
                    height: 350px;
                }
            }

            
            @media (max-width: 991px) {
                margin-top: 60px;

                .movie_box {
                    padding: 17px;
                }

                .movie_box .box_inner .movie_poster {
                    height: 300px;
                }

                .movie_box .box_inner .movie_info h3 {
                    font-size: 17px;
                }

                .movie_box .box_inner .movie_info p {
                    font-size: 14px;
                }

                .movie_box .box_inner .movie_info a {
                    height: 50px;
                    font-size: 16px;
                }

                .movie_box .box_inner .movie_info a i {
                    font-size: 16px;
                }
            }

            
            @media (max-width: 768px) {
                .movie_box {
                    padding: 15px;
                }

                .movie_box .box_inner .movie_poster {
                    height: 288px;
                }

                .movie_box .box_inner .movie_info h3 {
                    font-size: 17px;
                }

                .movie_box .box_inner .movie_info p {
                    display: none;
                }

                .movie_box .box_inner .movie_info a {
                    margin-top: 10px;
                    height: 50px;
                    font-size: 16px;
                }

                .movie_box .box_inner .movie_info a i {
                    font-size: 16px;
                }
            }

            @media (max-width: 575px) {
                .movie_box .box_inner .movie_poster {
                    height: 225px;
                }
                .movie_box .box_inner .movie_poster span {
                    padding: 15px;
                    font-size: 14px;
                }
            }

            @media (max-width: 475px) {
                .movie_box {
                    padding: 8px;

                    .box_inner .movie_poster {
                        height: 250px;
                    }

                    &:hover {
                        box-shadow: none;
                    }

                    .box_inner .movie_info {
                        margin-top: 10px;
                    }

                    .box_inner .movie_info h3 {
                        font-size: 15px;
                    }
                }
            }

            @media (max-width: 399px) {
                .movie_box {
                    .box_inner .movie_poster {
                        height: 220px;
                    }
                    .box_inner .movie_info a {
                        height: 45px;
                        font-size: 15px;

                        i {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1250px) {
        padding: 70px ;
        padding-top: 150px;
    }

    @media (max-width: 1150px) {
        padding-top: 120px;
    }

    @media (max-width: 991px) {
        padding: 50px ;
        padding-top: 120px;
    }

    @media (max-width: 575px) {
        padding: 35px ;
        padding-top: 100px;
    }

    @media (max-width: 475px) {
        padding: 25px 10px;
        padding-top: 80px;
    }
`;