import { CityModalWrapper } from "../Styles/Modal-Style";
import states from '../Data/State-City.json';
import { useEffect, useRef, useState } from "react";



function ModalCity({cityModalShow, setCityModalShow, selectedCity, setSelectedCity}) {

    const [cities, setCities] = useState([]);
    const [visibleCities, setVisibleCities] = useState([]);
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [cityDropdown, setCityDropdown] = useState(false);
    const [searchFilter, setSearchFilter] = useState(false);
    const observerRef = useRef();
    const modalBoxRef = useRef();

    const citiesPerPage = 20;

    useEffect(() => {
        // Fetch cities from JSON data
        const fetchedCities = states.flatMap(state => state.cities);
        setCities(fetchedCities);
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
            setPage(prevPage => prevPage + 1);
            }
        }, { threshold: 1 });

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            if (observerRef.current) {
            observer.unobserve(observerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        setVisibleCities(cities.slice(0, page * citiesPerPage));
        }, [page, cities]);

        useEffect(() => {
        const filteredCities = cities.filter(city =>
            city.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setVisibleCities(filteredCities.slice(0, page * citiesPerPage));
    }, [searchTerm, cities]);

    const handleCityDropdown = () => {
        setCityDropdown(!cityDropdown);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalBoxRef.current && !modalBoxRef.current.contains(event.target)) {
                setCityModalShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setCityModalShow]);


    function handleCityClick(cityName) {
        setSelectedCity(cityName);
        setSearchTerm(cityName);
        setCityDropdown(false);
        localStorage.setItem("location", cityName);
    }


    return(
        <>
            <CityModalWrapper className={cityModalShow ? 'active' : ''}>
                <div ref={modalBoxRef} className={`modal_box ${cityModalShow ? 'active' : ''}`}>
                    <div className="box_inner">
                        <h5>Select your location</h5>
                        <div className="select_box_sec">
                            <div className="select_input_btn">
                                <i className="fa-solid fa-location-dot"></i>
                                <input type="text" placeholder="Search your city" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} onClick={handleCityDropdown} required />
                            </div>
                            <div className={`city_dropdown ${cityDropdown ? 'active' : ''}`}>
                                <div className="dropdown_inner">
                                    <ul>
                                        {
                                            visibleCities  && visibleCities .map((city, index) => 
                                                <li key={index}
                                                    onClick={() => handleCityClick(city.name)}
                                                >{city.name}</li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CityModalWrapper>
        </>
    );
}


export default ModalCity;