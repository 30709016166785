import { styled } from "@mui/material";


export const PageWrapper = styled('div')`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;


    .exclusive_movies {
        position: relative;
        width: 100%;
        padding: 100px ;
        padding-top: 0;

        &.middle_sec {
            background: #FFFFE2;
            margin-bottom: 50px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 200px;
                z-index: 1;
                background: #FFF;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 200px;
                z-index: 1;
                background: #FFF;
            }
        }

        .sec_content {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 100px;
            overflow: hidden;
            z-index: 5;

            .sec_head {
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                h2 {
                    position: relative;
                    display: flex;
                    align-items: center;

                    span:nth-of-type(1) {
                        position: relative;
                        font-size: 55px;
                        font-weight: 700;
                        font-family: arista-pro-trial-bold;
                        color: #000;
                        z-index: 1;
                    }

                    span:nth-of-type(2) {
                        position: relative;
                        margin-left: 20px;
                        font-size: 55px;
                        font-family: arista-pro-alt-bold;
                        color: #FC6736;
                    }

                    svg {
                        position: absolute;
                        left: -50px;
                        width: 320px;
                        overflow: inherit;
                    }

                    @media (max-width: 1399px) {
                        span:nth-of-type(1) {
                            font-size: 45px;
                        }

                        span:nth-of-type(2) {
                            font-size: 45px;
                        }

                        svg {
                            left: -50px;
                            width: 250px;
                        }
                    }

                    @media (max-width: 575px) {
                        span:nth-of-type(1) {
                            font-size: 35px;
                        }

                        span:nth-of-type(2) {
                            font-size: 35px;
                        }

                        svg {
                            left: -50px;
                            width: 200px;
                        }
                    }

                    @media (max-width: 450px) {
                        span:nth-of-type(1) {
                            font-size: 30px;
                        }

                        span:nth-of-type(2) {
                            font-size: 30px;
                        }

                        svg {
                            left: -20px;
                            width: 180px;
                        }
                    }
                }
            }

            .sec_items {
                position: relative;
                margin-top: 100px;
                width: 100%;
                z-index: 5;
                display: flex;
                flex-wrap: wrap;

                .no_data_message {
                    position: relative;
                    width: 100%;
                    padding: 100px 0;
                    padding-top: 30px;
                    display: flex;
                    justify-content: center;
                    text-align: center;

                    h3 {
                        position: relative;
                        font-family: 'Lemonada', cursive;
                        font-size: 25px;
                        color: #AAA;

                        @media (max-width: 575px) {
                            font-size: 22px;
                        }
                    }
                }

                .swiper {
                    position: relative;
                    width: 100%;
                    padding-bottom: 60px;
                    overflow: visible;
                    transition: all 0.5s;

                    &:hover .swiper-slide {
                        transition: all 0.5s;
                    }

                    .swiper-slide {
                        width: 400px;
                        z-index: 10;
                        transition: all 0.5s;

                        .movie_box {
                            position: relative;
                            width: 100%;
                            transition: all 0.5s;
                            cursor: pointer;

                            .box_inner {
                                position: relative;
                                width: 100%;
                                display: flex;
                                flex-direction: column;

                                .poster_box {
                                    position: relative;
                                    width: 100%;
                                    height: 100%;
                                    padding: 0px 30px;
                                    border-right: 1px solid #E1E0EA;
                                    border-left: 1px solid #E1E0EA;

                                    .poster_inner {
                                        position: relative;
                                        width: 100%;
                                        height: 425px;
                                        border-radius: 6px;
                                        overflow: hidden;
                                    }

                                    .img_box {
                                        position: relative;
                                        width: 100%;
                                        height: 100%;
                                        display: flex;

                                        img {
                                            position: relative;
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                        }
                                    }
                                }

                                .movie_info {
                                    position: relative;
                                    width: 100%;
                                    display: flex;
                                    flex-direction: column;
                                    padding: 0px 30px;
                                    padding-top: 15px;

                                    h4 {
                                        position: relative;
                                        width: 100%;
                                        font-size: 20px;
                                        font-weight: 400;
                                        font-family: "Russo One", sans-serif;
                                        color: #000;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }
                            }
                        }

                        &:hover {
                            opacity: 1;
                            z-index: 50;
                            transition: all 0.5s;
                        }

                        &:hover .movie_box .box_inner .poster_box {
                            transform: scale(1.1);
                            border: none;
                            z-index: 50;
                            transition: all 0.5s;
                        }
                    }

                    .swiper-button-next {
                        right: 10px;
                        top: -80px;
                        width: 80px;
                        height: 50px;
                        background: #FFF;
                        border-radius: 50px;
                        background: #FC6736;
                        border: 2px solid #FC6736;
                        z-index: -1;

                        &::after {
                            content: url(images/arrow-right.svg);
                            position: absolute;
                            top: -4px;
                            left: 50%;
                            transform: translateX(-50%);
                        }

                        @media (max-width: 1399px) {
                            width: 60px;
                            height: 40px;

                            &::after {
                                content: url(images/arrow-right.svg);
                                font-size: 10px;
                                position: absolute;
                                top: 8px;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                    }

                    .swiper-button-prev  {
                        left: auto;
                        right: 120px;
                        top: -80px;
                        width: 80px;
                        height: 50px;
                        background: #FFF;
                        border-radius: 50px;
                        border: 2px solid #FC6736;
                        z-index: -1;

                        &::after {
                            content: url(images/arrow-left.svg);
                            position: absolute;
                            top: -4px;
                            left: 50%;
                            transform: translateX(-50%);
                        }

                        @media (max-width: 1399px) {
                            right: 90px;
                            width: 60px;
                            height: 40px;

                            &::after {
                                content: url(images/arrow-left.svg);
                                font-size: 10px;
                                position: absolute;
                                top: 8px;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                    }

                    .swiper-pagination {
                        span.swiper-pagination-bullet {
                            width: 10px;
                            height: 10px;
                            background: #dadada;
                            opacity: 1;
                        }
                        span.swiper-pagination-bullet-active {
                            width: 35px;
                            height: 10px;
                            border-radius: 20px;
                            background: #FC6736;
                            opacity: 1;
                        }
                    }

                    @media (max-width: 1399px) {
                        .swiper-slide {
                            width: 300px;

                            .movie_box .box_inner .poster_box .poster_inner {
                                height: 330px;
                            }

                            .movie_box .box_inner .movie_info h4 {
                                font-size: 17px;
                            }
                        }
                    }

                    @media (max-width: 991px) {
                        .swiper-slide .movie_box .box_inner {
                            .poster_box {
                                padding: 0px 15px;
                            }
                            .movie_info {
                                padding: 0px 15px;
                                padding-top: 15px;
                            }
                        }
                    }

                    @media (max-width: 768px) {
                        .swiper-slide {
                            width: 225px;

                            .movie_box .box_inner .poster_box .poster_inner {
                                height: 270px;
                            }
                        }

                        .swiper-button-next,
                        .swiper-button-prev  {
                            display: none;
                        }
                    }

                    @media (max-width: 575px) {
                        .swiper-slide {
                            width: 50%;

                            .movie_box .box_inner .poster_box .poster_inner {
                                height: 250px;
                            }

                            .movie_box .box_inner .movie_info h4 {
                                font-size: 16px;
                            }
                        }

                        .swiper-pagination {
                            span.swiper-pagination-bullet {
                                width: 8px;
                                height: 8px;
                            }
                            span.swiper-pagination-bullet-active {
                                width: 25px;
                                height: 8px;
                            }
                        }
                    }

                    @media (max-width: 450px) {
                        .swiper-slide {

                            .movie_box .box_inner {
                                .poster_box {
                                    padding: 0px 10px;

                                    .poster_inner {
                                        height: 200px;
                                    }
                                }
                                .movie_info {
                                    padding: 0px 10px;
                                    padding-top: 12px;

                                    h4 {
                                        font-size: 15px;
                                    }
                                }
                            }
                        }
                    }

                    @media (max-width: 399px) {
                        .swiper-slide {

                            .movie_box .box_inner {
                                .poster_box .poster_inner {
                                    height: 185px;
                                }
                                .movie_info h4 {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .sec_wave_top {
            position: absolute;
            top: -180px;
            left: 0;
            width: 100%;
            z-index: 2;
            overflow: hidden;

            svg {
                position: relative;
                width: 100%;
            }

            @media (max-width: 1920px) {
                height: 450px;
                width: 100%;
                top: -24%;

                svg {
                    height: 100%;
                    width: auto;
                }
            }

            @media (max-width: 768px) {
                top: -21%;
            }
        }

        .sec_wave_bottom {
            position: absolute;
            bottom: -220px;
            left: 0;
            width: 100%;
            z-index: 2;
            overflow: hidden;

            svg {
                position: relative;
                width: 100%;
            }

            @media (max-width: 1920px) {
                bottom: -25%;
                height: 450px;
                width: 100%;

                svg {
                    height: 100%;
                    width: auto;
                }
            }

            @media (max-width: 768px) {
                bottom: -28%;
                height: 400px;
            }
        }

        @media (max-width: 1250px) {
            padding: 70px;
            padding-top: 0;
        }

        @media (max-width: 991px) {
            padding: 50px;
            padding-top: 0;
        }

        @media (max-width: 768px) {
            .sec_content .sec_items {
                margin-top: 70px;
            }
        }

        @media (max-width: 575px) {
            padding: 35px;
            padding-top: 0;

            .sec_wave_bottom {
                bottom: -42%;
                height: 460px;
            }
        }

        @media (max-width: 450px) {
            padding: 25px 20px;
            padding-top: 0;
        }
    }
`;

export const BannerWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 800px;
    overflow: hidden;

    .video_backgroung_slider {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;

        .swiper {
            position: relative;
            width: 100%;
            height: 100%;

            .swiper-slide {
                position: relative;
                width: 100%;
                height: 100%;
                display: block;

                video {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .video_overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.87);
        z-index: 1;
    }

    .banner_content {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 5;
        display: flex;
        flex-direction: column;

        .banner_items {
            position: relative;
            width: 100%;
            height: calc(100% - 200px);
            padding: 0px 100px;
            padding-top: 150px;

            .banner_slider {
                position: relative;
                width: 100%;
                height: 100%;

                .swiper  {
                    position: relative;
                    width: 100%;
                    height: 100%;

                    .swiper-slide {
                        position: relative;
                        width: 100% !important;
                        height: 100%;
                        padding: 0px 120px;

                        .banner_item_box {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;

                            .box_left {
                                position: relative;
                                width: 270px;
                                height: 400px;

                                .movie_box {
                                    position: relative;
                                    width: 100%;
                                    height: 100%;
                                    display: flex;

                                    img {
                                        position: relative;
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        border-radius: 8px;
                                        box-shadow: 7px 10px 15px rgba(255,255,255,0.3),
                                                    -4px -4px 10px rgba(255,255,255,0.3);
                                    }
                                }
                            }

                            .box_right {
                                position: relative;
                                width: calc(100% - 270px);
                                padding-left: 90px;

                                .box_inner {
                                    position: relative;
                                    width: 100%;
                                    display: flex;
                                    flex-direction: column;

                                    h2 {
                                        position: relative;
                                        margin-bottom: 15px;
                                        font-size: 40px;
                                        color: #FFF;
                                        font-family: "Russo One", sans-serif;
                                        letter-spacing: 0.3px;
                                    }

                                    li {
                                        position: relative;
                                        margin-top: 20px;
                                        color: #FFF;
                                        display: flex;
                                        align-items: center;
                                        font-family: 'Poppins', sans-serif;

                                        span {
                                            font-size: 17px;
                                            font-weight: 500;
                                        }

                                        i {
                                            position: relative;
                                            font-size: 6px;
                                            margin: 0px 10px;
                                        }


                                        p {
                                            position: relative;
                                            display: flex;
                                            align-items: center;
                                            flex-wrap: wrap;
                                            max-width: 600px;

                                            a {
                                                position: relative;
                                                margin-right: 20px;
                                                margin-bottom: 20px;
                                                padding: 10px 30px;
                                                background: #E1E0EA;
                                                font-size: 16px;
                                                line-height: 1;
                                                color: #111;
                                                border-radius: 4px;
                                            }
                                        }

                                        @media (max-width: 1570px) {
                                            &.date_types {
                                                flex-direction: column;
                                                align-items: flex-start;

                                                i {
                                                    display: none;
                                                }

                                                span:first-of-type {
                                                    margin-bottom: 10px;
                                                }
                                            }
                                        }

                                        @media (max-width: 768px) {
                                            &.date_types {
                                                align-items: center;
                                            }
                                        }
                                    }

                                    ul {
                                        position: relative;
                                        margin-top: 15px;
                                        display: flex;
                                        align-items: center;

                                        a {
                                            position: relative;
                                            width: 200px;
                                            height: 55px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            font-family: 'Ubuntu', sans-serif;
                                            font-size: 17px;
                                            font-weight: 600;
                                            cursor: pointer;
                                            border-radius: 5px;
                                            overflow: hidden;
                                            background: none;
                                            outline: none;
                                            text-decoration: none;


                                            &::before {
                                                content: '';
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                width: 100%;
                                                height: 100%;
                                                transform: scaleX(1);
                                                transform-origin: left;
                                                transition: transform 0.5s;
                                            }

                                            &:hover:before {
                                                transform: scaleX(0);
                                                transform-origin: right;
                                                transition: transform 0.5s;
                                            }


                                            span {
                                                position: relative;
                                                z-index: 5;
                                                color: #FFF;
                                                transition: all 0.5s;

                                                i {
                                                    font-size: 18px;
                                                    margin-right: 10px;
                                                }
                                            }

                                            &:first-of-type {
                                                border: 2px solid #FC6736;
                                                color: #FC6736;

                                                &::before {
                                                    background: #FC6736;
                                                }

                                                &:hover span {
                                                    color: #FC6736;
                                                    transition: all 0.5s;
                                                }
                                            }

                                            &:last-of-type {
                                                border: 2px solid #3F00FF;
                                                color: #3F00FF;

                                                i {
                                                    transform: rotate(-45deg);
                                                }

                                                &::before {
                                                    background: #3F00FF;
                                                }

                                                &:hover span {
                                                    color: #3F00FF;
                                                    transition: all 0.5s;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }                    
                    }

                    .swiper-button-prev {
                        left: 3%;
                        width: 55px;
                        height: 55px;
                        background: #FFF;
                        border-radius: 50%;
                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.5s;

                        &::after {
                            font-size: 20px;
                            font-weight: 900;
                            color: #FC6736;
                        }
                    }

                    .swiper-button-next {
                        right: 3%;
                        width: 55px;
                        height: 55px;
                        background: #FFF;
                        border-radius: 50%;
                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.5s;

                        &::after {
                            font-size: 20px;
                            font-weight: 900;
                            color: #FC6736;
                        }
                    }

                    &:hover {
                        .swiper-button-prev {
                            left: 0%;
                            opacity: 1;
                            visibility: visible;
                            transition: all 0.5s;
                        }
                        .swiper-button-next {
                            right: 0%;
                            opacity: 1;
                            visibility: visible;
                            transition: all 0.5s;
                        }
                    }
                }
            }

            @media (max-width: 1440px) {
                .banner_slider .swiper .swiper-slide .banner_item_box .box_left {
                    width: 250px;
                    height: 350px;
                }
                .banner_slider .swiper .swiper-slide .banner_item_box .box_right {
                    width: calc(100% - 250px);
                }
                .banner_slider .swiper .swiper-slide .banner_item_box .box_right .box_inner h2 {
                    font-size: 30px;
                    margin-bottom: 5px;
                }
            } 

            @media (max-width: 1250px) {
                height: calc(100% - 170px);
                padding: 0px 80px;
                padding-top: 150px;

                .banner_slider .swiper .swiper-slide {
                    padding: 0px 0px;
                }

                .banner_slider .swiper .swiper-slide .banner_item_box .box_right {
                    padding-left: 70px;
                }
                
                .banner_slider .swiper .swiper-slide .banner_item_box .box_right .box_inner li p a {
                    font-size: 14px;
                }

                .banner_slider .swiper .swiper-slide .banner_item_box .box_right .box_inner ul a {
                    width: 180px;
                    height: 50px;
                    font-size: 15px;

                    span {
                        i {
                            font-size: 15px;
                            margin-right: 6px;
                        }
                    }
                }

                .swiper-button-prev,
                .swiper-button-next {
                    display: none;
                }
            } 

            @media (max-width: 991px) {
                height: calc(100% - 130px);
                padding-top: 120px;

                .banner_slider .swiper .swiper-slide .banner_item_box .box_left {
                    width: 210px;
                    height: 300px;
                }
                .banner_slider .swiper .swiper-slide .banner_item_box .box_right {
                    width: calc(100% - 210px);
                    padding-left: 50px;
                }
                .banner_slider .swiper .swiper-slide .banner_item_box .box_right .box_inner h2 {
                    font-size: 25px;
                }
                .banner_slider .swiper .swiper-slide .banner_item_box .box_right .box_inner li {
                    margin-top: 15px;
                }
            }

            @media (max-width: 768px) {
                padding: 0px 50px;
                padding-top: 150px;

                .banner_slider .swiper .swiper-slide .banner_item_box {
                    flex-direction: column;
                }

                .banner_slider .swiper .swiper-slide .banner_item_box .box_left {
                    width: 180px;
                    height: 250px;
                }

                .banner_slider .swiper .swiper-slide .banner_item_box .box_right {
                    width: 100%;
                    padding-left: 0px;
                    padding-top: 35px;
                }

                .banner_slider .swiper .swiper-slide .banner_item_box .box_right .box_inner{
                    align-items: center;
                    text-align: center;
                }

                .banner_slider .swiper .swiper-slide .banner_item_box .box_right .box_inner h2 {
                    font-size: 22px;
                }

                .banner_slider .swiper .swiper-slide .banner_item_box .box_right .box_inner li p {
                    align-items: center;
                    justify-content: center;
                }
                .banner_slider .swiper .swiper-slide .banner_item_box .box_right .box_inner li p a {
                    font-size: 13px;
                    margin-right: 15px;
                    margin-bottom: 15px;
                    line-height: 1;
                    padding: 8px 20px;
                }
                .banner_slider .swiper .swiper-slide .banner_item_box .box_right .box_inner li span {
                    font-size: 14px;
                }

                .banner_slider .swiper .swiper-slide .banner_item_box .box_right .box_inner ul a {
                    width: 170px;
                    height: 45px;
                    font-size: 14px;

                    span {
                        i {
                            font-size: 14px;
                            margin-right: 6px;
                        }
                    }
                }
            }

            @media (max-width: 575px) {
                padding: 0px 35px;
                padding-top: 100px;
            }

            @media (max-width: 450px) {
                padding: 0px 25px;
                padding-top: 100px;
            }
        }

        .banner_shape {
            position: absolute;
            bottom: -100px;
            left: 0;
            width: 100%;
            display: block;

            svg {
                position: relative;
                width: 100%;
                height: auto;
                display: block;
            }

            @media (max-width: 1570px) {
                bottom: -65px;
            }

            @media (max-width: 991px) {
                bottom: -20px;
            }
        }
    }

    @media (max-width: 1250px) {
        height: 700px;
    }

    @media (max-width: 991px) {
        height: 650px;
    }

    @media (max-width: 768px) {
        height: max-content;

        .banner_content {
            padding-bottom: 125px;
        }
    }
    
`;