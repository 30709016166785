import { useEffect, useState } from "react";
import { ReviewWrapper } from "../Styles/Modal-Style";
import axios from "axios";
import { Review_Submit_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';



function ModalReview({reviewModalShow, setReviewModalShow, selectedRating, setRateBox}) {

    const [userName, setUserName] = useState('');
    const [movieName, setMovieName] = useState('');
    const [comment, setComment] = useState('');

    useEffect(() => {

        if(localStorage.getItem("UserData")){
            let user = JSON.parse(localStorage.getItem("UserData"));
            setUserName(user.user);
        }

        if(localStorage.getItem("Movie_name")){
            setMovieName(localStorage.getItem("Movie_name"));
        }

    }, []);

    const commentInput = (e) => {
        setComment(e.target.value);
    };

    const handleClose = () => {
        setReviewModalShow(false);
    };

    const handleReviewSubmit = () => {

        const inputs = {
            user: userName,
            movie: movieName,
            rating: selectedRating,
            comment: comment,
        }

        axios.post(Review_Submit_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            if(res.data.status == "200"){
                toast.success('Review Submitted !!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setComment('');
                setRateBox(false);
                setReviewModalShow(false);
            } 
        })
        .catch(err => {
            toast.error('Internal Server Error !!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            setComment('');
            setRateBox(true);
            setReviewModalShow(true);
        })
        
    };

    return(
        <>
            <ReviewWrapper className={reviewModalShow ? 'active' : ''}>
                <div className={`review_box ${reviewModalShow ? 'active' : ''}`}>
                    <div className="box_inner">
                        <div className="rating_message">
                            <p>You have rated <span>{selectedRating} out of 5</span>.</p>
                        </div>
                        <div className="comment_box">
                            <textarea value={comment} onChange={commentInput} required></textarea>
                            <span>Your Comment</span>
                        </div>
                        <div className="button_sec">
                            <a onClick={handleClose}><span>Close</span></a>
                            <a onClick={handleReviewSubmit}><span>Submit</span></a>
                        </div>
                    </div>
                </div>
            </ReviewWrapper>
        </>
    );
}


export default ModalReview;