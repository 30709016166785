import { TicketListWrapper } from "../Styles/Booking-Style";




function CancelledBookings() {
    return(
        <>
            <TicketListWrapper>
                <div className="ticket_box">
                    <div className="box_inner">
                        <div className="ticket cancelled">
                            <div className="left_part">
                                <div class="cuts">
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                </div>

                                <div class="content">
                                    <div class="content_inner">
                                        <h4>Kisi Ka Bhai Kisi Ka Jaan</h4>
                                        <div className="date_time">
                                            <p>10 Nov, 2024</p>
                                            <span><i className="fa-solid fa-circle"></i></span>
                                            <p>02:30 PM</p>
                                        </div>
                                        <div className="section">
                                            <span>Section :</span>
                                            <p>Dress Circle</p>
                                        </div>
                                        <div className="seats">
                                            <span>Seats :</span>
                                            <p>A-1, A-2, A-3, B-5, B-6, B-7</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right_part">
                                <div class="cuts">
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                </div>
                                <div class="number"><p>TKB123456</p></div>
                            </div>

                            <div class="circles">
                                <div class="circle"></div>
                                <div class="circle"></div>
                            </div>
                        </div>
                        <div className="cancel_tag">
                            <img src="../images/cancel-tag.png" alt="cancel" />
                        </div>
                    </div>
                </div>
                <div className="ticket_box">
                    <div className="box_inner">
                        <div className="ticket cancelled">
                            <div className="left_part">
                                <div class="cuts">
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                </div>

                                <div class="content">
                                    <div class="content_inner">
                                        <h4>Kisi Ka Bhai Kisi Ka Jaan</h4>
                                        <div className="date_time">
                                            <p>10 Nov, 2024</p>
                                            <span><i className="fa-solid fa-circle"></i></span>
                                            <p>02:30 PM</p>
                                        </div>
                                        <div className="section">
                                            <span>Section :</span>
                                            <p>Dress Circle</p>
                                        </div>
                                        <div className="seats">
                                            <span>Seats :</span>
                                            <p>A-1, A-2, A-3, B-5, B-6, B-7</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right_part">
                                <div class="cuts">
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                </div>
                                <div class="number"><p>TKB123456</p></div>
                            </div>

                            <div class="circles">
                                <div class="circle"></div>
                                <div class="circle"></div>
                            </div>
                        </div>
                        <div className="cancel_tag">
                            <img src="../images/cancel-tag.png" alt="cancel" />
                        </div>
                    </div>
                </div>
                <div className="ticket_box">
                    <div className="box_inner">
                        <div className="ticket cancelled">
                            <div className="left_part">
                                <div class="cuts">
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                </div>

                                <div class="content">
                                    <div class="content_inner">
                                        <h4>Kisi Ka Bhai Kisi Ka Jaan</h4>
                                        <div className="date_time">
                                            <p>10 Nov, 2024</p>
                                            <span><i className="fa-solid fa-circle"></i></span>
                                            <p>02:30 PM</p>
                                        </div>
                                        <div className="section">
                                            <span>Section :</span>
                                            <p>Dress Circle</p>
                                        </div>
                                        <div className="seats">
                                            <span>Seats :</span>
                                            <p>A-1, A-2, A-3, B-5, B-6, B-7</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right_part">
                                <div class="cuts">
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                </div>
                                <div class="number"><p>TKB123456</p></div>
                            </div>

                            <div class="circles">
                                <div class="circle"></div>
                                <div class="circle"></div>
                            </div>
                        </div>
                        <div className="cancel_tag">
                            <img src="../images/cancel-tag.png" alt="cancel" />
                        </div>
                    </div>
                </div>
                <div className="ticket_box">
                    <div className="box_inner">
                        <div className="ticket cancelled">
                            <div className="left_part">
                                <div class="cuts">
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                </div>

                                <div class="content">
                                    <div class="content_inner">
                                        <h4>Kisi Ka Bhai Kisi Ka Jaan</h4>
                                        <div className="date_time">
                                            <p>10 Nov, 2024</p>
                                            <span><i className="fa-solid fa-circle"></i></span>
                                            <p>02:30 PM</p>
                                        </div>
                                        <div className="section">
                                            <span>Section :</span>
                                            <p>Dress Circle</p>
                                        </div>
                                        <div className="seats">
                                            <span>Seats :</span>
                                            <p>A-1, A-2, A-3, B-5, B-6, B-7</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right_part">
                                <div class="cuts">
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                    <div class="cut"></div>
                                </div>
                                <div class="number"><p>TKB123456</p></div>
                            </div>

                            <div class="circles">
                                <div class="circle"></div>
                                <div class="circle"></div>
                            </div>
                        </div>
                        <div className="cancel_tag">
                            <img src="../images/cancel-tag.png" alt="cancel" />
                        </div>
                    </div>
                </div>
            </TicketListWrapper>
        </>
    );
}


export default CancelledBookings;