import { TrailerWrapper } from "../Styles/Modal-Style";




function ModalTrailer({trailerShow, setTrailerShow, trailer}) {

    const handleCloseTrailer = () => {
        setTrailerShow(false);
    };

    return(
        <>
            <TrailerWrapper className={trailerShow ? 'active' : ''}>
                <div className={`trailer_box ${trailerShow ? 'active' : ''}`}>
                    <div className="btn_sec">
                        <a onClick={handleCloseTrailer}><i className="fa-solid fa-xmark"></i></a>
                    </div>
                    <div className="video_sec" dangerouslySetInnerHTML={{ __html: trailer }}></div>
                </div>
            </TrailerWrapper>
        </>
    );
}


export default ModalTrailer;