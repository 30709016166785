import { styled } from "@mui/material";


export const SigninWrapper = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    z-index: 999;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }

    .signin_box {
        position: relative;
        width: 950px;
        min-height: 250px;
        background: #FFF;
        border-radius: 10px;
        box-shadow: 8px 10px 20px rgba(255, 199, 108, 0.9),
                    -6px -7px 12px rgba(255, 255, 108, 0.8);
        padding: 40px 50px;
        display : flex;
        transform: translateY(-100px);
        transition: all 0.7s;  

        &.active {
            transform: translateY(0px);
            transition: all 0.7s;
        }

        .close {
            position: absolute;
            top: 25px;
            right: 30px;
            z-index: 15;

            a {
                position: relative;
                font-size: 20px;
                cursor: pointer;
                color: #333;
            }
        }

        .box_left {
            position: relative;
            width: 50%;

            form {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                h3 {
                    font-family: 'Lemonada', cursive;
                    font-size: 23px;
                    color: #333;
                }

                .form_sec {
                    position: relative;
                    margin-top: 30px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .input_box {
                        position: relative;
                        width: 100%;
                        margin-bottom: 20px;

                        input {
                            position: relative;
                            width: 100%;
                            height: 55px;
                            outline: none;
                            border: 2px solid #E1E0EA;
                            padding: 5px 25px;
                            border-radius: 6px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            color: #444;

                            &:focus,
                            &:valid {
                                border: 2px solid #FC6736;
                            }
                        }

                        span {
                            position: absolute;
                            top: 50%;
                            left: 25px;
                            display: flex;
                            align-items: center;
                            line-height: 1;
                            font-family: 'Poppins', sans-serif;
                            transform: translateY(-50%);
                            font-size: 16px;
                            color: #444;
                            pointer-events: none;
                            transition: all 0.5s;
                        }

                        input:focus ~ span,
                        input:valid ~ span {
                            top: 0%;
                            left: 15px;
                            font-size: 13px;
                            padding: 0px 8px;
                            background: #FFF;
                            color: #02C0FF;
                            font-weight: 500;
                            transition: 0.5s;
                        }

                        a {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 60px;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            font-size: 17px;
                            color: #555;
                        }
                    }

                    .forgot_remember {
                        position: relative;
                        margin-top: 10px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        label {
                            position: relative;
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            input[type="checkbox"] {
                                display: none;
                            }

                            span {
                                position: relative;
                                width: 20px;
                                height: 20px;
                                border: 1px solid #d4d4d4;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 4px;
                                cursor: pointer;
                                transition: all 0.4s;

                                i {
                                    position: relative;
                                    font-size: 13px;
                                    color: #FFF;
                                }
                            }

                            input[type="checkbox"]:checked ~ span {
                                border: 1px solid #FC6736;
                                background: #FC6736;
                                transition: all 0.4s;
                            }

                            p {
                                position: relative;
                                margin-left: 8px;
                                font-family: 'Poppins', sans-serif;
                                font-size: 16px;
                                color: #555;
                            }
                        }

                        a {
                            position: relative;
                            font-family: 'Poppins', sans-serif;
                            text-decoration: none;
                            font-size: 15px;
                        }
                    }

                    .form_btn {
                        position: relative;
                        margin-top: 25px;
                        width: 100%;

                        button {
                            position: relative;
                            width: 100%;
                            height: 55px;
                            border: 2px solid #3F00FF;
                            outline: none;
                            background: #3F00FF;
                            font-family: 'Ubuntu', sans-serif;
                            font-size: 18px;
                            text-transform: uppercase;
                            font-weight: 500;
                            letter-spacing: 0.55px;
                            color: #FFF;
                            border-radius: 6px;
                            cursor: pointer;
                            transition: all 0.5s;

                            &:hover {
                                background: #FFF;
                                color: #3F00FF;
                                transition: all 0.5s;
                            }
                        }
                    }

                    .redirect_box {
                        position: relative;
                        width: 100%;
                        margin-top: 15px;

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-size: 15px;
                            color: #666;

                            a {
                                text-decoration: none;
                                font-weight: 500;
                                font-size: 16px;
                                color: #FC6736;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

        .box_right {
            position: relative;
            width: 50%;
            padding-left: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                position: relative;
                width: 100%;
            }
        }

        @media (max-width: 1099px) {
            width: 600px;
            flex-direction: column;

            .box_left {
                width: 100%;
            }

            .box_right {
                width: 100%;
                padding-left: 0px;
                margin-top: 50px;

                img {
                    position: relative;
                    width: 300px;
                }
            }
        }

        @media (max-width: 768px) {
            width: 100%;
        }

        @media (max-width: 575px) {
            padding: 35px;

            .box_left form h3 {
                font-size: 20px;
            }

            .box_left form .form_sec .input_box input {
                height: 50px;
                font-size: 14px;
                padding: 5px 20px;
            }

            .box_left form .form_sec .input_box span {
                font-size: 14px;
                left: 20px;
            }

            .box_left form .form_sec .input_box a {
                font-size: 15px;
            }

            .box_left form .form_sec .forgot_remember label span {
                width: 16px;
                height: 16px;
            }
            .box_left form .form_sec .forgot_remember label span i {
                font-size: 11px;
            }
            .box_left form .form_sec .forgot_remember label p {
                font-size: 14px;
            }
            .box_left form .form_sec .forgot_remember a {
                font-size: 14px;
            }

            .box_left form .form_sec .form_btn button {
                height: 50px;
                font-size: 16px;
            }

            .box_left form .form_sec .redirect_box p {
                font-size: 13px;

                a {
                    font-size: 14px;
                }
            }
        }
        
        @media (max-width: 450px) {
            padding: 30px 25px;

            .box_right {

                img {
                    width: 250px;
                }
            }
        }
    }

    @media (max-width: 1099px) {
        padding: 50px;
        align-items: flex-start;
        overflow-y: auto;
    }

    @media (max-width: 575px) {
        padding: 50px 35px;
    }

    @media (max-width: 450px) {
        padding: 50px 25px;
    }
`;

export const SignupWrapper = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    z-index: 999;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }


    .signup_box {
        position: relative;
        width: 1050px;
        min-height: 250px;
        background: #FFF;
        border-radius: 10px;
        box-shadow: 8px 10px 20px rgba(255, 199, 108, 0.9),
                    -6px -7px 12px rgba(255, 255, 108, 0.8);
        padding: 40px 50px;
        display : flex;
        transform: translateX(-100px);
        transition: all 0.7s;  

        &.active {
            transform: translateX(0px);
            transition: all 0.7s;
        }

        .close {
            position: absolute;
            top: 25px;
            right: 30px;
            z-index: 15;

            a {
                position: relative;
                font-size: 20px;
                cursor: pointer;
                color: #333;
            }
        }
        
        .box_left {
            position: relative;
            width: 50%;
            padding-right: 60px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                position: relative;
                width: 100%;
            }
        }

        .box_right {
            position: relative;
            width: 50%;

            form {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                h3 {
                    font-family: 'Lemonada', cursive;
                    font-size: 23px;
                    color: #333;
                }

                .form_sec {
                    position: relative;
                    margin-top: 30px;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .input_box {
                        position: relative;
                        width: 100%;
                        margin-bottom: 20px;

                        input {
                            position: relative;
                            width: 100%;
                            height: 55px;
                            outline: none;
                            border: 2px solid #E1E0EA;
                            padding: 5px 25px;
                            border-radius: 6px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            color: #444;

                            &:focus,
                            &:valid {
                                border: 2px solid #FC6736;
                            }
                        }

                        span {
                            position: absolute;
                            top: 50%;
                            left: 25px;
                            display: flex;
                            align-items: center;
                            line-height: 1;
                            font-family: 'Poppins', sans-serif;
                            transform: translateY(-50%);
                            font-size: 16px;
                            color: #444;
                            pointer-events: none;
                            transition: all 0.5s;
                        }

                        input:focus ~ span,
                        input:valid ~ span {
                            top: 0%;
                            left: 15px;
                            font-size: 13px;
                            padding: 0px 8px;
                            background: #FFF;
                            color: #02C0FF;
                            font-weight: 500;
                            transition: 0.5s;
                        }

                        a {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 60px;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            font-size: 17px;
                            color: #555;
                        }
                    }

                    .accept_box {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;

                        input[type="checkbox"] {
                            display: none;
                        }

                        label {
                            position: relative;
                            margin-top: 2px;
                            width: 20px;
                            height: 20px;
                            border: 1px solid #d4d4d4;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 4px;
                            cursor: pointer;
                            transition: all 0.4s;

                            i {
                                position: relative;
                                font-size: 13px;
                                color: #FFF;
                            }
                        }

                        input[type="checkbox"]:checked ~ label {
                            border: 1px solid #FC6736;
                            background: #FC6736;
                            transition: all 0.4s;
                        }

                        p {
                            position: relative;
                            width: calc(100% - 20px);
                            padding-left: 10px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 15px;
                            color: #444;

                            a {
                                font-weight: 500;
                                color: #3F00FF;
                                text-decoration: none;
                                cursor: pointer;
                            }
                        }

                        .form_btn {
                            position: relative;
                            margin-top: 25px;
                            width: 100%;

                            button {
                                position: relative;
                                width: 100%;
                                height: 55px;
                                border: 2px solid #FC6736;
                                outline: none;
                                background: #FC6736;
                                font-family: 'Ubuntu', sans-serif;
                                font-size: 18px;
                                text-transform: uppercase;
                                font-weight: 500;
                                letter-spacing: 0.55px;
                                color: #FFF;
                                border-radius: 6px;
                                cursor: pointer;
                                pointer-events: none;
                                opacity: 0.6;
                                transition: all 0.5s;

                                &:hover {
                                    background: #FFF;
                                    color: #FC6736;
                                    transition: all 0.5s;
                                }
                            }
                        }

                        input[type="checkbox"]:checked ~ .form_btn button {
                            pointer-events: initial;
                            opacity: 1;
                            transition: all 0.5s;
                        }
                    }

                    .select_box {
                        position: relative;
                        width: 48%;
                        margin-bottom: 25px;

                        input {

                            &#state {
                                display: none;
                            }

                            &#city {
                                display: none;
                            }
                        }

                        .dropdown_btn {
                            position: relative;
                            width: 100%;
                            height: 55px;
                            display: flex;
                            justify-content: space-between;
                            padding: 5px 25px;
                            border: 2px solid #E1E0EA;
                            border-radius: 6px;
                            font-family: 'Poppins', sans-serif;
                            color: #444;
                            cursor: pointer;

                            span {
                                position: absolute;
                                top: 50%;
                                left: 25px;
                                font-family: 'Poppins', sans-serif;
                                font-size: 16px;
                                line-height: 1;
                                color: #555;
                                transform: translateY(-50%);
                                transition: 0.5s;
                            }

                            p {
                                position: relative;
                                width: calc(100% - 25px);
                                height: 100%;
                                display: flex;
                                align-items: center;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                font-size: 15px;
                            }

                            i {
                                font-size: 16px;
                                width: 16px;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                background: #FFF;
                                transition: all 0.5s;

                                &.active {
                                    transform: rotate(-180deg);
                                    transition: all 0.5s;
                                }
                            }
                        }

                        .dropdown {
                            position: absolute;
                            top: 100%;
                            left: 0;
                            width: 100%;
                            background: #FFF;
                            z-index: 5;
                            border-radius: 5px;
                            box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                            max-height: 0;
                            overflow: hidden;
                            transition: all 0.6s;

                            &.active {
                                max-height: 300px;
                                transition: all 0.6s;
                            }

                            .search_sec {
                                position: relative;
                                width: 100%;
                                padding: 15px;
                                padding-bottom: 0;

                                .sec_inner {
                                    position: relative;
                                    width: 100%;

                                    input {
                                        position: relative;
                                        width: 100%;
                                        height: 40px;
                                        border: 1px solid #d4d4d4;
                                        border-radius: 6px;
                                        padding: 5px 15px;
                                        padding-left: 40px;
                                        font-size: 13px;
                                        color: #333;
                                        outline: none;
                                    }

                                    i {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 40px;
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        font-size: 15px;
                                        color: #d4d4d4;
                                    }
                                }
                            }

                            ul {
                                position: relative;
                                width: 100%;
                                padding: 10px 10px;
                                max-height: 250px;
                                overflow: auto;

                                li {
                                    position: relative;
                                    list-style: none;
                                    margin: 5px 0;
                                    padding: 10px 15px;
                                    color: #555;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 14px;
                                    line-height: 1;
                                    border-radius: 4px;
                                    transition: all 0.5s;
                                    cursor: pointer;
                                    
                                    &:hover {
                                        background: #FC6736;
                                        color: #FFF;
                                        transition: all 0.5s;
                                    }
                                }
                            }
                        }

                        input:focus ~ .dropdown_btn,
                        input:valid ~ .dropdown_btn {
                            border: 2px solid #FC6736;
                            transition: 0.5s;
                        }

                        input:focus ~ .dropdown_btn span,
                        input:valid ~ .dropdown_btn span {
                            top: 0%;
                            left: 15px;
                            font-size: 13px;
                            padding: 0px 8px;
                            background: #FFF;
                            color: #02C0FF;
                            font-weight: 500;
                            transition: 0.5s;
                        }
                    }

                    .redirect_box {
                        position: relative;
                        width: 100%;
                        margin-top: 10px;

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-size: 15px;
                            color: #666;

                            a {
                                text-decoration: none;
                                font-weight: 500;
                                font-size: 16px;
                                color: #3F00FF;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 1150px) {
            width: 600px;
            flex-direction: column-reverse;

            .box_left {
                width: 100%;
                padding-right: 0px;
                margin-top: 60px;

                img {
                    width: 300px;
                }
            }

            .box_right {
                width: 100%;
            }
        }

        @media (max-width: 768px) {
            width: 100%;
        }

        @media (max-width: 575px) {
            padding: 35px;

            .box_left {

                img {
                    width: 250px;
                }
            }

            .box_right form h3 {
                font-size: 20px;
            }

            .box_right form .form_sec .input_box input {
                height: 50px;
                font-size: 14px;
                padding: 5px 20px;
            }

            .box_right form .form_sec .input_box span {
                font-size: 14px;
                left: 20px;
            }

            .box_right form .form_sec .input_box a {
                font-size: 15px;
            }

            .box_right form .form_sec .select_box {
                width: 100%;
            }

            .box_right form .form_sec .accept_box label {
                width: 17px;
                height: 17px;
            }
            .box_right form .form_sec .accept_box label i {
                font-size: 11px;
            }

            .box_right form .form_sec .accept_box .form_btn button {
                height: 50px;
                font-size: 16px;
            }

            .box_right form .form_sec .redirect_box p {
                font-size: 13px;

                a {
                    font-size: 14px;
                }
            }
        }

        @media (max-width: 575px) {
            padding: 30px 25px;
        }
    }

    @media (max-width: 1150px) {
        padding: 50px;
        align-items: flex-start;
        overflow-y: auto;
    }

    @media (max-width: 575px) {
        padding: 50px 35px;
    }

    @media (max-width: 450px) {
        padding: 50px 25px;
    }
`;

export const CityModalWrapper = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1200;
    background: rgba(255, 255, 255, 0.377);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }

    .modal_box {
        position: relative;
        width: 550px;
        background: #FFF;
        padding: 45px 50px;
        padding-bottom: 50px;
        border-radius: 10px;
        box-shadow: 5px 8px 20px rgba(0,0,0,0.5);
        transform: translateY(-100px);
        transition: all 0.8s;

        &.active {
            transform: translateY(0px);
            transition: all 0.8s;
        }


        .box_inner {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;

            h5 {
                position: relative;
                font-size: 19px;
                line-height: 1;
                font-weight: 500;
                color: #FC6736;
            }

            .select_box_sec {
                position: relative;
                width: 100%;
                margin-top: 30px;

                .select_input_btn {
                    position: relative;
                    width: 100%;
                    height: 50px;
                    display: flex;
                    border: 1px solid #E1E0EA;
                    border-radius: 8px;
                    padding: 8px 0;

                    i {
                        position: relative;
                        width: 60px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 19px;
                        color: #cfcfcf;
                        border-right: 1px solid #cfcfcf;
                    }

                    input {
                        position: relative;
                        width: calc(100% - 60px);
                        padding: 0px 20px;
                        font-size: 15px;
                        color: #444;
                        border: none;
                        outline: none;
                    }
                }

                .city_dropdown {
                    position: absolute;
                    top: 100%;
                    left: 70px;
                    width: calc(100% - 70px);
                    max-height: 0px;
                    overflow: hidden;
                    background: #FFF;
                    z-index: 15;
                    border-radius: 6px;
                    box-shadow: 10px 15px 25px rgba(0,0,0,0.3),
                                -4px -4px 10px rgba(0,0,0,0.1);
                    transition: all 0.5s;

                    &.active {
                        max-height: 220px;
                        transition: all 0.5s;
                    }

                    .dropdown_inner {
                        position: relative;
                        width: 100%;
                        padding: 20px 15px;

                        ul {
                            position: relative;
                            width: 100%;
                            max-height: 180px;
                            overflow-y: auto;
                            scrollbar-width: none;
                            display: flex;
                            flex-direction: column;

                            li {
                                position: relative;
                                width: 100%;
                                list-style: none;
                                margin: 5px 0;
                                padding: 3px 20px;
                                cursor: pointer;
                                font-size: 16px;
                                color: #444;
                                transition: all 0.4s;

                                &:hover {
                                    color: #FC6736;
                                    transition: all 0.4s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 678px) {
        padding: 100px 35px;
        align-items: flex-start;

        .modal_box {
            width: 100%;
        }
    }

    @media (max-width: 575px) {
        .modal_box {
            padding: 35px 40px;
            padding-bottom: 40px;
            
            .box_inner {
                h5 {
                    font-size: 17px;
                }
            }
        }
    } 

    @media (max-width: 450px) {
        padding: 100px 25px;

        .modal_box {
            padding: 25px 25px;
            padding-bottom: 30px;

            .box_inner {
                h5 {
                    font-size: 17px;
                }
                .select_box_sec{
                    margin-top: 20px;

                    .select_input_btn{
                        height: 45px;

                        i {
                            width: 45px;
                            font-size: 17px;
                        }

                        input {
                            width: calc(100% - 45px);
                            font-size: 14px;
                        }
                    }

                    .city_dropdown {
                        left: 45px;
                        width: calc(100% - 45px);

                        .dropdown_inner {
                            padding: 10px;

                            ul {
                                li {
                                    margin: 3px 0;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    } 
`;

export const ScreenWrapper = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }

    .screen_modal {
        position: relative;
        width: 600px;
        min-height: 250px;
        background: #FFF;
        border-radius: 10px;
        box-shadow: 15px 20px 30px rgba(255, 199, 108, 0.9),
                    -6px -7px 12px rgba(255, 255, 108, 0.8);
        padding: 35px 40px;    
        transform: translateX(-100px);
        transition: all 0.7s;                

        &.active {
            transform: translateX(0px);
            transition: all 0.7s;
        }

        .modal_inner {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;

            .top_part {
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                h3 {
                    position: relative;
                    width: max-content;
                    font-family: arista-pro-alt-semibold;
                    font-size: 23px;
                    color: #222;

                    &::before {
                        content: '';
                        position: absolute;
                        bottom: -7px;
                        left: 0;
                        width: 70%;
                        height: 3px;
                        border-radius: 20px;
                        background: #FC6736;
                    }
                }

                span {
                    position: relative;
                    font-size: 18px;
                    cursor: pointer;
                    color: #555;
                }
            }

            .bottom_part {
                position: relative;
                margin-top: 25px;
                width: 100%;
                display: flex;
                justify-content: space-between;

                .screen_box {
                    position: relative;
                    width: 32%;
                    padding: 20px 0;
                    cursor: pointer;
                    transition: all 0.5s;

                    .box_inner {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;

                        .icon {
                            position: relative;
                            width: 125px;
                            height: 125px;
                            border: 2px solid #FFC76C;
                            padding: 8px;
                            transition: all 0.5s;

                            img {
                                position: relative;
                                width: 100%;
                                height: 100%;
                                transition: all 0.5s;
                            }
                        }

                        p {
                            position: relative;
                            margin-top: 15px;
                            font-family: 'Oleo Script', cursive;
                            font-size: 20px;
                            line-height: 1;
                            color: #888;
                            transition: all 0.5s;
                        }
                    }

                    &:hover .box_inner .icon {
                        border-radius: 50%;
                        box-shadow: 5px 8px 15px rgba(255, 199, 108, 0.9),
                                    -4px -4px 10px rgba(255, 255, 108, 0.8);
                        transition: all 0.5s;
                    }

                    &:hover .box_inner .icon img {
                        border-radius: 50%;
                        transition: all 0.5s;
                    }

                    &:hover .box_inner p {
                        color: #111;
                        transition: all 0.5s;
                    }
                }
            }
        }
    }
`;

export const InfoWrapper = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }

    .info_modal {
        position: relative;
        width: 800px;
        min-height: 150px;
        background: #FFF;
        border-radius: 10px;
        box-shadow: 15px 20px 30px rgba(255, 199, 108, 0.9),
                    -6px -7px 12px rgba(255, 255, 108, 0.8);
        padding: 35px 40px; 
        transform: translateX(-100px);
        transition: all 0.7s;  

        &.active {
            transform: translateX(0px);
            transition: all 0.7s;
        }

        .modal_inner {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;

            .top_part {
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                h3 {
                    position: relative;
                    width: max-content;
                    font-family: 'Lemonada', cursive;
                    font-size: 22px;
                    color: #222;

                    &::before {
                        content: '';
                        position: absolute;
                        bottom: -7px;
                        left: 0;
                        width: 70%;
                        height: 3px;
                        border-radius: 20px;
                        background: #FC6736;
                    }
                }

                span {
                    position: relative;
                    font-size: 18px;
                    cursor: pointer;
                    color: #555;
                }
            }

            .no_data_message {
                position: relative;
                width: 100%;
                margin-top: 60px;
                padding-bottom: 40px;
                display: flex;
                justify-content: center;
                text-align: center;

                h3 {
                    font-family: 'Lemonada', cursive;
                    font-size: 20px;
                    font-weight: 600;
                    color: #AAA;
                }
            }

            .no_movie_message {
                position: relative;
                width: 100%;
                margin-top: 25px;
                padding: 25px 0;
                display: flex;
                justify-content: center;
                text-align: center;

                h3 {
                    font-family: 'Lemonada', cursive;
                    font-size: 20px;
                    font-weight: 600;
                    color: #AAA;
                }
            }

            .date_panel_wrapper {
                position: relative;
                margin-top: 50px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                border-bottom: 2px solid #FC6736;

                .arrow_btn {
                    position: relative;
                    width: 50px;
                    display: flex;
                    align-items: center;

                    a {
                        position: relative;
                        width: 100%;
                        font-size: 19px;
                        cursor: pointer;
                        color: #3FE44F;
                        display: flex;
                    }

                    &.left a {
                        justify-content: flex-start;
                    }

                    &.right a {
                        justify-content: flex-end;
                    }
                }

                .date_panel {
                    position: relative;
                    width: calc(100% - 120px);
                    padding: 0 15px;
                    display: flex;
                    overflow-x: auto;
                    scroll-behavior: smooth;
                    white-space: nowrap;
                    scrollbar-width: none;

                    label {
                        position: relative;
                        margin: 0px 10px;

                        li {
                            position: relative;
                            list-style: none;
                            padding: 5px 15px;
                            font-family: 'Poppins', sans-serif;
                            cursor: pointer;
                            transition: all 0.4s;

                            p {
                                font-size: 15px;
                                font-weight: 500;
                            }
                        }

                        &.selected li {
                            background: #FC6736;
                            color: #FFF;
                            border-radius: 5px 5px 0 0;

                            span {
                                position: absolute;

                                &:nth-child(1) {
                                    bottom: 0;
                                    left: -10px;
                                    width: 10px;
                                    height: 10px;
                                    background: #FC6736;

                                    &::before {
                                        content: '';
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        background: #FFF;
                                        border-bottom-right-radius: 15px;
                                    }
                                }
                                &:nth-child(2) {
                                    bottom: 0;
                                    right: -10px;
                                    width: 10px;
                                    height: 10px;
                                    background: #FC6736;

                                    &::before {
                                        content: '';
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        background: #FFF;
                                        border-bottom-left-radius: 15px;
                                    }
                                }
                            }
                        }

                        &.selected:hover li {
                            background: #FC6736;
                            color: #FFF;
                            border-radius: 5px 5px 0 0;

                            span {
                                position: absolute;

                                &:nth-child(1) {
                                    bottom: 0;
                                    left: -10px;
                                    width: 10px;
                                    height: 10px;
                                    background: #FC6736;

                                    &::before {
                                        content: '';
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        background: #FFF;
                                        border-bottom-right-radius: 15px;
                                    }
                                }
                                &:nth-child(2) {
                                    bottom: 0;
                                    right: -10px;
                                    width: 10px;
                                    height: 10px;
                                    background: #FC6736;

                                    &::before {
                                        content: '';
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        background: #FFF;
                                        border-bottom-left-radius: 15px;
                                    }
                                }
                            }
                        }

                        &:hover li {
                            background: #FFFF6C;
                            color: #000;
                            border-radius: 5px 5px 0 0;
                            transition: all 0.4s;

                            span {
                                position: absolute;

                                &:nth-child(1) {
                                    bottom: 0;
                                    left: -10px;
                                    width: 10px;
                                    height: 10px;
                                    background: #FFFF6C;

                                    &::before {
                                        content: '';
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        background: #FFF;
                                        border-bottom-right-radius: 15px;
                                    }
                                }
                                &:nth-child(2) {
                                    bottom: 0;
                                    right: -10px;
                                    width: 10px;
                                    height: 10px;
                                    background: #FFFF6C;

                                    &::before {
                                        content: '';
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        background: #FFF;
                                        border-bottom-left-radius: 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .movie_info_box {
                position: relative;
                margin-bottom: 15px;
                width: 100%;

                .infobox_inner {
                    position: relative;
                    padding-top: 40px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    max-height: 400px;
                    overflow: auto;

                    .info_box {
                        position: relative;
                        width: 100%;
                        margin: 20px 0;
                        display: flex;
                        align-items: flex-start;

                        .movie_name {
                            position: relative;
                            width: 275px;
                            padding-right: 25px;

                            p {
                                position: relative;
                                padding: 12px 25px;
                                background: #222;
                                color: #FFF;
                                font-family: 'Poppins', sans-serif;
                                border-radius: 8px;
                            }
                        }

                        .movie_times {
                            position: relative;
                            width: calc(100% - 275px);
                            display: flex;
                            flex-wrap: wrap;

                            li {
                                position: relative;
                                list-style: none;
                                margin-right: 15px;
                                margin-bottom: 15px;
                                display: flex;

                                a {
                                    position: relative;
                                    padding: 12px 25px;
                                    border: 2px solid #FC6736;
                                    font-family: 'Poppins', sans-serif;
                                    border-radius: 6px;
                                    font-size: 16px;
                                    line-height: 1;
                                    color: #222;
                                    cursor: pointer;
                                    text-decoration: none;
                                    transition: all 0.5s;

                                    &:hover {
                                        background: #FC6736;
                                        color: #FFF;
                                    }
                                }

                                span {
                                    position: absolute;
                                    top: -55px;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    font-size: 16px;
                                    font-weight: 500;
                                    color: #FFF;
                                    opacity: 0;
                                    visibility: hidden;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    transition: all 0.5s;
                                    z-index: 5;
                                    pointer-events: none;

                                    p {
                                        position: relative;
                                        width: 100%;
                                        height: 100%;
                                        background: #02C0FF;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        border-radius: 6px;
                                    }

                                    &::before {
                                        content: '';
                                        position: absolute;
                                        width: 15px;
                                        height: 15px;
                                        background: #02C0FF;
                                        transform: translateY(22px) rotate(45deg);
                                    }
                                }

                                &:hover span {
                                    opacity: 1;
                                    visibility: visible;
                                    transition: all 0.5s;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 991px) {
            width: 100%;
        }

        @media (max-width: 768px) {
            .modal_inner {
                .top_part h3 {
                    font-size: 19px;
                }

                .movie_info_box .infobox_inner .info_box {
                    flex-direction: column;

                    .movie_name {
                        width: 100%;
                        margin-bottom: 20px;
                    }

                    .movie_times {
                        width: 100%;
                    }
                }
            }
        }

        @media (max-width: 575px) {
            padding: 30px 25px; 

            .modal_inner .date_panel_wrapper {
                .arrow_btn {
                    width: 40px;

                    a {
                        font-size: 17px;
                    }
                }

                .date_panel {
                    width: calc(100% - 90px);

                    label {
                        margin: 0px 8px;

                        li {
                            padding: 4px 12px;

                            p {
                                font-size: 13px;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }

            .modal_inner .movie_info_box .infobox_inner .info_box{
                .movie_times {
                    li {
                        a {
                            padding: 10px 20px;
                            font-size: 14px;
                        }

                        span {
                            top: -45px;
                            font-size: 14px;

                            &::before {
                                transform: translateY(17px) rotate(45deg);
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 450px) {
            .modal_inner {
                .top_part h3 {
                    font-size: 17px;
                }

                .date_panel_wrapper {
                    .arrow_btn {
                        width: 30px;

                        a {
                            font-size: 15px;
                        }
                    }

                    .date_panel {
                        width: calc(100% - 70px);
                    }
                }
            }
        }
    }

    @media (max-width: 991px) {
        padding: 50px;
        align-items: flex-start;
        overflow-y: auto;
    }

    @media (max-width: 575px) {
        padding: 50px 35px;
    }

    @media (max-width: 450px) {
        padding: 50px 25px;
    }
`;

export const ProfileImagesBox = styled('div')`
    position: fixed;
    top: 120px;
    right: 0px;
    width: 420px;
    height: calc(100vh - 120px);
    background: #FFF;
    z-index: 1100;
    border-radius: 10px 0 0 0;
    padding: 35px;
    padding-right: 55px;
    padding-bottom: 25px;
    box-shadow: -10px 0 25px rgba(25,25,25,0.3);
    opacity: 0;
    pointer-events: none;
    transition: all 0.7s ease-in-out;

    &.active {
        right: 380px;
        opacity: 1;
        pointer-events: initial;
        transition: all 0.7s ease-in-out;
    }

    .close_btn {
        position: absolute;
        top: 15px;
        right: 20px;
        display: none;

        a {
            position: relative;
            font-size: 21px;
            cursor: pointer;
            color: #000;
        }
    }

    .wrapper_inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .img_box_sec {
            position: relative;
            width: 100%;
            height: calc(100% - 80px);
            display: flex;
            flex-wrap: wrap;
            overflow-y: auto;
            scrollbar-width: none;

            .img_box {
                position: relative;
                width: 50%;
                padding: 15px;

                .box_inner {
                    position: relative;
                    width: 100%;
                    height: 135px;
                    border-radius: 50%;
                    padding: 5px;
                    cursor: pointer;

                    .img {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        overflow: hidden;

                        img {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                    }
                }

                &.selected .box_inner {
                    border: 2px solid #FC6736;
                }
            }
        }

        .btn_sec {
            position: relative;
            width: 100%;
            height: 80px;
            display: flex;
            align-items: flex-end;

            a {
                position: relative;
                width: 100%;
                height: 65px;
                background: #FC6736;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: 6px;
                font-size: 18px;
                font-weight: 500;
                color: #FFF;

                i {
                    position: relative;
                    margin-right: 10px;
                    font-size: 20px;
                }
            }
        }
    }

    @media (max-width: 1150px) {
        width: 330px;
        padding: 15px;
        padding-right: 35px;
        padding-bottom: 15px;

        &.active {
            right: 310px;
        }

        .wrapper_inner {
            .img_box_sec {
                height: calc(100% - 65px);

                .img_box {
                    .box_inner {
                        height: 110px;
                    }
                }
            }
            .btn_sec {
                height: 65px;

                a {
                    height: 50px;
                    font-size: 16px;
                }
            }
        }
    }

    @media (max-width: 991px) {
        top: 90px;
        height: calc(100vh - 90px);
    }

    @media (max-width: 768px) {
        z-index: 1300;
        padding: 15px;
        padding-top: 35px;
        right: -120%;

        &.active {
            right: 0;
        }

        .close_btn {
            display: block;
        }

        .wrapper_inner {
            .img_box_sec {
                .img_box {
                    .box_inner {
                        height: 120px;
                    }
                }
            }
        }
    }
`;

export const BookedTicketsBox = styled('div')`
    position: fixed;
    top: 120px;
    right: 0;
    width: 420px;
    height: calc(100vh - 120px);
    background: #FFF;
    z-index: 1100;
    border-radius: 10px 0 0 0;
    padding: 35px 25px;
    padding-top: 55px;
    padding-right: 45px;
    padding-bottom: 25px;
    box-shadow: -10px 0 25px rgba(25,25,25,0.3);
    opacity: 0;
    pointer-events: none;
    transition: all 0.7s ease-in-out;

    &.active {
        right: 380px;
        opacity: 1;
        pointer-events: initial;
        transition: all 0.7s ease-in-out;
    }

    .close {
        position: absolute;
        top: 15px;
        right: 20px;
        display: none;

        a {
            position: relative;
            font-size: 19px;
            cursor: pointer;
            color: #000;
        }
    }

    .wrapper_inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .ticket_box_sec {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            scrollbar-width: none;

            .ticket {
                position: relative;
                width: 100%;
                height: 125px;
                margin-bottom: 25px;
                display: flex;
                cursor: pointer;

                &:nth-child(4n + 1) {
                    background-color: #02C0FF;
                    color: #FFF;
                }

                &:nth-child(4n + 2) {
                    background-color: #FF33A1;
                    color: #FFF;
                }

                &:nth-child(4n + 3) {
                    background-color: rgba(28, 66, 255, 0.801) ;
                    color: #FFF;
                }

                &:nth-child(4n) {
                    background-color: #FFC76C ;
                    color: #000;
                }

                &:last-child {
                    margin-bottom: 0px;
                }

                .left_part {
                    position: relative;
                    width: calc(100% - 85px);
                    height: 100%;
                    padding: 10px 15px;
                    padding-right: 25px;

                    .cuts {
                        position: absolute;
                        top: 0;
                        left: -6px;
                        width: fit-content;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-direction: column;
                        padding: 3px 0;

                        .cut {
                            position: relative;
                            width: 10px;
                            height: 10px;
                            background: #FFF;
                            border-radius: 50%;
                        }
                    }

                    .content {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        border: 1px solid #FFF;
                        border-radius: 3px;
                        color: #FFF;
                        padding: 6px 10px;

                        h4 {
                            position: relative;
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            font-size: 16px;
                            line-height: 1.3;
                            text-overflow: ellipsis;
                            font-weight: 600;
                        }

                        .date_time {
                            position: relative;
                            margin-top: 4px;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 15px;
                            line-height: 1;
                            opacity: 0.9;
                            font-weight: 500;

                            span {
                                font-size: 7px;
                            }
                        }

                        .section {
                            position: relative;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            display: 15px;
                            line-height: 1;
                            margin-top: 2px;
                            font-weight: 500;
                            opacity: 0.9;

                            span {
                                position: relative;
                                width: 70px;
                            }

                            p {
                                position: relative;
                                width: calc(100% - 70px);
                                padding-left: 4px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }

                        .seats {
                            position: relative;
                            width: 100%;
                            margin-top: 2px;
                            display: flex;
                            align-items: center;
                            display: 15px;
                            line-height: 1;
                            margin-top: 2px;
                            font-weight: 500;
                            opacity: 0.9;

                            span {
                                position: relative;
                                width: 53px;
                            }

                            p {
                                position: relative;
                                width: calc(100% - 53px);
                                padding-left: 5px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }

                .right_part {
                    position: relative;
                    width: 85px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-image: linear-gradient(rgba(255, 255, 255) 45%, rgba(255, 255, 255, 0) 0%);
                    background-position: left;
                    background-size: 2px 21px;
                    background-repeat: repeat-y;
                    padding: 10px 15px;
                    padding-left: 25px;

                    .cuts {
                        position: absolute;
                        right: -6px;
                        width: fit-content;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-direction: column;
                        padding: 3px 0;

                        .cut {
                            position: relative;
                            width: 10px;
                            height: 10px;
                            background: #FFF;
                            border-radius: 50%;
                        }
                    }

                    .number {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        border: 1px solid #FFF;
                        border-radius: 3px;
                        color: #FFF;
                        font-family: 'Oswald', sans-serif;
                        text-align: center;
                        font-size: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        letter-spacing: 0.33px;

                        p {
                            position: relative;
                            font-family: 'Oswald', sans-serif; 
                            transform: rotate(-90deg);
                        }
                    }
                }

                &:nth-child(4n) {
                    .left_part {
                        .content {
                            border: 1px solid #000;
                            color: #000;
                        }
                    }
                    .right_part {
                        background-image: linear-gradient(rgba(0, 0, 0) 45%, rgba(255, 255, 255, 0) 0%);

                        .number {
                            border: 1px solid #000;
                            color: #000;
                        }
                    }
                }

                .circles {
                    position: absolute;
                    width: fit-content;
                    height: calc(100% + 30px);
                    top: 50%;
                    transform: translate(0, -50%);
                    right: 70px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;

                    .circle {
                        position: relative;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background: #FFF;
                    }
                }
            }

            .empty_message {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: 0px 15px;

                p {
                    position: relative;
                    font-size: 20px;
                    line-height: 1.6;
                    font-family: 'Lemonada', cursive;
                    font-weight: 500;
                    color: rgb(194, 194, 194);
                }
            }
        }
    }

    .ticket_details_popup {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: #FFF;
        box-shadow: 0px -5px 10px rgba(25,25,25,0.3) ;
        max-height: 0;
        overflow: hidden;
        transition: all 0.9s;

        &.active {
            max-height: 680px;
            transition: all 0.9s;
        }

        .popup_inner {
            position: relative;
            width: 100%;
            padding: 35px 25px;
            padding-right: 45px;
            padding-top: 5px;

            .close_btn {
                position: relative;
                width: 100%;
                height: 50px;
                margin-bottom: 10px;

                a {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    font-size: 23px;
                    color: #000;
                }
            }

            .ticket_outer {
                position: relative;
                max-height: 590px;
                overflow-y: auto;
                scrollbar-width: none;

                .ticket_box {
                    position: relative;
                    width: 100%;
                    min-height: 250px;
                    display: flex;
                    flex-direction: column;

                    .top_part {
                        position: relative;
                        width: 100%;
                        border-bottom: 2px dashed #000;

                        .dots {
                            position: absolute;
                            left: 0;
                            top: -7px;
                            width: 100%;
                            height: fit-content;
                            padding: 0px 5px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .dot {
                                position: relative;
                                width: 12px;
                                height: 12px;
                                background: #FFF;
                                border-radius: 50%;
                            }
                        }

                        .top_items {
                            position: relative;
                            padding: 30px 25px;
                            padding-top: 35px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .poster_img {
                                position: relative;
                                width: 80px;
                                height: 100px;
                                border-radius: 10px;
                                overflow: hidden;

                                img {
                                    position: relative;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }

                            h4 {
                                position: relative;
                                margin-top: 15px;
                                width: 100%;
                                text-align: center;
                                font-size: 17px;
                                font-weight: 600;
                            }

                            .date_time {
                                position: relative;
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-top: 5px;
                                line-height: 1;
                                font-size: 15px;
                                font-weight: 500;

                                span {
                                    margin: 0px 12px;
                                }
                            }

                            h5 {
                                position: relative;
                                margin-top: 5px;
                                font-size: 17px;
                                font-weight: 600;
                            }
                        }

                        .circles {
                            position: absolute;
                            bottom: -20px;
                            width: calc(100% + 40px);
                            transform: translateX(-20px);
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .circle {
                                position: relative;
                                width: 40px;
                                height: 40px;
                                background: #FFF;
                                border-radius: 50%;
                            }
                        }
                    }

                    .bottom_part {
                        position: relative;
                        width: 100%;
                        padding: 30px 25px;

                        .bottom_items {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .id_sec {
                                position: relative;
                                display: flex;
                                align-items: center;
                                font-size: 16px;
                                line-height: 1;
                                font-weight: 500;
                                opacity: 0.9;

                                span {
                                    position: relative;
                                    margin-right: 6px;
                                }
                            }

                            .seats_sec {
                                position: relative;
                                margin-top: 10px;
                                display: flex;

                                span {
                                    position: relative;
                                    font-weight: 500;
                                    font-size: 16px;
                                    white-space: nowrap;
                                }

                                i {
                                    position: relative;
                                    font-size: 6px;
                                    margin: 0px 10px;
                                    padding: 8px 0;
                                }

                                p {
                                    position: relative;
                                    font-size: 16px;
                                }
                            }

                            .qrcode_sec {
                                position: relative;
                                width: 110px;
                                margin-top: 20px;
                                padding: 6px;
                                border: 1px solid #000;
                                border-radius: 6px;
                                display: flex;

                                canvas {
                                    position: relative;
                                    width: 100% !important;
                                    height: auto !important;
                                }
                            }
                        }
                    }

                    .price_part {
                        position: relative;
                        border-top: 1px solid #000;
                        width: 100%;
                        padding: 15px 25px;
                        padding-bottom: 25px;

                        .dots {
                            position: absolute;
                            left: 0;
                            bottom: -7px;
                            width: 100%;
                            height: fit-content;
                            padding: 0px 5px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .dot {
                                position: relative;
                                width: 12px;
                                height: 12px;
                                background: #FFF;
                                border-radius: 50%;
                            }
                        }

                        .price_items {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;

                            .item_inner {
                                position: relative;
                                width: 100%;
                                display: flex;
                                align-items: center;

                                span {
                                    position: relative;
                                    font-size: 16px;
                                    font-weight: 500;
                                }

                                p {
                                    position: relative;
                                    margin-left: auto;
                                    font-size: 16px;
                                    font-weight: 500;
                                }

                                a {
                                    position: relative;
                                    margin-left: 10px;
                                    font-size: 15px;
                                    cursor: pointer;

                                    i {
                                        transition: all 0.6s;
                                    }

                                    &.active i {
                                        transform: rotate(-180deg);
                                        transition: all 0.6s;
                                    }
                                }
                            }

                            .inner_details {
                                position: relative;
                                width: 100%;
                                display: flex;
                                justify-content: flex-end;
                                max-height: 0;
                                overflow: hidden;
                                transition: all 0.6s;

                                &.active {
                                    max-height: 250px;
                                    transition: all 0.6s;
                                }

                                .details_box {
                                    position: relative;
                                    padding-top: 10px;

                                    .details {
                                        position: relative;
                                        width: max-content;
                                        padding-top: 10px;
                                        border-top: 1px solid #000;
                                        display: flex;
                                        flex-direction: column;

                                        .details_line {
                                            position: relative;
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;
                                            font-size: 15px;
                                            line-height: 1;
                                            margin-bottom: 4px;

                                            span {
                                                position: relative;
                                                font-weight: 500;
                                                color: #000;
                                            }

                                            p {
                                                position: relative;
                                                margin-left: 15px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    @media (max-width: 1150px) {
        width: 350px;
        padding: 25px 15px;
        padding-top: 50px;
        padding-right: 35px;

        &.active {
            right: 310px;
        }

        .wrapper_inner .ticket_box_sec .ticket {
            height: 115px;

            .left_part .content {
                h4 {
                    font-size: 14px;
                }
                .date_time {
                    font-size: 13px;
                    span {
                        font-size: 5px;
                    }
                }
                .section {
                    font-size: 13px;
                }

                .seats {
                    font-size: 13px;

                    span {
                        width: 45px;
                    }

                    p {
                        width: calc(100% - 45px);
                    }
                }
            }

            .right_part .number {
                font-size: 17px;
            }
        }

        .ticket_details_popup .popup_inner {
            .close_btn {
                height: 40px;

                a {
                    font-size: 20px;
                }
            }

            .ticket_outer .ticket_box {
                .top_part .top_items {
                    padding: 25px 20px;
                    padding-top: 30px;

                    .poster_img {
                        width: 70px;
                        height: 95px;
                    }
                    h4 {
                        font-size: 15px;
                    }

                    .date_time {
                        font-size: 14px;
                    }

                    h5 {
                        font-size: 15px;
                    }
                }
                .bottom_part {
                    padding: 25px 20px;

                    .bottom_items {
                        .id_sec {
                            font-size: 15px;
                        }
                        .seats_sec {
                            span {
                                font-size: 15px;
                            }
                            p {
                                font-size: 15px;
                            }
                        }
                        .qrcode_sec {
                            width: 90px;
                        }
                    }
                }
                .price_part .price_items {
                    .item_inner {
                        span {
                            font-size: 15px;
                        }

                        p {
                            font-size: 15px;
                        }
                        a {
                            font-size: 14px;
                        }
                    }
                    .inner_details .details_box .details .details_line {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    @media (max-width: 991px) {
        top: 90px;
        height: calc(100vh - 90px);
    }

    @media (max-width: 768px) {
        right: -100%;
        width: 350px;
        padding: 25px 15px;
        padding-top: 50px;
        z-index: 1200;

        &.active {
            right: 0;
        }

        .close {
            display: block;
        }

        .ticket_details_popup .popup_inner {
            padding: 35px 25px;
            padding-top: 5px;
        }
    }
`;

export const TrailerWrapper = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }

    .trailer_box {
        position: relative;
        width: 750px;
        min-height: 200px;
        background: #FFF;
        border-radius: 10px;
        box-shadow: 15px 20px 30px rgba(255, 199, 108, 0.9),
                    -6px -7px 12px rgba(255, 255, 108, 0.8);
        padding: 35px 40px; 
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        transform: translateY(-100px);
        transition: all 0.8s;

        &.active {
            transform: translateY(0px);
            transition: all 0.8s;
        }

        .btn_sec {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: flex-end;

            a {
                position: relative;
                cursor: pointer;
                font-size: 22px;
                color: #555;
            }
        }

        .video_sec {
            position: relative;
            margin-top: 25px;
            width: 100%;

            iframe {
                position: relative;
                width: 100%;
                height: 370px;
                border-radius: 10px;
            }
        }
    }
`;

export const ReviewWrapper = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }

    .review_box {
        position: relative;
        width: 600px;
        min-height: 150px;
        background: #FFF;
        border-radius: 10px;
        box-shadow: 15px 20px 30px rgba(161, 161, 161, 0.9),
                    -6px -7px 12px rgba(221, 221, 221, 0.8);
        padding: 35px 40px; 
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        transform: translateX(-100px);
        transition: all 0.8s;

        &.active {
            transform: translateX(0px);
            transition: all 0.8s;
        }

        .box_inner {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;

            .rating_message {
                position: relative;
                width: 100%;

                p {
                    position: relative;
                    font-size: 18px;
                    color: #444;

                    span {
                        font-weight: 500;
                        color: #3FE44F;
                    }
                }
            }

            .comment_box {
                position: relative;
                width: 100%;
                margin-top: 30px;

                textarea {
                    position: relative;
                    width: 100%;
                    height: 120px;
                    outline: none;
                    resize: none;
                    border: 2px solid #E1E0EA;
                    border-radius: 6px;
                    padding: 15px 25px;
                    font-size: 16px;
                    color: #333;

                    &:focus,
                    &:valid {
                        border: 2px solid #FC6736;
                    }
                }

                span {
                    position: absolute;
                    left: 25px;
                    top: 20px;
                    color: #555;
                    font-size: 16px;
                    line-height: 1;
                    transition: all 0.5s;
                    pointer-events: none;
                }

                textarea:focus ~ span,
                textarea:valid ~ span {
                    top: -6px;
                    left: 15px;
                    font-size: 13px;
                    color: #02C0FF;
                    padding: 0px 6px;
                    background: #FFF;
                    font-weight: 600;
                    transition: all 0.5s;
                }
            }

            .button_sec {
                position: relative;
                width: 100%;
                margin-top: 30px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                a {
                    position: relative;
                    width: 150px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 17px;
                    font-weight: 500;
                    cursor: pointer;
                    border-radius: 5px;
                    overflow: hidden;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        transform: scaleX(1);
                        transform-origin: left;
                        transition: transform 0.5s;
                    }

                    &:hover:before {
                        transform: scaleX(0);
                        transform-origin: right;
                        transition: transform 0.5s;
                    }

                    span {
                        position: relative;
                        z-index: 5;
                        color: #FFF;
                        transition: all 0.5s;

                        i {
                            font-size: 18px;
                            margin-right: 10px;
                        }
                    }

                    &:first-of-type {
                        margin-right: 25px;
                        border: 2px solid #000;
                        color: #000;

                        &::before {
                            background: #000;
                        }

                        &:hover span {
                            color: #000;
                            transition: all 0.5s;
                        }
                    }

                    &:last-of-type {
                        border: 2px solid #02C0FF;
                        color: #02C0FF;

                        &::before {
                            background: #02C0FF;
                        }

                        &:hover span {
                            color: #02C0FF;
                            transition: all 0.5s;
                        }
                    }
                }
            }
        }
    }
`;

export const BookingModalWrapper = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1200;
    padding: 30px 50px;
    background: #FFF;
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.4s;
    }

    .wrapper_inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .top_part {
            position: relative;
            width: 100%;
            height: 85px;
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #E1E0EA;

            h3 {
                position: relative;
                font-family: 'Lemonada', cursive;
                line-height: 1;
                font-size: 20px;
                color: #FC6736;

                span {
                    position: relative;
                    font-size: 19px;
                    margin-left: 10px;
                    font-weight: 600;
                    color: #02C0FF;
                }
            }

            .show_details {
                position: relative;
                margin-top: 15px;
                display: flex;
                align-items: center;

                h5 {
                    position: relative;
                    font-size: 17px;
                    font-weight: 500;
                    color: #555;
                    margin-right: 10px;

                    span {
                        margin-right: 8px;
                        font-weight: 600;
                        color: #000;
                    }
                }

                li {
                    position: relative;
                    list-style: none;
                    display: flex;
                    align-items: center;

                    p {
                        position: relative;
                        margin: 0px 5px;
                        font-size: 16px;
                        color: #555;

                        span {
                            position: relative;
                            font-weight: 500;
                            color: #111;
                            margin-right: 7px;
                        }
                    }
                }

                a {
                    position: relative;
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    color: #555;

                    span {
                        position: relative;
                        font-weight: 500;
                        color: #111;
                        margin-right: 7px;
                    }

                    i {
                        position: relative;
                        margin-left: 10px;
                        font-size: 18px;
                        cursor: pointer;
                        color: #02C0FF;
                    }
                }
            }
        }

        .seat_layout_part {
            position: relative;
            width: 100%;
            height: calc(100% - 160px);
            padding: 35px 0;
            overflow: hidden;
            overflow-y: auto;
            scrollbar-width: none;

            &:active {
                cursor: grabbing;
            }

            .seat_layout {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;

                .swiper {
                    position: relative;
                    width: 100%;

                    .swiper-slide {
                        position: relative;
                        min-width: 100%;
                        width: max-content !important;
                        min-height: 250px;

                        &:last-of-type {
                            width: 0 !important;
                            min-width: 0 !important;
                        }

                        .screen_box {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;

                            .display_image {
                                position: relative;
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                margin-bottom: 50px;

                                img {
                                    position: relative;
                                    width: 550px;
                                    display: flex;
                                }
                            }

                            .seat_sections {
                                position: relative;
                                width: 100%;
                                display: flex;
                                flex-direction: column;

                                .section {
                                    position: relative;
                                    width: 100%;
                                    display: flex;
                                    flex-direction: column;
                                    margin-bottom: 40px;

                                    &:last-of-type {
                                        margin-bottom: 0;
                                    }

                                    .sec_name {
                                        position: relative;
                                        width: 100%;
                                        padding-bottom: 15px;
                                        border-bottom: 1px solid #E1E0EA;
                                        display: flex;
                                        align-items: center;

                                        h5 {
                                            position: relative;
                                            font-family: 'Lemonada', cursive;
                                            font-size: 16px;
                                            line-height: 1;
                                            font-weight: 500;
                                            color: #02C0FF;
                                        }

                                        p {
                                            position: relative;
                                            margin-left: 12px;
                                            font-size: 15px;
                                            color: #555;
                                        }
                                    }

                                    .sec_seat_rows {
                                        position: relative;
                                        width: 100%;
                                        margin-top: 30px;
                                        display: flex;
                                        flex-direction: column-reverse;

                                        .seat_row {
                                            position: relative;
                                            width: 100%;
                                            padding: 0px 60px;
                                            margin-top: 15px;

                                            &:last-of-type {
                                                margin-top: 0;
                                            }

                                            .seats {
                                                position: relative;
                                                width: 100%;
                                                display: flex;
                                                flex-direction: row-reverse;
                                                align-items: center;

                                                li {
                                                    position: relative;
                                                    list-style: none;
                                                    width: 40px;
                                                    cursor: pointer;
                                                    display: flex;
                                                    flex-direction: column;
                                                    align-items: center;
                                                    color: #555;

                                                    i {
                                                        position: relative;
                                                        font-size: 15px;
                                                    }

                                                    p {
                                                        position: relative;
                                                        margin-top: 5px;
                                                        font-size: 12px;
                                                        line-height: 1;
                                                    }

                                                    &.booked {
                                                        color: #ddd;
                                                        pointer-events: none;
                                                    }

                                                    &.selected {
                                                        color: #3FE44F;
                                                    }
                                                }
                                            }

                                            .index {
                                                position: absolute;
                                                top: 30%;
                                                right: 5px;
                                                transform: translateY(-50%);
                                                font-size: 17px;
                                                font-weight: 500;
                                                color: #FC6736;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .bottom_part {
            position: relative;
            width: 100%;
            height: 75px;
            border-top: 1px solid #E1E0EA;
            display: flex;
            justify-content: space-between;

            .indicator_sec {
                position: relative;
                height: 100%;
                display: flex;
                align-items: flex-end;

                li {
                    list-style: none;
                    display: flex;
                    align-items: center;
                    margin-right: 40px;

                    &.not_available {
                        color: #FC6736;
                    }

                    &.available {
                        color: #555;
                    }

                    &.booked {
                        color: #ddd;
                    }

                    i {
                        position: relative;
                        font-size: 20px;
                        margin-right: 10px;
                    }

                    p {
                        position: relative;
                        font-size: 17px;
                        font-weight: 500;

                    }
                }
            }

            .btn_sec {
                position: relative;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;

                a {
                    position: relative;
                    width: 200px;
                    height: 55px;
                    font-family: 'Ubuntu', sans-serif;
                    font-size: 17px;
                    font-weight: 600;
                    cursor: pointer;
                    border-radius: 5px;
                    overflow: hidden;
                    background: none;
                    outline: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        transform: scaleX(1);
                        transform-origin: left;
                        transition: transform 0.5s;
                    }

                    &:hover:before {
                        transform: scaleX(0);
                        transform-origin: right;
                        transition: transform 0.5s;
                    }

                    span {
                        position: relative;
                        z-index: 5;
                        color: #FFF;
                        transition: all 0.5s;

                        i {
                            font-size: 18px;
                            margin-right: 10px;
                        }
                    }

                    &.close_btn {
                        border: 2px solid #000;
                        color: #000;
                        margin-right: 20px;

                        &::before {
                            background: #000;
                        }

                        &:hover span {
                            color: #000;
                            transition: all 0.5s;
                        }
                    }

                    &.booking_btn {
                        border: 2px solid #3FE44F;
                        color: #3FE44F;
                        opacity: 0.4;
                        pointer-events: none;

                        &.active {
                            opacity: 1;
                            pointer-events: initial;
                        }

                        &::before {
                            background: #3FE44F;
                        }

                        i {
                            transform: rotate(-45deg);
                        }

                        &:hover span {
                            color: #3FE44F;
                            transition: all 0.5s;
                        }
                    }
                }
            }
        }
    }
`;

export const SeatCapacitySelect = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(5px);
    z-index: 1299;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.4s;
    }

    .wrapper_innner {
        position: relative;
        width: 650px;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        background: #FFF;
        border-radius: 15px;
        box-shadow: 8px 10px 20px rgba(20,20,20,0.4),
                    -4px -4px 12px rgba(100,100,100,0.1);
        transition: all 0.6s;
        padding: 40px 50px;
        padding-bottom: 50px;
        transform: translateY(-100px);
        transition: all 0.8s;

        &.active {
            transform: translateY(0);
            transition: all 0.8s;
        }

        .heading_part {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;

            h3 {
                position: relative;
                font-family: 'Lemonada', cursive;
                font-size: 18px;
                font-weight: 600;
                color: #FC6736;
            }
        }

        .middle_part {
            position: relative;
            width: 100%;
            margin-top: 40px;
            padding-bottom: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: linear-gradient(to right, #FFF, #bdbdbd, #fff);
            }

            .icon_box {
                position: relative;
                display: flex;

                img {
                    position: relative;
                    display: flex;
                    height: 80px;
                }
            }

            .selection_btn_sec {
                position: relative;
                margin-top: 25px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;

                a {
                    position: relative;
                    margin: 0px 7px;
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                    border: 1px solid #02C0FF;
                    color: #02C0FF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-weight: 500;
                    border-radius: 50%;
                    transition: all 0.5s;

                    &:hover {
                        border: 1px solid #E5322D;
                        background: #E5322D;
                        color: #FFF;
                        transition: all 0.5s;
                    }

                    &.selected {
                        border: 1px solid #E5322D;
                        background: #E5322D;
                        color: #FFF;
                    }
                }
            }
        }

        .section_part {
            position: relative;
            width: 100%;
            padding: 15px 0;

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: linear-gradient(to right, #FFF, #bdbdbd, #fff);
            }

            .part_inner {
                position: relative;
                width: 100%;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;

                .section_btn {
                    position: relative;
                    padding: 10px;

                    .btn_inner {
                        position: relative;
                        padding: 10px 15px;
                        display: flex;
                        flex-direction: column;
                        border: 2px solid #02C0FF;
                        border-radius: 6px;
                        cursor: pointer;
                        color: #02C0FF;
                        text-align: center;
                        transition: all 0.5s;

                        &:hover {
                            background: #02C0FF;
                            color: #FFF;
                        }

                        &.selected {
                            border: 2px solid #E5322D;
                            background: #E5322D;
                            color: #FFF;
                        }

                        h5 {
                            position: relative;
                            font-size: 14px;
                            font-weight: 500;
                        }

                        span {
                            position: relative;
                            margin-top: 4px;
                            font-size: 18px;
                            font-weight: 500;

                            i {
                                position: relative;
                                font-size: 16px;
                            }
                        }

                        p {
                            position: relative;
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .bottom_part {
            position: relative;
            width: 100%;
            margin-top: 30px;
            padding: 0px 50px;

            button {
                position: relative;
                width: 100%;
                height: 55px;
                border: 2px solid #3FE44F;
                background: #3FE44F;
                color: #FFF;
                font-size: 17px;
                letter-spacing: 0.3px;
                font-weight: 500;
                border-radius: 8px;
                cursor: pointer;
                transition: all 0.5s;

                &:hover {
                    background: #FFF;
                    color: #3FE44F;
                    transition: all 0.5s;
                }
            }
        }
    }
`;

export const CheckoutTermsModalBox = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.4s;
    }

    .terms_box {
        position: relative;
        width: 650px;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        background: #FFF;
        border-radius: 15px;
        box-shadow: 8px 10px 20px rgba(20,20,20,0.4),
                    -4px -4px 12px rgba(100,100,100,0.1);
        transform: translateY(-100px);
        transition: all 0.8s;

        &.active {
            transform: translateY(0);
            transition: all 0.8s;
        }

        .heading_part {
            position: relative;
            width: 100%;
            padding: 25px 40px;
            border-bottom: 1px solid #E1E0EA;
            display: flex;
            align-items: center;

            h3 {
                font-size: 20px;
                font-family: 'Lemonada', cursive;
                line-height: 1;
                color: #FC6736;
                font-weight: 600;
            }

            a {
                position: relative;
                margin-left: auto;
                cursor: pointer;
                font-size: 18px;
                color: #444;
            }
        }

        .body_part {
            position: relative;
            width: 100%;
            padding: 30px 40px;

            .part_inner {
                position: relative;
                width: 100%;
                padding-right: 25px;
                max-height: 350px;
                overflow-y: auto;

                li {
                    position: relative;
                    width: 100%;
                    display: flex;
                    list-style: none;
                    margin-bottom: 15px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    span {
                        position: relative;
                        width: 25px;
                        height: 24px;
                        display: flex;
                        align-items: center;
                        font-weight: 500;
                        font-size: 15px;
                        color: #02C0FF;
                    }

                    p {
                        position: relative;
                        width: calc(100% - 25px);
                        font-size: 15px;
                        line-height: 24px;
                        color: #444;

                        a {
                            font-weight: 500;
                            text-decoration: none;
                            color: #02C0FF;
                        }
                    }
                }
            }
        }

        .btn_part {
            position: relative;
            width: 100%;
            padding: 25px 40px;
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #E1E0EA;

            button {
                position: relative;
                width: 160px;
                height: 50px;
                font-family: 'Ubuntu', sans-serif;
                font-size: 16px;
                font-weight: 500;
                cursor: pointer;
                border-radius: 6px;
                overflow: hidden;
                background: transparent;
                outline: none;
                text-decoration: none;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    transform: scaleX(1);
                    transform-origin: left;
                    transition: transform 0.5s;
                }

                &:hover:before {
                    transform: scaleX(0);
                    transform-origin: right;
                    transition: transform 0.5s;
                }

                span {
                    position: relative;
                    z-index: 5;
                    color: #FFF;
                    transition: all 0.5s;

                    i {
                        font-size: 17px;
                        margin-right: 8px;
                    }
                }

                &:first-of-type {
                    border: 2px solid #000;
                    
                    &::before {
                        background: #000;
                    }

                    &:hover {
                        span {
                            color: #000;
                            transition: all 0.5s;
                        }
                    }
                }

                &:last-of-type {
                    border: 2px solid #3FE44F;
                    
                    &::before {
                        background: #3FE44F;
                    }

                    &:hover {
                        span {
                            color: #3FE44F;
                            transition: all 0.5s;
                        }
                    }
                }
            }
        }
    }
`;

export const CheckoutModalBox = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.4s;
    }

    .modal_box {
        position: relative;
        width: 900px;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        background: #FFF;
        border-radius: 15px;
        box-shadow: 8px 10px 20px rgba(20,20,20,0.4),
                    -4px -4px 12px rgba(100,100,100,0.1);
        transition: all 0.6s;
        padding: 40px 50px;
        padding-bottom: 50px;
        transform: translateX(-100px);
        transition: all 0.8s;

        &.active {
            transform: translateX(0px);
            transition: all 0.8s;
        }

        .close_btn {
            position: absolute;
            top: 20px;
            right: 30px;

            a {
                position: relative;
                font-size: 18px;
                cursor: pointer;
                color: #555;
            }
        }

        .box_inner {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;

            .top_sec {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                h3 {
                    position: relative;
                    font-family: 'Lemonada', cursive;
                    font-size: 25px;
                    font-weight: 700;
                    color: #FC6736;
                }

                h6 {
                    position: relative;
                    margin-top: 10px;
                    font-family: 'Poppins', sans-serif;
                    font-size: 20px;
                    font-weight: 600;
                    color: #000;
                }

                .top_sec_items {
                    position: relative;
                    margin-top: 20px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    li {
                        position: relative;
                        list-style: none;

                        p {
                            position: relative;
                            font-size: 16px;
                            color: #555;
                            font-weight: 400;

                            span {
                                font-weight: 500;
                                color: #000;
                                margin-right: 6px;
                            }
                        }
                    }
                }
            }

            .bottom_sec {
                position: relative;
                width: 100%;
                margin-top: 50px;
                display: flex;
                justify-content: space-between;

                .ticket_sec {
                    position: relative;
                    width: 350px;
                    display: flex;
                    flex-direction: column;

                    .ticket_heading {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        background: #02C0FF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 8px;

                        h5 {
                            position: relative;
                            font-size: 18px;
                            color: #FFF;
                            line-height: 1;
                            font-weight: 600;
                            letter-spacing: 0.44px;
                        }
                    }

                    table {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        thead {
                            position: relative;
                            width: 100%;
                            height: 50px;
                            display: flex;

                            th {
                                position: relative;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 15px;
                                font-weight: 600;
                                padding: 0px 22px;

                                &:nth-child(1) {
                                    width: 40%;
                                    justify-content: flex-start;
                                }
                                &:nth-child(2) {
                                    width: 20%;
                                }
                                &:nth-child(3) {
                                    width: 40%;
                                }
                            }
                        }

                        tbody {
                            position: relative;
                            width: 100%;
                            max-height: 170px;
                            overflow-y: auto;
                            display: flex;
                            flex-direction: column;

                            tr {
                                position: relative;
                                width: 100%;
                                display: flex;
                                border-radius: 8px;

                                &:nth-of-type(odd) {
                                    background: #F5F5F5;
                                }

                                td {
                                    position: relative;
                                    padding: 16px 22px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    text-align: center;
                                    font-size: 14px;

                                    &:nth-child(1) {
                                        width: 40%;
                                        justify-content: flex-start;
                                        text-align: left;
                                    }
                                    &:nth-child(2) {
                                        width: 20%;
                                    }
                                    &:nth-child(3) {
                                        width: 40%;
                                    }
                                }
                            }
                        }
                    }
                }

                .amount_sec {
                    position: relative;
                    display: flex;
                    flex-direction: column;

                    .amount_inner {
                        position: relative;
                        margin-bottom: 35px;
                        width: max-content;
                        height: max-content;
                        display: flex;
                        flex-direction: column;
                        padding: 25px 35px;
                        border-radius: 8px;
                        box-shadow: 5px 6px 12px rgba(100,100,100,0.3),
                                    -4px -4px 8px rgba(100,100,100,0.3);

                        .amount_top {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;

                            li {
                                position: relative;
                                list-style: none;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin-bottom: 15px;
                                font-size: 17px;
                                font-weight: 500;

                                p {
                                    position: relative;
                                    width: 175px;
                                    text-align: right;
                                    margin-right: 10px;
                                }

                                a {
                                    position: relative;
                                    margin-left: 6px;
                                    font-size: 14px;
                                    cursor: pointer;
                                    color: #555;

                                    i {
                                        position: relative;
                                        transition: all 0.4s;

                                        &.active {
                                            transform: rotate(-180deg);
                                            transition: all 0.4s;
                                        }
                                    }
                                }
                            }
                        }

                        .convenience_details {
                            position: relative;
                            width: 100%;
                            max-height: 0;
                            overflow: hidden;
                            transition: all 0.4s;

                            &.active {
                                max-height: 250px;
                                transition: all 0.4s;
                            }

                            .details_inner {
                                position: relative;
                                border-top: 1px solid #E1E0EA;
                                width: 100%;
                                padding: 15px 0;
                                display: flex;
                                flex-direction: column;

                                li {
                                    position: relative;
                                    list-style: none;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    margin-bottom: 10px;
                                    font-size: 13px;
                                    color: #898989;

                                    &:last-of-type {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }

                        .amount_bottom {
                            position: relative;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding-top: 15px;
                            border-top: 1px solid #E1E0EA;
                            font-size: 17px;
                            font-weight: 600;

                            p {
                                margin-right: 10px;
                            }
                        }
                    }

                    .box_btn {
                        position: relative;
                        margin-top: auto;
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;

                        a {
                            position: relative;
                            width: 200px;
                            height: 55px;
                            font-family: 'Ubuntu', sans-serif;
                            font-size: 17px;
                            font-weight: 600;
                            cursor: pointer;
                            border-radius: 30px;
                            overflow: hidden;
                            background: none;
                            outline: none;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 2px solid #3FE44F;

                            &::before {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background: #3FE44F;
                                transform: scaleX(1);
                                transform-origin: left;
                                transition: transform 0.5s;
                            }

                            &:hover:before {
                                transform: scaleX(0);
                                transform-origin: right;
                                transition: transform 0.5s;
                            }

                            span {
                                position: relative;
                                z-index: 5;
                                color: #FFF;
                                transition: all 0.5s;

                                i {
                                    font-size: 18px;
                                    margin-left: 10px;
                                    transition: all 0.5s;
                                }
                            }

                            &:hover span {
                                color: #3FE44F;
                                transition: all 0.5s;

                                i {
                                    margin-left: 20px;
                                    transition: all 0.5s;
                                }
                            }
                        }
                    }
                }
            }

            .button_sec {
                position: relative;
                width: 100%;
                margin-top: 35px;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;

                .user_details_sec {
                    position: relative;
                    display: flex;
                    flex-direction: column;

                    h5 {
                        position: relative;
                        font-size: 15px;
                        color: #000;
                        font-weight: 600;
                    }

                    ul {
                        position: relative;
                        margin-top: 10px;
                        display: flex;
                        flex-direction: column;

                        li {
                            position: relative;
                            list-style: none;
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            margin: 4px 0;

                            span {
                                position: relative;
                                font-weight: 500;
                                color: #333;
                                margin-right: 5px;
                            }

                            p {
                                position: relative;
                                color: #898989;
                            }
                        }
                    }
                }

                .btn_sec {
                    position: relative;

                    a {
                        position: relative;
                        text-decoration: none;
                        width: 200px;
                        height: 55px;
                        font-family: 'Ubuntu', sans-serif;
                        font-size: 17px;
                        font-weight: 600;
                        cursor: pointer;
                        border-radius: 30px;
                        overflow: hidden;
                        background: none;
                        outline: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 2px solid #3FE44F;
                        transition: all 0.4s;

                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: #3FE44F;
                            transform: scaleX(1);
                            transform-origin: left;
                            transition: transform 0.5s;
                        }

                        &:hover:before {
                            transform: scaleX(0);
                            transform-origin: right;
                            transition: transform 0.5s;
                        }

                        span {
                            position: relative;
                            z-index: 5;
                            color: #FFF;
                            transition: all 0.5s;

                            i {
                                font-size: 18px;
                                margin-left: 10px;
                                transition: all 0.5s;
                            }
                        }

                        &:hover span {
                            color: #3FE44F;
                            transition: all 0.5s;

                            i {
                                margin-left: 20px;
                                transition: all 0.5s;
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const TermsConditionsWrapper = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }

    .terms_conditions_box {
        position: relative;
        width: 850px;
        min-height: 200px;
        background: #FFF;
        border-radius: 10px;
        box-shadow: 8px 10px 20px rgba(255, 199, 108, 0.9),
                    -6px -7px 12px rgba(255, 255, 108, 0.8);
        transform: translateX(-100px);
        transition: all 0.7s;  

        &.active {
            transform: translateX(0px);
            transition: all 0.7s;
        }


        .heading_part {
            position: relative;
            width: 100%;
            padding: 25px 40px;
            border-bottom: 1px solid #E1E0EA;


            h3 {
                font-size: 23px;
                font-family: 'Lemonada', cursive;
                line-height: 1;
                color: #FC6736;
                font-weight: 600;
            }
        }

        .body_part {
            position: relative;
            width: 100%;
            padding: 30px 40px;
            padding-right: 0;


            .part_inner{
                position: relative;
                width: 100%;
                padding-right: 40px;
                max-height: 350px;
                overflow-y: auto;

                li {
                    position: relative;
                    width: 100%;
                    display: flex;
                    list-style: none;
                    margin-bottom: 15px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    i {
                        position: relative;
                        width: 30px;
                        height: 25px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 11px;
                        color: #FC6736;
                    }

                    p {
                        position: relative;
                        width: calc(100% - 30px);
                        font-size: 16px;
                        line-height: 25px;
                        color: #444;

                        a {
                            font-weight: 500;
                            text-decoration: none;
                            color: #02C0FF;
                        }
                    }
                }
            }
        }

        .btn_part{
            position: relative;
            width: 100%;
            padding: 25px 40px;
            display: flex;
            justify-content: flex-end;
            border-top: 1px solid #E1E0EA;

            a {
                position: relative;
                width: 160px;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: 'Ubuntu', sans-serif;
                font-size: 17px;
                font-weight: 600;
                cursor: pointer;
                border-radius: 5px;
                border: 2px solid #02C0FF;
                color: #02C0FF;
                overflow: hidden;
                background: none;
                outline: none;
                text-decoration: none;


                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #02C0FF;
                    transform: scaleX(1);
                    transform-origin: left;
                    transition: transform 0.5s;
                }

                &:hover:before {
                    transform: scaleX(0);
                    transform-origin: right;
                    transition: transform 0.5s;
                }


                span {
                    position: relative;
                    z-index: 5;
                    color: #FFF;
                    transition: all 0.5s;

                    i {
                        font-size: 18px;
                        margin-right: 10px;
                    }
                }

                &:hover span {
                    color: #02C0FF;
                    transition: all 0.5s;
                }
            }
        }

        @media (max-width: 991px) {
            width: 100%;
        } 

        @media (max-width: 768px) {
            width: 100%;

            .heading_part {
                padding: 25px 30px;

                h3 {
                    font-size: 21px;
                }
            }

            .body_part {
                padding: 30px 25px;
                padding-right: 0;

                .part_inner {
                    padding-right: 30px;
                }
            }

            .btn_part {
                padding: 25px 30px;
            }
        } 

        @media (max-width: 575px) {
            height: 100%;

            .body_part {
                height: calc(100% - 180px);
                padding: 25px 15px;
                padding-right: 0;
                
                .part_inner {
                    padding-right: 25px;
                    max-height: 100%;

                    li {
                        i {
                            height: 22px;
                            font-size: 10px;
                        }

                        p {
                            font-size: 14px;
                            line-height: 22px;
                        }
                    }
                }
            }

            .btn_part {
                a {
                    width: 125px;
                    height: 45px;
                    font-size: 15px;
                }
            }
        }

        @media (max-width: 450px) {
            .heading_part {
                padding: 20px;

                h3 {
                    font-size: 19px;
                }
            }

            .body_part {
                padding: 25px 10px;
                padding-right: 0;
                height: calc(100% - 140px);

                .part_inner {
                    padding-right: 20px;
                }
            }

            .btn_part {
                padding: 15px 20px;
            }
        }
    }

    @media (max-width: 575px) {
        padding: 40px 35px;
        align-items: flex-start;

    }

    @media (max-width: 450px) {
        padding: 40px 25px;

    }
`;

export const PrivacyPolicyWrapper = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }

    .privacy_policy_box {
        position: relative;
        width: 850px;
        min-height: 200px;
        background: #FFF;
        border-radius: 10px;
        box-shadow: 8px 10px 20px rgba(255, 199, 108, 0.9),
                    -6px -7px 12px rgba(255, 255, 108, 0.8);
        transform: translateX(-100px);
        transition: all 0.7s;  

        &.active {
            transform: translateX(0px);
            transition: all 0.7s;
        }
        

         .heading_part {
            position: relative;
            width: 100%;
            padding: 25px 40px;
            border-bottom: 1px solid #E1E0EA;


            h3 {
                font-size: 23px;
                font-family: 'Lemonada', cursive;
                line-height: 1;
                color: #FC6736;
                font-weight: 600;
            }
        }

        .body_part {
            position: relative;
            width: 100%;
            padding: 30px 40px;
            padding-right: 0;

            .part_inner{
                position: relative;
                width: 100%;
                padding-right: 40px;
                max-height: 400px;
                overflow-y: auto;

                li {
                    position: relative;
                    width: 100%;
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 25px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    h5 {
                        position: relative;
                        font-size: 17px;
                        font-weight: 500;
                        color: #2ad33b;
                        margin-bottom: 15px;
                    }

                    p {
                        position: relative;
                        width: 100%;
                        margin-bottom: 15px;
                        font-size: 16px;
                        color: #444;
                        line-height: 1.5;

                        &:last-of-type {
                            margin-bottom: 0;
                        }

                        i {
                            position: relative;
                            font-size: 16px;
                            margin-right: 10px;
                            color: #FC6736;
                        }
                    }
                }
            }
        }

        .btn_part{
            position: relative;
            width: 100%;
            padding: 25px 40px;
            display: flex;
            justify-content: flex-end;
            border-top: 1px solid #E1E0EA;

            a {
                position: relative;
                width: 160px;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: 'Ubuntu', sans-serif;
                font-size: 17px;
                font-weight: 600;
                cursor: pointer;
                border-radius: 5px;
                border: 2px solid #02C0FF;
                color: #02C0FF;
                overflow: hidden;
                background: none;
                outline: none;
                text-decoration: none;


                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #02C0FF;
                    transform: scaleX(1);
                    transform-origin: left;
                    transition: transform 0.5s;
                }

                &:hover:before {
                    transform: scaleX(0);
                    transform-origin: right;
                    transition: transform 0.5s;
                }


                span {
                    position: relative;
                    z-index: 5;
                    color: #FFF;
                    transition: all 0.5s;

                    i {
                        font-size: 18px;
                        margin-right: 10px;
                    }
                }

                &:hover span {
                    color: #02C0FF;
                    transition: all 0.5s;
                }
            }
        }

        @media (max-width: 991px) {
            width: 100%;
        } 

        @media (max-width: 768px) {
            width: 100%;

            .heading_part {
                padding: 25px 30px;

                h3 {
                    font-size: 21px;
                }
            }

            .body_part {
                padding: 30px 25px;
                padding-right: 0;

                .part_inner {
                    padding-right: 30px;
                }
            }

            .btn_part {
                padding: 25px 30px;
            }
        } 

        @media (max-width: 575px) {
            height: 100%;

            .body_part {
                height: calc(100% - 180px);
                padding-right: 0;
                
                .part_inner {
                    max-height: 100%;

                    li {
                        h5 {
                            font-size: 15px;
                        }

                        p {
                            font-size: 14px;
                            line-height: 22px;

                            i {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            .btn_part {
                a {
                    width: 125px;
                    height: 45px;
                    font-size: 15px;
                }
            }
        }

        @media (max-width: 450px) {
            .heading_part {
                padding: 20px;

                h3 {
                    font-size: 19px;
                }
            }

            .body_part {
                height: calc(100% - 140px);
            }

            .btn_part {
                padding: 15px 20px;
            }
        }
    }

    @media (max-width: 575px) {
        padding: 40px 35px;
        align-items: flex-start;

    }

    @media (max-width: 450px) {
        padding: 40px 25px;

    }
`;

export const RefundPolicyWrapper = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }

    .refund_policy_box {
        position: relative;
        width: 850px;
        min-height: 200px;
        background: #FFF;
        border-radius: 10px;
        box-shadow: 8px 10px 20px rgba(255, 199, 108, 0.9),
                    -6px -7px 12px rgba(255, 255, 108, 0.8);
        transform: translateY(-100px);
        transition: all 0.7s;  

        &.active {
            transform: translateY(0px);
            transition: all 0.7s;
        }


        .heading_part {
            position: relative;
            width: 100%;
            padding: 25px 40px;
            border-bottom: 1px solid #E1E0EA;


            h3 {
                font-size: 23px;
                font-family: 'Lemonada', cursive;
                line-height: 1;
                color: #FC6736;
                font-weight: 600;
            }
        }

        .body_part {
            position: relative;
            width: 100%;
            padding: 30px 40px;
            padding-right: 0;

            .part_inner{
                position: relative;
                width: 100%;
                padding-right: 40px;
                max-height: 400px;
                overflow-y: auto;

                li {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    list-style: none;
                    margin-bottom: 25px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    b {
                        position: relative;
                        width: 100%;
                        font-weight: 500;
                        font-size: 16px;
                        color: #02C0FF;
                    }

                    h5 {
                        position: relative;
                        font-size: 17px;
                        font-weight: 500;
                        color: #2ad33b;
                        margin-bottom: 10px;
                    }

                    span {
                        position: relative;
                        font-size: 16px;
                        font-weight: 500;
                        color: #222;
                        margin-bottom: 10px;
                    }

                    p {
                        position: relative;
                        width: 100%;
                        margin-bottom: 10px;
                        font-size: 16px;
                        color: #444;
                        line-height: 25px;
                        display: flex;

                        &:last-of-type {
                            margin-bottom: 0;

                            span {
                                font-weight: normal;
                                color: #444;

                                b {
                                    font-weight: 500;
                                    color: #222;
                                }
                            }
                        }

                        i {
                            position: relative;
                            height: 25px;
                            display: flex;
                            align-items: center;
                            font-size: 12px;
                            margin-right: 10px;
                            color: #FC6736;
                        }

                        span {
                            margin-bottom: 0;
                            margin-right: 8px;
                            line-height: 25px;
                        }

                        a {
                            font-size: 16px;
                            line-height: 25px;
                            color: #02C0FF;
                            text-decoration: none;
                        }
                    }
                }
            }    
        }

        .btn_part{
            position: relative;
            width: 100%;
            padding: 25px 40px;
            display: flex;
            justify-content: flex-end;
            border-top: 1px solid #E1E0EA;

            a {
                position: relative;
                width: 160px;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: 'Ubuntu', sans-serif;
                font-size: 17px;
                font-weight: 600;
                cursor: pointer;
                border-radius: 5px;
                border: 2px solid #02C0FF;
                color: #02C0FF;
                overflow: hidden;
                background: none;
                outline: none;
                text-decoration: none;


                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #02C0FF;
                    transform: scaleX(1);
                    transform-origin: left;
                    transition: transform 0.5s;
                }

                &:hover:before {
                    transform: scaleX(0);
                    transform-origin: right;
                    transition: transform 0.5s;
                }


                span {
                    position: relative;
                    z-index: 5;
                    color: #FFF;
                    transition: all 0.5s;

                    i {
                        font-size: 18px;
                        margin-right: 10px;
                    }
                }

                &:hover span {
                    color: #02C0FF;
                    transition: all 0.5s;
                }
            }
        }

        @media (max-width: 991px) {
            width: 100%;
        } 

        @media (max-width: 768px) {
            width: 100%;

            .heading_part {
                padding: 25px 30px;

                h3 {
                    font-size: 21px;
                }
            }

            .body_part {
                padding: 30px 25px;
                padding-right: 0;

                .part_inner {
                    padding-right: 30px;
                }
            }

            .btn_part {
                padding: 25px 30px;
            }
        } 

        @media (max-width: 575px) {
            height: 100%;

            .heading_part {

                h3 {
                    font-size: 18px;
                }
            }

            .body_part {
                height: calc(100% - 180px);
                padding-right: 0;
                
                .part_inner {
                    max-height: 100%;

                    li {
                        b {
                            font-size: 15px;
                            line-height: 22px;
                        }

                        h5 {
                            font-size: 15px;
                        }

                        span {
                            font-size: 14px;
                        }

                        p {
                            font-size: 14px;
                            line-height: 22px;

                            i {
                                font-size: 13px;
                            }

                            a {
                                font-size: 15px;
                                line-height: 22px;
                            }
                        }
                    }
                }
            }

            .btn_part {
                a {
                    width: 125px;
                    height: 45px;
                    font-size: 15px;
                }
            }
        }

        @media (max-width: 450px) {
            .heading_part {
                padding: 20px;

                h3 {
                    font-size: 16px;
                }
            }

            .body_part {
                height: calc(100% - 146px);
            }

            .btn_part {
                padding: 15px 20px;
            }
        }
    }

    @media (max-width: 575px) {
        padding: 40px 35px;
        align-items: flex-start;

    }

    @media (max-width: 450px) {
        padding: 40px 25px;

    }
`;